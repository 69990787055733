import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { RenderNotifications } from 'src/components/Header/StudentHeader'
import { NotificationTypes, _notify } from 'src/utils/notify'
import { INotification } from 'src/store/user/user.types'
import useAppSelector from 'src/hooks/useAppSelector'
import useAppDispatch from 'src/hooks/useAppDispatch'
import stylePage from './Notification.module.scss'
import { dayWorker } from 'src/utils/dateWorker'
import React, { useEffect, useRef } from 'react';
import { Card } from 'src/components/Card/Card'
import Loader from 'src/components/Loader'
import { Button } from 'primereact/button'
import { Helmet } from 'react-helmet-async'
import { Toast } from 'primereact/toast';
import { map, size } from 'lodash';
import {
    getAllNotification,
    saveStatusNotification,
    saveStatusNotificationsList,
} from 'src/store/admin/notification/notification.actions';

function sortNotifications(
    notifications: Array<INotification>
): Array<INotification> {
    const copy = JSON.parse(
        JSON.stringify(notifications)
    ) as Array<INotification>;
    copy.sort((a, b) => {
        return dayWorker(a.createdAt).isAfter(dayWorker(b.createdAt))
            ? 1
            : -1;
    });
    return copy;
}

export const NotificationsPage = () => {
    const notifications = useAppSelector(
        state => state.userReducer.user.allNotifications
    );
    const { pending } = useAppSelector(
        state => state.userReducer.getAllNotification
    );
    const dispatch = useAppDispatch();
    const toast = useRef<Toast>(null);

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        dispatch(getAllNotification())
            .unwrap()
            .catch(e => {
                _notify(toast, { type: NotificationTypes.error, content: e })
            });
    }

    function onCheck(notification: INotification) {
        dispatch(saveStatusNotification({ ...notification, isShow: true }))
            .unwrap()
            .catch(e => {
                _notify(toast, { type: NotificationTypes.error, content: e })
            });
    }

    function onAllCheck() {
        dispatch(
            saveStatusNotificationsList(
                map(notifications.unread, notification => ({
                    ...notification,
                    isShow: true,
                }))
            )
        )
            .unwrap()
            .then(_ => getData())
            .catch(e => {
                _notify(toast, { type: NotificationTypes.error, content: e })
            });
    }
    const anyNotifications =
        size(notifications.unread) || size(notifications.read);

    return (
        <StudentLayout>
            <Helmet title={'Все уведомления'} />
            <Toast ref={toast} />
            <Card
                headerClassName={'mt-24'}
                header={'Уведомления'}
                contentClassName={stylePage.page}
            >
                {pending ? (
                    <Loader />
                ) : (
                    <div className={'flex flex-column'} style={{ gap: '1rem' }}>
                        {anyNotifications ? (
                            <>
                                {size(notifications.unread) ? (
                                    <div className="flex justify-content-center">
                                        <Button
                                            className="p-button-sm"
                                            onClick={onAllCheck}
                                        >
                                            Прочитать все
                                        </Button>
                                    </div>
                                ) : null}
                                <RenderNotifications
                                    notifications={notifications.unread}
                                    onCheck={onCheck}
                                />
                                <RenderNotifications
                                    notifications={notifications.read}
                                />
                            </>
                        ) : (
                            'Нет уведомлений!'
                        )}
                    </div>
                )}
            </Card>
        </StudentLayout>
    );
};
