import { AttendanceStatus } from 'src/@types/modules/attendance/attendance.types'
import { LessonStatusPlain } from 'src/api/endpoints/attendance/attendance.types'

export type LessonTeacher = {
    id: number;
    fio: string; // TODO: Поменять на сервере на fullname
}

export type LessonSubject = {
    id: number;
    name: string;
}

export type LessonAudience = {
    id: number;
    name: string;
}

export type AttendanceLesson = {
    id: number;
    number: number;
    startedAt: Date;
    finishedAt: Date;
    group: Group;
    teacher: LessonTeacher;
    subject: LessonSubject;
    audience: LessonAudience;
}

export enum StudentAttendanceStatus {
    Present = 0,
    Absent = 1,
    Late = 2
}

export type AttendanceLessonInformation = {
    id: number;
    index: number;
    status: LessonStatusPlain; // TODO: Закатать в енам
    lesson: AttendanceLesson;
}

export type StudentAttendanceStatisticsResponse = {
    lessons: AttendanceLessonInformation[],
    statuses: AttendanceStatus[];
}

export type GetStudentStatisticArguments = {
    userId: number;
}

export interface GetShortStatisticsResult {
    profile: StudentProfile;
    performance: Performance;
    attendance: StudentAttendanceStatistic;
}

export type StudentProfile = {
    id: number;
    status: Status;
    user: User;
    group: Group;
    coins: number;
}

interface Status {
    id: number;
    name: string;
}

interface User {
    id: number;
    role: Role;
    firstname: string;
    lastname: string;
    patronymic: string;
    avatar: string;
    permissions: Permissions;
}

interface Role {
    id: number;
    key: string;
    name: string;
}

interface Permissions {
    canBeEditMaterials: boolean;
    canBeEditTeachers: boolean;
    canBeEditSubjects: boolean;
}

interface Group {
    id: number;
    name: string;
    status: Status;
    details: GroupDetails;
    meta: GroupMeta;
}

interface GroupDetails {
    morale: number;
    capacity: Capacity;
    launchYear: number;
}

interface Capacity {
    total: number;
    occupied: number;
}

interface GroupMeta {
    actions: GroupActions;
}

interface GroupActions {
    canBeFrozen: boolean;
    canBeUnfrozen: boolean;
    canBeDisband: boolean;
    canBeMerged: boolean;
    canBeReleased: boolean;
}

interface Performance {
    marks: Marks;
    homework: Homework;
    material: Material;
    lesson: Lesson;
}

interface Marks {
    average: AverageMarks;
    last: LastMark[];
}

interface AverageMarks {
    current: number;
    previous: number[];
}

interface LastMark {
    id: number;
    mark: number;
    date: number;
}

interface Homework {
    total: number;
    upcoming: number;
    expired: number;
    completed: number;
    pending: number;
    currentTask: CurrentTask;
}

interface CurrentTask {
    name: string;
    description: string;
    expiryAt: number;
}

interface Material {
    id: number;
    name: string;
    image: string;
    theme: string;
    source: string;
    createdAt: number;
}

interface Lesson {
    id: number;
    time: number;
    theme: string;
}

interface StudentAttendanceStatistic {
    attended: number;
    missed: number;
    late: number;
    total: number;
}
