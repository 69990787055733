import { LessonType, StudentAttendance } from 'src/@types/modules/attendance/attendance.types'
import { StudentAttendanceStatus } from 'src/@types/modules/attendance/attendance.enums'
import { FC, PropsWithChildren, ReactElement } from 'react'
import styles from '../AttendantPage.module.scss'

export function num_word(value: number, words: Array<string>): string {
    value = Math.abs(value) % 100
    
    const units: number = value % 10
    
    if (value > 10 && value < 20) return words[2]
    if (units > 1 && units < 5) return words[1]
    if (units === 1) return words[0]
    return words[2]
}

export function getMarkClassname(mark: number) {
    return mark === 0
           ? styles.noMark
           : mark >= 1 && mark < 4
             ? styles.bad
             : mark > 3 && mark < 8
               ? styles.ok
               : styles.good
}

export function isDisabled(attendance: StudentAttendance, lessonType?: LessonType): boolean {
    return (
        !attendance.status
        || attendance.status.id === StudentAttendanceStatus.Absent
        || !lessonType
    )
}

export const marks: string[] = [
    'Нет оценки',
    ...Array.from({ length: 10 }, (_, i) => {
        const num = i + 1
        return `${num} ${num_word(num, ['балл', 'балла', 'баллов'])}`
    }),
]

export const Statuses = {
    0: styles.good,
    2: styles.absence,
    3: styles.noMark,
}

export const Label: FC<PropsWithChildren> = (props: PropsWithChildren): ReactElement => {
    return <span className={styles.label}>{props.children}</span>
}

export type FormItemProps = PropsWithChildren & {
    label: string
}

export const FormItem: FC<FormItemProps> = (props: FormItemProps): ReactElement => {
    return (
        <div className={styles.formItem}>
            <Label>{props.label}</Label>
            {props.children}
        </div>
    )
}
