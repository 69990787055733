import classNames from 'classnames';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FC, useState } from 'react';
import { Modal } from 'src/components/Modal/Modal';
import useAppDispatch from 'src/hooks/useAppDispatch';
import useAppSelector from 'src/hooks/useAppSelector';
import { updateSubjectName } from 'src/store/admin/subject/subject.actions';
import { NotificationTypes, _notify } from 'src/utils/notify'

interface Props {
    id: number;
    name: string;
}
interface Form {
    name: string;
}

export const EditButton: FC<Props> = ({ id, name }) => {
    const dispatch = useAppDispatch();
    const {
        subjects,
        updateSubjectName: { pending },
    } = useAppSelector(state => state.adminSubjectSlice);

    const [visible, setVisible] = useState(false);
    const formik = useFormik<Form>({
        initialValues: {
            name,
        },
        validate: data => {
            const errors: Partial<Record<keyof Form, any>> = {};

            if (!data.name.trim()) {
                errors.name = 'Введите название предмета!';
            }
            return errors;
        },
        onSubmit: data => {
            dispatch(
                updateSubjectName({
                    id,
                    name: data.name,
                })
            )
                .unwrap()
                .catch(() =>
                    _notify(window.Toast, {
                        type: NotificationTypes.error,
                        content: 'Не получилось обновить название предмета',
                    })
                );

            setVisible(false);
        },
    });

    const isFormFieldValid = (name: keyof Form) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: keyof Form) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    return (
        <>
            <Modal
                header={'Обновить название урока'}
                visible={visible}
                onHide={() => setVisible(false)}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="field">
                        <span className="p-float-label">
                            <InputText
                                id="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                className={classNames('w-full', {
                                    'p-invalid': isFormFieldValid('name'),
                                })}
                            />
                            <label
                                htmlFor="comment"
                                className={classNames({
                                    'p-error': isFormFieldValid('name'),
                                })}
                            >
                                Название предмета
                            </label>
                        </span>
                        {getFormErrorMessage('name')}
                    </div>
                    <Button
                        loading={pending}
                        type="submit"
                        label="Сохранить"
                        className={'w-auto'}
                    />
                </form>
            </Modal>
            <Button icon="pi pi-file-edit" onClick={() => setVisible(true)} />
        </>
    );
};
