import { HttpMethod, PathEntityIdentifier, RequestBody } from 'src/@types/modules/api.types'
import { Group, GroupLearningProgress, QueryPayload, SortingData } from 'src/api'
import { baseApi } from 'src/api/baseApi'

const api = baseApi.injectEndpoints({
        endpoints: builder => ({
            getGroups: builder.query<Group[], string>({
                providesTags: (result?: Group[]) =>
                    result
                    ? [...result.map((group: Group) => ({ type: 'Group' as const, id: group.id }))]
                    : ['Group'],
                query: (queryFilter = '') => ({
                    url: `/groups?` + queryFilter,
                    params: {
                        page: 0,
                        size: 10000,
                    },
                    method: 'get',
                    errorMessage: 'Не удалось получить информацию о группах!',
                }),
                transformResponse: (responseBody: any): Group[] => {
                    return responseBody.content
                },
            }),
            createGroup: builder.mutation<
                void,
                { name: string; capacity: number; year: number, expectedLessons: number }
            >({
                invalidatesTags: ['Group'],
                query: arg => ({
                    url: '/groups',
                    method: 'post',
                    data: arg,
                    errorMessage: 'Не удалось создать группу!',
                }),
            }),
            disbandGroup: builder.mutation<void, { id: number }>({
                invalidatesTags: ['Group'],
                query: ({ id }) => ({
                    url: `/groups/${id}/disband`,
                    method: 'post',
                    errorMessage: 'Не удалось расформировать группу!',
                }),
            }),
            freezeGroup: builder.mutation<
                void,
                { id: number; startAt: number; endsAt: number; reason: string }
            >({
                invalidatesTags: ['Group'],
                query: ({ id, ...rest }) => ({
                    url: `/groups/${id}/freeze`,
                    method: 'post',
                    data: rest,
                    errorMessage: 'Не удалось заморозить группу!',
                }),
            }),
            unfreezeGroup: builder.mutation<void, { id: number }>({
                invalidatesTags: ['Group'],
                query: ({ id }) => ({
                    url: `/groups/${id}/unfreeze`,
                    method: 'post',
                    errorMessage: 'Не удалось разморозить группу!',
                }),
            }),
            releaseGroup: builder.mutation<
                void,
                { id: number; releasedAt: number }
            >({
                invalidatesTags: ['Group'],
                query: ({ id, releasedAt }) => ({
                    url: `/groups/${id}/release`,
                    method: 'post',
                    data: { releasedAt },
                    errorMessage: 'Не удалось выпустить группу!',
                }),
            }),
            getMergeMeta: builder.query<Group[], { id: number }>({
                query: ({ id }) => ({
                    url: `/groups/${id}/merge`,
                    method: 'get',
                    errorMessage: 'Не удалось получить информацию о группах!',
                }),
                transformResponse(val: any) {
                    return val.availableTargets
                },
            }),
            mergeGroup: builder.mutation<void, { id: number; mergeInto: number }>({
                invalidatesTags: ['Group'],
                query: ({ id, mergeInto }) => ({
                    url: `/groups/${id}/merge`,
                    method: 'post',
                    data: { mergeInto },
                    errorMessage: 'Не удалось объединить группы!',
                }),
            }),
            getGroupSearchData: builder.query<SortingData, void>({
                query: () => ({
                    url: '/groups/page',
                    method: 'get',
                    errorMessage:
                        'Не удалось получить информацию о группах для сортировки!',
                }),
            }),
            getGroupById: builder.query<Group, number>({
                providesTags: (result: Group | undefined) =>
                    [result ? { type: 'Group' as const, id: result.id } : 'Group'],
                query: id => ({
                    url: `/groups/${id}`,
                    method: 'get',
                    errorMessage: 'Не удалось получить информацию о группах!',
                }),
            }),
            getGroupLearningProgress: builder.query<GroupLearningProgress, number>({
                providesTags: (_result, _error, arg) => [{ type: 'Group', id: arg }],
                query: (id: number) => ({
                    url: `/groups/${id}/metrics/lessons`,
                    method: 'GET',
                    errorMessage: 'Не удалось получить информацию о количестве отработанных пар!',
                }),
            }),
            updateGroup: builder.mutation<void, UpdateGroupRequestPayload>({
                invalidatesTags: (
                    _result: void | undefined,
                    _error: unknown,
                    payload: UpdateGroupRequestPayload,
                ) =>
                    [{ type: 'Group' as const, id: payload.path.groupId }],
                query: (payload: UpdateGroupRequestPayload): QueryPayload => ({
                    url: `/groups/${payload.path.groupId}`,
                    method: HttpMethod.Patch,
                    data: payload.body,
                    errorMessage: 'Не удалось обновить информацию о группе',
                }),
            }),
        }),
    },
)

export type UpdatingGroup = {
    name: string;
    capacity: number;
    year: number;
    expectedLessons: number;
}

export type UpdateGroupRequestPayload =
    PathEntityIdentifier<'groupId'>
    & RequestBody<UpdatingGroup>

export const {
    useGetGroupsQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useDisbandGroupMutation,
    useFreezeGroupMutation,
    useUnfreezeGroupMutation,
    useReleaseGroupMutation,
    useGetMergeMetaQuery,
    useMergeGroupMutation,
    useGetGroupSearchDataQuery,
    useGetGroupByIdQuery,
    useGetGroupLearningProgressQuery,
} = api
