import { EducationDepartmentPaths, EducationDepartmentRoutesNavigation } from 'src/routes/roles/EducationDepartment'
import { dateFormat, dateFormatWithTime, displayFormat } from 'src/constants/constants'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { useGetAcademicLeaveByIdQuery } from 'src/api/endpoints/academic'
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { useGetShortStatisticsQuery } from 'src/api'
import { Link, useParams } from 'react-router-dom'
import { getTime } from 'src/utils/date/getTime'
import { dayWorker } from 'src/utils/dateWorker'
import { Avatar } from 'src/components/Avatar'
import { MenuItem } from 'primereact/menuitem'
import { classNames } from 'primereact/utils'
import { Helmet } from 'react-helmet-async'
import Loader from 'src/components/Loader'
import { Button } from 'primereact/button'
import cifra from 'src/static/cifra.svg'
import { Menu } from 'primereact/menu'
import { map } from 'lodash'
import {
    ContinueAcademicVacationModal,
    CreateAcademicVacationModal,
    ExpelStudentModal,
    OutOfAcademicVacationModal,
} from 'src/components/AcademicVacationModals'

export const StudentPage: FC = (): ReactElement => {
    const { id } = useParams()
    const [skipAcademicDataFetch, setSkipAcademicDataFetch] = useState(true)
    const { isLoading, data } = useGetShortStatisticsQuery({
        userId: Number(id),
    })
    const { isLoading: isAcademicLoading, data: academicData } =
        useGetAcademicLeaveByIdQuery(data?.profile.id || -1, {
            skip: skipAcademicDataFetch,
        })
    const menuRef = useRef<Menu>(null)
    
    const [outOfAcademicModalVisible, setOutOfAcademicModalVisible] =
        useState(false)
    
    const [continueAcademicModalVisible, setContinueOfAcademicModalVisible] =
        useState(false)
    
    const [expelStudentModalVisible, setExpelStudentModalVisible] =
        useState(false)
    
    const [createAcademicModalVisible, setCreateAcademicModalVisible] =
        useState(false)
    
    const FROZEN_STUDENT_ACTIONS: MenuItem[] = [
        {
            label: 'Вывести',
            command: () => setOutOfAcademicModalVisible(true),
        },
        {
            label: 'Продлить',
            command: () => setContinueOfAcademicModalVisible(true),
        },
        {
            label: 'Отчислить',
            command: () => setExpelStudentModalVisible(true),
        },
    ]
    const ACTIVE_STUDENT_ACTIONS: MenuItem[] = [
        {
            label: 'Отправить в академ. отпуск',
            command: () => setCreateAcademicModalVisible(true),
        },
        {
            label: 'Отчислить',
            command: () => setExpelStudentModalVisible(true),
        },
    ]
    const BUTTON_ACTIONS = {
        'Действующий': ACTIVE_STUDENT_ACTIONS,
        'В академическом отпуске': FROZEN_STUDENT_ACTIONS,
        'Отчислен': [
            {
                label: 'Действий нет',
                
                disabled: true,
            },
        ],
        'Выпущен': [
            {
                label: 'Действий нет',
                
                disabled: true,
            },
        ],
    }
    
    useEffect(() => {
        if (
            data?.profile.id &&
            data.profile.status.name === 'В академическом отпуске'
        ) {
            setSkipAcademicDataFetch(false)
        }
    }, [data])
    
    return (
        <StudentLayout>
            <Helmet title={'Просмотр студента'} />
            {isLoading || isAcademicLoading ? (
                <Loader />
            ) : (
                <>
                    <>
                        <div className="grid">
                            <div className="flex flex-column lg:col-4 col-12 card py-0">
                                <div className="header flex align-items-center justify-content-between points-bg">
                                    <div className="title">Студент</div>
                                </div>
                                <div className="content flex-1">
                                    <div className="area bg">
                                        <div
                                            className={
                                                'flex flex-column align-items-center gap-2'
                                            }
                                        >
                                            <Avatar
                                                width={124}
                                                height={124}
                                                src={data?.profile.user.avatar}
                                            />
                                            <div
                                                className={
                                                    'flex flex-column gap-2 text-center align-items-center'
                                                }
                                            >
                                                <div
                                                    className="title points-text">{`${data?.profile.user.firstname} ${data?.profile.user.lastname} ${data?.profile.user.patronymic}`}</div>
                                                <div>
                                                    {data?.profile.group.name}
                                                </div>
                                                <div>
                                                    Статус:{' '}
                                                    {data?.profile.status.name}
                                                </div>
                                            </div>
                                            <div
                                                className="w-full flex flex-row justify-content-between flex-wrap mt-48">
                                                <div className="coin flex align-items-center justify-content-center">
                                                    <img
                                                        src={cifra}
                                                        alt="cifra"
                                                    />
                                                    <div className="ml-2">
                                                        {data?.profile.coins}
                                                    </div>
                                                </div>
                                                <div>
                                                    <Menu
                                                        model={
                                                            BUTTON_ACTIONS[
                                                                data!.profile
                                                                    .status.name
                                                                ] || []
                                                        }
                                                        popup
                                                        ref={menuRef}
                                                        id="popup_menu_left"
                                                    />
                                                    <Button
                                                        className="mt-2"
                                                        size="small"
                                                        label="Действия"
                                                        icon="pi pi-chevron-down"
                                                        iconPos="right"
                                                        onClick={event => {
                                                            if (
                                                                menuRef.current
                                                            ) {
                                                                menuRef.current.toggle(
                                                                    event,
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:col-4 col-12 card py-0 md-mt-24">
                                <div className="header flex align-items-center justify-content-between points-bg">
                                    <div className="title">Успеваемость</div>
                                </div>
                                <div className="content">
                                    <div
                                        className="area bg"
                                        style={{ height: '137.117px' }}
                                    >
                                        <div className="title points-text">
                                            Средний балл
                                        </div>
                                        <div className="flex align-items-end text">
                                            <div className="points-text h1 border">
                                                {
                                                    data?.performance.marks
                                                        .average.current
                                                }
                                            </div>
                                            {map(
                                                data?.performance.marks.average
                                                    .previous,
                                                (point, idx) => (
                                                    <div
                                                        className={`h${idx + 2 > 3 ? 3 : idx + 2} border`}
                                                        key={point}
                                                    >
                                                        {point}
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                    <div className="grid">
                                        {data?.performance.marks.last ? (
                                            data?.performance.marks.last.map(
                                                (point, idx) => (
                                                    <div
                                                        className="col-4 py-0 xs-col-6"
                                                        id={classNames({
                                                            first: idx <= 1,
                                                        })}
                                                        key={
                                                            point.date +
                                                            point.mark
                                                        }
                                                    >
                                                        <div
                                                            className="area bg"
                                                            style={{
                                                                height: 152.233,
                                                            }}
                                                        >
                                                            <div className="title">
                                                                Получено
                                                            </div>
                                                            <div className="h1 dark">
                                                                {point.mark}
                                                            </div>
                                                            <small className="date">
                                                                {dayWorker(
                                                                    point.date,
                                                                ).format(
                                                                    dateFormat,
                                                                )}
                                                            </small>
                                                        </div>
                                                    </div>
                                                ),
                                            )
                                        ) : (
                                             <div
                                                 className="col-4 py-0 xs-col-6"
                                                 id="first"
                                             >
                                                 <div
                                                     className="area bg"
                                                     style={{ height: 152.233 }}
                                                 >
                                                     <div className="title">
                                                         Пока нет оценок
                                                     </div>
                                                 </div>
                                             </div>
                                         )}
                                    </div>
                                </div>
                            </div>
                            <div className="lg:col-4 col-12 card py-0 md-mt-24">
                                <div className="header flex align-items-center justify-content-between homework-bg">
                                    <div className="title">
                                        Домашнее задание
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="grid mt-0 h-full">
                                        <div
                                            className={
                                                (data?.performance.homework
                                                     .currentTask
                                                 ? 'col-7'
                                                 : 'col-12') +
                                                ' xs-col-12 py-0 relative'
                                            }
                                        >
                                            <div className="grid mt-0">
                                                <div className="col-6 py-0">
                                                    <div
                                                        className="area bg"
                                                        style={{
                                                            height: 137.117,
                                                        }}
                                                    >
                                                        <div className="title homework-text">
                                                            Всего заданий
                                                            <br />
                                                        </div>
                                                        <div className="h1 dark">
                                                            {
                                                                data
                                                                    ?.performance
                                                                    .homework
                                                                    .total
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 py-0">
                                                    <div
                                                        className="area bg"
                                                        style={{
                                                            height: 137.117,
                                                        }}
                                                    >
                                                        <div className="title">
                                                            Текущие задания
                                                        </div>
                                                        <div className="h1 dark">
                                                            {
                                                                data
                                                                    ?.performance
                                                                    .homework
                                                                    .upcoming
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid">
                                                <div className="col-6 py-0">
                                                    <div
                                                        className="area bg"
                                                        style={{
                                                            height: 152.233,
                                                        }}
                                                    >
                                                        <div className="title">
                                                            Просрочено заданий
                                                        </div>
                                                        <div className="h1 dark">
                                                            {
                                                                data
                                                                    ?.performance
                                                                    .homework
                                                                    .expired
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 py-0">
                                                    <div
                                                        className="area bg"
                                                        style={{
                                                            height: 152.233,
                                                        }}
                                                    >
                                                        <div className="title">
                                                            Проверено заданий
                                                        </div>
                                                        <div className="h1 dark">
                                                            {
                                                                data
                                                                    ?.performance
                                                                    .homework
                                                                    .completed
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {data?.performance.homework
                                             .currentTask ? (
                                             <div className="col-5 xs-col-12 py-0">
                                                 <div
                                                     className="area overflow-y-scroll flex flex-column justify-content-between h-full">
                                                     <div>
                                                         <div className="title light sm-mt-24">
                                                             Ближайшее
                                                         </div>
                                                         <div className="text bold fz-20">
                                                             {
                                                                 data
                                                                     ?.performance
                                                                     .homework
                                                                     .currentTask
                                                                     .name
                                                             }
                                                         </div>
                                                         <small className="text">
                                                             {
                                                                 data
                                                                     ?.performance
                                                                     .homework
                                                                     .currentTask
                                                                     .description
                                                             }
                                                         </small>
                                                     </div>
                                                     <small className="text date">
                                                         До{' '}
                                                         {dayWorker(
                                                             data?.performance
                                                                 .homework
                                                                 .currentTask
                                                                 .expiryAt,
                                                         ).format(
                                                             dateFormatWithTime,
                                                         )}
                                                     </small>
                                                 </div>
                                             </div>
                                         ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="lg:col-4 col-12 card py-0">
                                <div className="header flex align-items-center justify-content-between materials-bg">
                                    <div className="title">
                                        Учебные материалы
                                    </div>
                                </div>
                                <div
                                    className="content"
                                    style={{ height: 305.35 }}
                                >
                                    {data?.performance.material ? (
                                        <div
                                            className="area flex flex-column justify-content-between h-full overflow-y-scroll">
                                            <div>
                                                <div className="flex justify-content-between">
                                                    <div className="title materials-text">
                                                        Последнее поступление:
                                                    </div>
                                                </div>
                                                <small className="date">
                                                    {dayWorker(
                                                        data.performance
                                                            .material.createdAt,
                                                    ).format(displayFormat)}
                                                </small>
                                                <div className="text fz-20 mt-6">
                                                    {
                                                        data.performance
                                                            .material.theme
                                                    }
                                                </div>
                                                <div className="text fz-20 bold mt-24">
                                                    {
                                                        data.performance
                                                            .material.name
                                                    }
                                                </div>
                                            </div>
                                            <small className="date mt-24">
                                                {
                                                    data.performance.material
                                                        .source
                                                }
                                            </small>
                                        </div>
                                    ) : (
                                         <div className="text fz-20">
                                             Здесь пока ничего нет
                                         </div>
                                     )}
                                </div>
                            </div>
                            <div className="lg:col-4 col-12 card py-0 md-mt-24">
                                <div className="header flex align-items-center justify-content-between timetable-bg">
                                    <div className="title">Расписание</div>
                                </div>
                                <div
                                    className="content"
                                    style={{ height: 305.35 }}
                                >
                                    {data?.performance.lesson ? (
                                        <div className="area overflow-y-scroll max-h-full">
                                            <div className="title timetable-text">
                                                Следующее занятие:
                                            </div>
                                            <div className="text fz-20 bold mt-6">
                                                {dayWorker(
                                                    data?.performance.lesson
                                                        .time,
                                                ).format(dateFormatWithTime)}
                                                <br />
                                                {getTime(
                                                    data?.performance.lesson
                                                        .time,
                                                )}
                                            </div>
                                            <small className="date mt-24">
                                                Тема занятия:
                                            </small>
                                            <div className="text fz-20 bold mt-6">
                                                {data?.performance.lesson.theme}
                                            </div>
                                        </div>
                                    ) : (
                                         <div className="text fz-20">
                                             Здесь пока ничего нет
                                         </div>
                                     )}
                                </div>
                            </div>
                            <div className="lg:col-4 col-12 card py-0 md-mt-24 payment-card">
                                <div className="header flex align-items-center justify-content-between payment-bg">
                                    <div className="title">Посещаемость</div>
                                    <Link
                                        to={EducationDepartmentRoutesNavigation[EducationDepartmentPaths.StudentAttendancePage](id)}
                                    >
                                        <Button
                                            icon="pi pi-chevron-right white"
                                            className="p-button-rounded p-button-outlined"
                                        />
                                    </Link>
                                </div>
                                <div
                                    className="content overflow-y-scroll max-h-full"
                                    style={{ height: 305.35 }}
                                >
                                    {data?.attendance ? (
                                        <>
                                            <div className="area bg">
                                                <div className="title">
                                                    Пар было
                                                </div>
                                                <div className="h1 dark">
                                                    {
                                                        data.attendance.total
                                                    }
                                                </div>
                                                <div className="title mt-2">
                                                    Присутствовал на
                                                </div>
                                                <div className="h1 dark">
                                                    {data.attendance.attended + data.attendance.late}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                         <div className="text fz-20">
                                             Здесь пока ничего нет
                                         </div>
                                     )}
                                </div>
                            </div>
                        </div>
                    </>
                    
                    {academicData?.id ? (
                        <>
                            <OutOfAcademicVacationModal
                                visible={outOfAcademicModalVisible}
                                onHide={() =>
                                    setOutOfAcademicModalVisible(false)
                                }
                                studentName={`${data?.profile.user.firstname} ${data?.profile.user.lastname} ${data?.profile.user.patronymic}`}
                                academicId={academicData.id}
                            />
                            <ContinueAcademicVacationModal
                                visible={continueAcademicModalVisible}
                                onHide={() =>
                                    setContinueOfAcademicModalVisible(false)
                                }
                                studentName={`${data?.profile.user.firstname} ${data?.profile.user.lastname} ${data?.profile.user.patronymic}`}
                                academicId={academicData.id}
                            />
                        </>
                    ) : null}
                    {data?.profile.id ? (
                        <>
                            <ExpelStudentModal
                                visible={expelStudentModalVisible}
                                onHide={(): void =>
                                    setExpelStudentModalVisible(false)
                                }
                                studentName={`${data?.profile.user.firstname} ${data?.profile.user.lastname} ${data?.profile.user.patronymic}`}
                                studentId={data.profile.id}
                            />
                            <CreateAcademicVacationModal
                                studentId={data.profile.id}
                                visible={createAcademicModalVisible}
                                onHide={(): void =>
                                    setCreateAcademicModalVisible(false)
                                }
                            />
                        </>
                    ) : null}
                </>
            )}
        </StudentLayout>
    )
}
