import { EducationDepartmentPaths } from 'src/routes/roles/EducationDepartment'
import { EDRNavigation } from 'src/routes/roles/EducationDepartment/navigation'
import { GroupMetrics } from 'src/api/endpoints/group/metrics/metrics.types'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { CircleDiagram } from 'src/components/CircleDiagram'
import { FC, ReactElement } from 'react'

export type GroupMetricsDiagramsProps = {
    groupId: number;
    metrics: GroupMetrics;
};

export const GroupMetricsDiagrams: FC<GroupMetricsDiagramsProps> = (props: GroupMetricsDiagramsProps): ReactElement => {
    const { groupId, metrics } = props
    
    const navigate: NavigateFunction = useNavigate()
    
    const attendancePathname: string =
        EDRNavigation[EducationDepartmentPaths.AttendancePage]()
    
    const attendanceSearchParams: URLSearchParams = new URLSearchParams({
        groupId: '' + groupId,
    })
    
    
    const onPresenceDiagramClick: VoidFunction = (): void => {
        navigate({
            pathname: attendancePathname,
            search: attendanceSearchParams.toString(),
        })
    }
    
    return <div className="grid m-0">
        <div className="col-6 xl:col-3">
            <CircleDiagram
                title="Посещаемость"
                values={{
                    isPercentage: true,
                    value: metrics.presencePercentage,
                }}
                clickable={true}
                actions={{
                    onClick: onPresenceDiagramClick,
                }}
                color="#C56AA0"
            />
        </div>
        <div className="col-6 xl:col-3">
            <CircleDiagram
                title="Опоздания"
                values={{
                    isPercentage: true,
                    value: metrics.latenessPercentage,
                }}
                clickable={false}
                color="#C5A06A"
            />
        </div>
        <div className="col-6 xl:col-3">
            <CircleDiagram
                title="Средний балл"
                values={{
                    isPercentage: false,
                    value: Math.round(metrics.averageGrade * 100) / 100,
                    max: 10,
                }}
                clickable={false}
                color="#79C294"
            />
        </div>
        <div className="col-6 xl:col-3">
            <CircleDiagram
                title="Задания"
                values={{
                    isPercentage: true,
                    value: metrics.homeworksPercentage,
                }}
                clickable={false}
                color="#9D6AC5"
            />
        </div>
        <div className="col-6 xl:col-3">
            <CircleDiagram
                title="Заполненность"
                values={{
                    isPercentage: true,
                    value: metrics.staffingPercentage,
                }}
                clickable={false}
                color="#5CCFF2"
            />
        </div>
    </div>
}