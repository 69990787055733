import { LessonMaterialInformation } from 'src/api/endpoints/lessons/lessons.types'
import { Formik, useFormik } from 'formik'
import { ReactNode } from 'react'
import {
    getFormikFormErrorMessage,
    getLessonMaterialFormInitialValues,
    isFormikFormFieldValid,
    lessonMaterialFormSubmitter,
    lessonMaterialFormValidator,
} from 'src/pages/Attendant/form/form.functions'
import {
    FieldValidator,
    FormHook,
    FormHookValues,
    FormSubmitter,
    FormSubmitValues,
    GetFieldErrorFunction,
    LessonMaterialFormValues,
} from 'src/pages/Attendant/form/form.types'

export const useLessonMaterialForm: FormHook<LessonMaterialInformation, LessonMaterialFormValues, FormSubmitValues> = (
    initialValues: LessonMaterialInformation,
    onSubmit: FormSubmitter<FormSubmitValues>,
): FormHookValues<LessonMaterialFormValues> => {
    const onLessonMaterialFormSubmit: FormSubmitter<LessonMaterialFormValues> =
        (values: LessonMaterialFormValues): void => lessonMaterialFormSubmitter(values, onSubmit)
    
    const formik: Formik<LessonMaterialFormValues> = useFormik<LessonMaterialFormValues>({
        initialValues: getLessonMaterialFormInitialValues(initialValues),
        validate: lessonMaterialFormValidator,
        onSubmit: onLessonMaterialFormSubmit,
    })
    
    const fieldIsValid: FieldValidator<LessonMaterialFormValues> =
        (field: keyof LessonMaterialFormValues): boolean => isFormikFormFieldValid(formik, field)
    
    const getFieldErrorMessage: GetFieldErrorFunction<LessonMaterialFormValues> =
        (field: keyof LessonMaterialFormValues): ReactNode => getFormikFormErrorMessage(formik, field)
    
    return [formik, fieldIsValid, getFieldErrorMessage]
}