import { useGetLessonMaterialQuery, useUploadLessonMaterialMutation } from 'src/api/endpoints/lessons/lessons.api'
import { LessonMaterial, LessonMaterialInformation } from 'src/api/endpoints/lessons/lessons.types'
import { FC, ReactElement, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { getLessonComponents } from 'src/pages/admin/Homework/functions/getLessonComponents'
import { LessonMaterialAttachingForm } from 'src/pages/Attendant/components/Modal'
import { notifyAboutException, notifyAboutSuccess } from 'src/utils/notify'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { AttendanceTable } from 'src/pages/Attendant/components/Table'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { FormSubmitter } from 'src/pages/Attendant/form/form.types'
import styles from 'src/pages/Attendant/AttendantPage.module.scss'
import { ClassType } from 'src/components/ClassType/ClassType'
import { SelectItem } from 'primereact/selectitem'
import { skipToken } from '@reduxjs/toolkit/query'
import { Modal } from 'src/components/Modal/Modal'
import { useNumberParam } from 'src/hooks/params'
import { Nullable } from 'primereact/ts-helpers'
import { Card } from 'src/components/Card/Card'
import Loader from '../../components/Loader'
import { Helmet } from 'react-helmet-async'
import { Button } from 'primereact/button'
import { FormItem } from './utils/utils'
import { Toast } from 'primereact/toast'
import { map, size } from 'lodash'
import {
    useGetAvailableGroupSubjectsWithAttendanceQuery,
    useGetAvailableGroupsWithAttendanceQuery,
    useGetDataForAttendanceMarkingQuery,
    useGetGroupAttendanceLessonsBySubjectQuery,
    useGetStudentsAttendanceByLessonQuery,
    useUpdateStudentAttendanceMutation,
} from 'src/api/endpoints/attendance'
import {
    AttendanceLesson,
    AttendanceStatus,
    LessonType,
    StudentAttendance, UpdatingStudentAttendance, UpdatingStudentAttendanceBase,
} from 'src/@types/modules/attendance/attendance.types'
import { LessonStatus, StudentAttendanceStatus } from 'src/@types/modules/attendance/attendance.enums'
import { GroupShort } from 'src/@types/modules/groups/groups.types'
import { SubjectShort } from 'src/@types/modules/subjects/subjects.types'

export interface SelectedLessonType {
    type: LessonType;
    lessonId: number;
}

export const AttendantPage: FC = (): ReactElement => {
    const toast: RefObject<Toast> = useRef<Toast>(null)
    
    // region Get attendance statuses and lesson types
    const [attendanceStatuses, setAttendanceStatuses] = useState<AttendanceStatus[]>([])
    const [lessonTypes, setLessonTypes] = useState<LessonType[]>([])
    
    const {
        data: attendanceMarkingData,
        isLoading: attendanceMarkingDataIsLoading,
    } = useGetDataForAttendanceMarkingQuery()
    
    useEffect((): void => {
        if (attendanceMarkingDataIsLoading || !attendanceMarkingData) return
        
        setLessonTypes(attendanceMarkingData.types)
        setAttendanceStatuses(attendanceMarkingData.statuses)
    }, [
        attendanceMarkingDataIsLoading,
        attendanceMarkingData,
    ])
    // endregion
    
    const [uploadLessonMaterial] =
        useUploadLessonMaterialMutation()
    
    const [selectedLessonsTypes, setSelectedLessonsTypes] = useState<SelectedLessonType[]>([])
    
    // region Groups
    // Получаем список доступных для выбора групп
    const [selectedGroup, selectGroup] = useState<GroupShort | null>(null)
    const [selectedGroupId, setSelectedGroupId] = useNumberParam<GroupShort>({
        fieldName: 'groupId',
        ref: selectedGroup,
    })
    
    const {
        data: groups,
        isLoading: groupsIsLoading,
    } = useGetAvailableGroupsWithAttendanceQuery()
    
    useEffect((): void => {
        if (selectedGroupId === undefined) return
        
        // Если группы в текущий момент загружаются, то пропускаем эффект
        if (!groups || groupsIsLoading) return
        
        const foundedGroup: Nullable<GroupShort> = groups.find(
            (group: GroupShort): boolean => group.id === selectedGroupId,
        )
        
        // Если указанная в параметре группа не нашлась, то сбрасываем параметр
        if (!foundedGroup) return setSelectedGroupId(null)
        
        // Иначе выбираем группы
        selectGroup(foundedGroup)
    }, [
        groupsIsLoading,
        selectedGroupId,
        groups,
    ])
    // endregion
    
    // region Subjects
    // Если группа выбрана, то мы получаем список доступных для просмотра посещаемости предметов
    const [selectedSubject, selectSubject] = useState<SubjectShort | null>(null)
    const [selectedSubjectId, setSelectedSubjectId] = useNumberParam<SubjectShort>({
        fieldName: 'subjectId',
        ref: selectedSubject,
    })
    
    const {
        data: subjects,
        isLoading: subjectsIsLoading,
    } = useGetAvailableGroupSubjectsWithAttendanceQuery(
        selectedGroup
        ? { path: { groupId: selectedGroup.id } }
        : skipToken,
    )
    
    useEffect((): void => {
        if (!selectedGroupId === null) return setSelectedSubjectId(null)
        
        if (!subjects || subjectsIsLoading) return
        
        const foundedSubject: Nullable<SubjectShort> = subjects.find(
            (subject: SubjectShort): boolean => subject.id === selectedSubjectId,
        )
        
        // Если указанный в параметре предмет не нашелся, то сбрасываем параметр
        if (!foundedSubject) return setSelectedSubjectId(null)
        
        // Иначе выбираем предмет группы
        selectSubject(foundedSubject)
    }, [
        subjectsIsLoading,
        selectedSubjectId,
        selectedGroupId,
        subjects,
    ])
    // endregion
    
    // region AttendanceLessons
    // Если группа и предмет выбраны, то мы получаем список уроков выбранной группы по выбранному предмету
    const [lessons, setLessons] = useState<AttendanceLesson[] | void>()
    
    const [selectedLesson, selectLesson] = useState<AttendanceLesson | null>(null)
    const [selectedLessonId, setSelectedLessonId] = useNumberParam<AttendanceLesson>({
        fieldName: 'lessonId',
        ref: selectedLesson,
    })
    
    const {
        data: lessonsResponse,
        isLoading: lessonsIsLoading,
    } = useGetGroupAttendanceLessonsBySubjectQuery(
        selectedGroup && selectedSubject
        ? {
                path: {
                    groupId: selectedGroup.id,
                    subjectId: selectedSubject.id,
                },
            }
        : skipToken,
    )
    
    useEffect((): void => {
        if (!selectedSubjectId === null) return setSelectedLessonId(null)
        
        // Если группы в текущий момент загружаются, то пропускаем эффект
        if (!lessonsResponse || lessonsIsLoading) return
        if (!lessons) return
        
        const foundedLesson: Nullable<AttendanceLesson> = lessons.find(
            (lesson: AttendanceLesson): boolean => lesson.id === selectedLessonId,
        )
        
        // Если указанная в параметре группа не нашлась, то сбрасываем параметр
        if (!foundedLesson) return setSelectedLessonId(null)
        
        // Иначе выбираем группы
        selectLesson(foundedLesson)
    }, [
        selectedSubjectId,
        selectedLessonId,
        lessonsIsLoading,
        lessons,
    ])
    
    useEffect((): void => {
        if (!lessonsResponse || lessonsIsLoading) return
        
        setLessons(lessonsResponse)
    }, [lessonsResponse, lessonsIsLoading])
    // endregion
    
    // region LessonMaterial attach information
    const [lessonHasMaterial, setLessonHasMaterial] = useState<boolean>(false)
    const [lessonMaterial, setLessonMaterial] = useState<LessonMaterialInformation>({
        attachment: null,
        isAttached: false,
    })
    
    const {
        data: lessonAttachedMaterial,
        isLoading: isLessonAttachedMaterialIsLoading,
    } = useGetLessonMaterialQuery(selectedLesson ? {
        path: { lessonId: selectedLesson.id },
    } : skipToken)
    
    useEffect((): void => {
        if (isLessonAttachedMaterialIsLoading || !lessonAttachedMaterial) return
        
        setLessonMaterial(lessonAttachedMaterial)
        setLessonHasMaterial(lessonAttachedMaterial.isAttached)
    }, [isLessonAttachedMaterialIsLoading, lessonAttachedMaterial])
    // endregion
    
    const [isLessonMaterialModalVisible, setLessonMaterialModalVisible] = useState(false)
    
    const [studentsAttendance, setStudentsAttendance] = useState<StudentAttendance[]>([])
    
    const {
        data: studentsAttendanceResponse,
        isLoading: studentsAttendanceResponseIsLoading,
    } = useGetStudentsAttendanceByLessonQuery(
        selectedLesson ? { path: { lessonId: selectedLesson.id } } : skipToken,
    )
    
    const [updateStudentAttendance] = useUpdateStudentAttendanceMutation()
    
    useEffect((): void => {
        if (!studentsAttendanceResponse || studentsAttendanceResponseIsLoading) return
        
        setStudentsAttendance(studentsAttendanceResponse)
    }, [studentsAttendanceResponse, studentsAttendanceResponseIsLoading])
    
    const transformStudentAttendance = (
        attendance: StudentAttendance,
        lessonType: LessonType,
    ): UpdatingStudentAttendance => {
        const partialAttendance: UpdatingStudentAttendanceBase = {
            lessonType: lessonType.id,
            status: attendance.status.id,
        }
        
        if (attendance.status.id === StudentAttendanceStatus.Absent) {
            return {
                ...partialAttendance,
                mark: 0,
                reward: 0,
                comment: null,
            }
        }
        
        return {
            ...partialAttendance,
            mark: attendance.mark,
            reward: attendance.reward,
            comment: attendance.comment,
        }
    }
    
    const onStudentAttendanceChanged = (
        attendance: StudentAttendance,
    ): void => {
        if (!selectedLesson) return
        
        const selectedLessonType: Nullable<LessonType> = selectedLessonsTypes.find(
            (selectedLessonType: SelectedLessonType): boolean =>
                selectedLessonType.lessonId === selectedLesson.id)?.type
        
        if (!selectedLessonType) return
        
        updateStudentAttendance({
            path: {
                lessonId: selectedLesson.id,
                attendanceId: attendance.id,
            },
            body: transformStudentAttendance(attendance, selectedLessonType),
        })
            .unwrap()
            .then((updatedStudentAttendance: StudentAttendance): void => {
                setStudentsAttendance((previous: StudentAttendance[]): StudentAttendance[] =>
                    previous.map((attendance: StudentAttendance): StudentAttendance => {
                        if (attendance.id === updatedStudentAttendance.id) {
                            return updatedStudentAttendance
                        }
                        return attendance
                    }))
            })
            .catch((reason: any): void => {
                notifyAboutException({
                    toast: toast,
                    message: reason.data,
                })
            })
    }
    
    const updateSelectedLessonTypes = (
        previousValues: SelectedLessonType[],
        lessonType: LessonType,
    ): SelectedLessonType[] => {
        if (!selectedLesson) return previousValues
        
        return previousValues
            .filter(
                (lessonSelectedType: SelectedLessonType): boolean =>
                    lessonSelectedType.lessonId !== selectedLesson.id,
            )
            .concat({
                type: lessonType,
                lessonId: selectedLesson.id,
            })
    }
    
    const resetLessons: VoidFunction = (): void => {
        selectLesson(null)
        setLessons()
    }
    
    const resetSubject: VoidFunction = (): void => {
        resetLessons()
        setSelectedSubjectId(null)
        selectSubject(null)
    }
    
    const onGroupSelectChange = (event: DropdownChangeEvent): void => {
        resetSubject()
        selectGroup(event.value as GroupShort)
    }
    
    const onSubjectSelectChange = (event: DropdownChangeEvent): void => {
        resetLessons()
        selectSubject(event.value as SubjectShort)
    }
    
    const getGroupsSelectItems: () => SelectItem<GroupShort>[] = useCallback(
        (): SelectItem<GroupShort>[] =>
            map(groups,
                (group: GroupShort): SelectItem<GroupShort> => ({
                    label: group.name,
                    value: group,
                })),
        [groups],
    )
    
    const getSubjectSelectItems: () => SelectItem<SubjectShort>[] = useCallback(
        (): SelectItem<SubjectShort>[] =>
            map(subjects,
                (subject: SubjectShort): SelectItem<SubjectShort> => ({
                    label: subject.name,
                    value: subject,
                })),
        [subjects],
    )
    
    const onSuccessLessonMaterialUploading: VoidFunction = (): void => {
        notifyAboutSuccess({
            toast: toast,
            message: 'Материал урока успешно обновлен',
        })
        
        setLessonMaterialModalVisible(false)
    }
    
    const onErrorWhenLessonMaterialUploading = (reason: any): void => {
        notifyAboutException({
            toast: toast,
            message: reason.data,
        })
    }
    
    const onLessonMaterialFormSubmit: FormSubmitter<LessonMaterial<Date>> = (form: LessonMaterial<Date>): void => {
        if (!selectedLesson) return
        
        uploadLessonMaterial({
            path: {
                lessonId: selectedLesson.id,
            },
            body: {
                materialId: form.material.id,
                expiresAt: form.expiresAt?.getTime() || null,
            },
        })
            .unwrap()
            .then(onSuccessLessonMaterialUploading)
            .catch(onErrorWhenLessonMaterialUploading)
    }
    
    const onLessonTypeSelected = (selectedType: LessonType): void => {
        setSelectedLessonsTypes(
            (previousValue: SelectedLessonType[]): SelectedLessonType[] =>
                updateSelectedLessonTypes(
                    previousValue,
                    selectedType,
                ),
        )
    }
    
    const getAttendanceStatusOptions = (): SelectItem<AttendanceStatus>[] => {
        return map(
            attendanceStatuses,
            (status: AttendanceStatus): SelectItem => ({
                label: status.name,
                value: status,
            }),
        )
    }
    
    return (
        <StudentLayout>
            <Toast ref={toast} />
            <Helmet title={'Посещаемость'} />
            <Card
                header={'Посещаемость'}
                className={'mt-24'}
                headerClassName={styles.header}
                contentClassName={'p-24'}
            >
                {selectedLesson && lessonMaterial && (
                    <Modal
                        header={'Методический материал'}
                        visible={isLessonMaterialModalVisible}
                        onHide={(): void => setLessonMaterialModalVisible(false)}
                    >
                        <LessonMaterialAttachingForm
                            lessonId={selectedLesson.id}
                            initialValues={lessonMaterial}
                            onSubmit={onLessonMaterialFormSubmit}
                        />
                    </Modal>
                )}
                
                {groupsIsLoading
                 ? (<Loader />)
                 : (
                     <>
                         <FormItem label={'Выбор группы'}>
                             <div className={'flex gap-24'}>
                                 <Dropdown
                                     filter
                                     value={selectedGroup}
                                     options={getGroupsSelectItems()}
                                     onChange={onGroupSelectChange}
                                 />
                                 {selectedGroup ? (
                                     <Dropdown
                                         filter
                                         value={selectedSubject}
                                         options={getSubjectSelectItems()}
                                         onChange={onSubjectSelectChange}
                                     />
                                 ) : null}
                             </div>
                         </FormItem>
                         
                         {lessons && size(lessons) ? (
                             <FormItem label={'Урок №'}>
                                 <div className={styles.classWrapper}>
                                     {getLessonComponents<AttendanceLesson>({
                                         lessons,
                                         onLessonClick: (lesson: AttendanceLesson): void => {
                                             selectLesson(lesson)
                                         },
                                         isSelected: (lesson: AttendanceLesson): boolean => {
                                             return lesson.id === selectedLesson?.id
                                         },
                                         isDisabled: (lesson: AttendanceLesson): boolean => {
                                             return lesson.status === LessonStatus.Scheduled
                                         },
                                     })}
                                 </div>
                             </FormItem>
                         ) : null}
                     </>
                 )}
                
                {selectedLesson ? (
                    isLessonAttachedMaterialIsLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <FormItem label={'Методический пакет'}>
                                <Button
                                    onClick={(): void => setLessonMaterialModalVisible(true)}
                                >
                                    {lessonHasMaterial
                                     ? 'Обновить'
                                     : 'Загрузить'}
                                </Button>
                            </FormItem>
                            
                            {size(lessonTypes) && size(attendanceStatuses) ? (
                                <FormItem label={'Тип урока'}>
                                    <div className={styles.classWrapper}>
                                        {map(lessonTypes,
                                            (lessonType: LessonType): ReactElement => (
                                                <ClassType
                                                    key={lessonType.id}
                                                    active={
                                                        lessonType.id ===
                                                        selectedLessonsTypes.find(
                                                            (lessonType: SelectedLessonType): boolean =>
                                                                lessonType.lessonId ===
                                                                selectedLesson.id,
                                                        )?.type.id
                                                    }
                                                    onClick={(): void =>
                                                        onLessonTypeSelected(lessonType)
                                                    }
                                                >
                                                    {lessonType.name}
                                                </ClassType>
                                            ),
                                        )}
                                    </div>
                                </FormItem>
                            ) : null}
                            
                            {studentsAttendanceResponseIsLoading ? (
                                <Loader />
                            ) : (
                                 <>
                                     {size(studentsAttendance) ? (
                                         <>
                                             <AttendanceTable
                                                 statusLoading={studentsAttendanceResponseIsLoading}
                                                 statusOptions={getAttendanceStatusOptions()}
                                                 attendance={studentsAttendance}
                                                 lessonType={
                                                     selectedLessonsTypes.find(
                                                         (lessonType: SelectedLessonType): boolean => {
                                                             return lessonType.lessonId ===
                                                                 selectedLesson.id
                                                         },
                                                     )?.type
                                                 }
                                                 onAttendanceChange={onStudentAttendanceChanged}
                                             />
                                         </>
                                     ) : null}
                                 </>
                             )}
                        </>
                    )
                ) : null}
            </Card>
        </StudentLayout>
    )
}
