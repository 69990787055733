import { Nullable } from 'primereact/ts-helpers'

export type SeparatedFullname = {
    name: Nullable<string>;
    surname: Nullable<string>;
    patronymic: Nullable<string>;
}

export type FullnameConverterFunction = (fullname: SeparatedFullname) => string;

const convertFullnameElement =
    (element: string, index: number): string => index ? element.substring(0, 1).toUpperCase() + '.' : element

export const getPatronymic: FullnameConverterFunction =
    (separatedFullname: SeparatedFullname): string =>
        String(separatedFullname.patronymic ? ' ' + separatedFullname.patronymic : '')

export const capitalizeWord: (word: Nullable<string>) => string = (word: Nullable<string>): string =>
    word ? String(word.substring(0, 1).toUpperCase() + word.substring(1)) : ''

export const getFullnameWithoutPatronymic: FullnameConverterFunction =
    (separatedFullname: SeparatedFullname): string =>
        String(`${capitalizeWord(separatedFullname.surname)} ${capitalizeWord(separatedFullname.name)}`)

export const getUserFullname: FullnameConverterFunction =
    <T extends SeparatedFullname = SeparatedFullname>(separatedFullname: T): string => {
        if (!separatedFullname.name || !separatedFullname.surname) return 'Нет данных'
        
        return getFullnameWithoutPatronymic(separatedFullname)
            .concat(getPatronymic(separatedFullname))
            .trim()
    }

export const getUserInitials: FullnameConverterFunction =
    <T extends SeparatedFullname = SeparatedFullname>(separatedFullname: T): string => {
        if (!separatedFullname.name || !separatedFullname.surname) return 'Нет данных'
        
        return getFullnameWithoutPatronymic(separatedFullname)
            .concat(getPatronymic(separatedFullname))
            .split(/\s+/)
            .map(convertFullnameElement)
            .join(' ')
    }
