import { FC, ImgHTMLAttributes, ReactElement, ReactEventHandler, SyntheticEvent } from 'react'
import { Nullable } from 'primereact/ts-helpers'
import avatarImg from '../../static/avatar.svg'
import styles from './Avatar.module.scss'
import { getServerPath } from 'src/http'
import classNames from 'classnames'

export type AvatarProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
    src: Nullable<string>;
};

export const Avatar: FC<AvatarProps> = (props: AvatarProps): ReactElement => {
    const { src, className, alt, ...rest } = props
    
    const onError: ReactEventHandler<HTMLImageElement> =
        (event: SyntheticEvent<HTMLImageElement>): void =>
            event.currentTarget.setAttribute('src', avatarImg)
    
    return (
        <img
            className={classNames(styles.avatar, className)}
            src={src ? `${getServerPath()}/${src}` : avatarImg}
            onError={onError}
            alt={alt}
            {...rest}
        />
    )
}
