import style from './Notification.module.scss'
import { classNames } from 'primereact/utils'
import React, { ReactNode } from 'react'
import parse from 'html-react-parser'
import { Check } from '../../static'

interface NotificationProps {
  title: ReactNode
  striped: boolean
  body: string
  date: ReactNode
  additional: ReactNode
  onCheck?: () => void
}

export const Notification = ({
  title,
  body,
  date,
  onCheck,
  additional,
  striped,
}: NotificationProps) => {
  return (
    <div
      className={classNames(style.notification, {
        [style.striped]: striped,
      })}
    >
      <div className={classNames(style.row, style.title)}>
        {title}
        {onCheck ? (
          <button
            style={{
              display: 'flex',
              background: 'inherit',
              cursor: 'pointer',
            }}
          >
            <Check onClick={onCheck} />
          </button>
        ) : null}
      </div>
      <div className={style.content}>
        {parse(body?.replace(/(?:\r\n|\r|\n)/g, '<br>'))}
      </div>
      <div className={classNames(style.footer, style.row)}>
        {date}
        {additional}
      </div>
    </div>
  )
}
