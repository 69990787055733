import { LessonBase } from 'src/@types/modules/lessons/lessons.types'
import { Lesson } from 'src/components/Lesson/Lesson'
import { ReactNode } from 'react'
import { map } from 'lodash'

export type GetLessonComponentParamsBase<LessonType extends LessonBase> = {
    onLessonClick: (lesson: LessonType) => void;
    isSelected: (lesson: LessonType) => boolean;
    isDisabled: (lesson: LessonType) => boolean;
}

export type GetLessonsComponentsParams<LessonType extends LessonBase> =
    GetLessonComponentParamsBase<LessonType>
    & { lessons: LessonType[] }

export type GetLessonComponentParams<LessonType extends LessonBase> =
    GetLessonComponentParamsBase<LessonType>
    & { lesson: LessonType }

export const getLessonComponents =
    <LessonType extends LessonBase, >
    (params: GetLessonsComponentsParams<LessonType>): ReactNode[] => {
        const lessonsMapper =
            (lesson: LessonType): ReactNode =>
                getLessonComponent({
                    lesson: lesson,
                    onLessonClick: params.onLessonClick,
                    isSelected: params.isSelected,
                    isDisabled: params.isDisabled,
                })
        
        return map(params.lessons, lessonsMapper)
    }

export const getLessonComponent =
    <LessonType extends LessonBase, >
    (params: GetLessonComponentParams<LessonType>): ReactNode => {
        const { lesson, onLessonClick, isSelected, isDisabled } = params
        
        const onClick: VoidFunction =
            (): void => onLessonClick(lesson)
        
        return (
            <Lesson
                key={lesson.id}
                disabled={isDisabled(lesson)}
                active={isSelected(lesson)}
                onClick={onClick}
            >
                {lesson.index}
            </Lesson>
        )
    }