import { EducationDepartmentPaths } from 'src/routes/roles/EducationDepartment'
import { EDRNavigation } from 'src/routes/roles/EducationDepartment/navigation'
import { AttendanceLessonInformation, useGetStatisticsQuery } from 'src/api'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import styles from '../../Attendant/AttendantPage.module.scss'
import { ColorIndicator } from 'src/components/ColorIndicator'
import { dateFormatWithTime } from 'src/constants/constants'
import { Lesson } from 'src/components/Lesson/Lesson'
import { Link, useParams } from 'react-router-dom'
import React, { FC, ReactElement } from 'react'
import { Card } from 'src/components/Card/Card'
import Loader from '../../../components/Loader'
import { Tooltip } from 'primereact/tooltip'
import { Helmet } from 'react-helmet-async'
import { Button } from 'primereact/button'
import { dayWorker } from 'src/utils'
import classNames from 'classnames'
import { map, size } from 'lodash'

export const AttendanceStatistic: FC = (): ReactElement => {
    const { id } = useParams()
    
    const { isLoading, data } = useGetStatisticsQuery({
        userId: Number(id),
    })
    
    return (
        <StudentLayout>
            <Helmet title={'Посещаемость'} />
            <Card
                headerClassName={'mt-24'}
                header={'Посещаемость'}
                contentClassName={'p-24'}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <div
                            className={classNames(styles.classWrapper, 'mt-3')}
                        >
                            {size(data?.lessons)
                             ? map(
                                    data?.lessons,
                                    (attendanceLesson: AttendanceLessonInformation): ReactElement => (
                                        <TooltipLesson
                                            key={attendanceLesson.id}
                                            attendanceLesson={attendanceLesson}
                                        />
                                    ),
                                )
                             : 'Уроки отсутствуют'}
                        </div>
                        <div className={'mt-3'}>
                            <ColorIndicator text={'Студент был'} />
                            <ColorIndicator danger text={'Студента не было'} />
                            <ColorIndicator info text={'Студент опоздал'} />
                        </div>
                    </div>
                )}
            </Card>
        </StudentLayout>
    )
}

export type AttendanceLessonTooltipProps = {
    attendanceLesson: AttendanceLessonInformation;
};

export enum TooltipPosition {
    Top = 'top',
    Bottom = 'bottom',
    Left = 'left',
    Right = 'right',
    Mouse = 'mouse'
}

const TooltipLesson: FC<AttendanceLessonTooltipProps> = (props: AttendanceLessonTooltipProps): ReactElement => {
    const { lesson } = props.attendanceLesson
    const { group, subject, teacher } = lesson
    
    const lessonHtmlId: string = `lesson-${lesson.id}`
    
    const lessonStartDate: string = dayWorker(lesson.startedAt)
        .format(dateFormatWithTime)
    
    const attendancePathname: string =
        EDRNavigation[EducationDepartmentPaths.AttendancePage]()
    
    const searchParams: URLSearchParams = new URLSearchParams({
        groupId: '' + group.id,
        subjectId: '' + subject.id,
        lessonId: '' + lesson.id,
    })
    
    return (
        <>
            <Tooltip
                position={TooltipPosition.Top}
                target={`#${lessonHtmlId}`}
                autoHide={false}
                event="focus"
                className={styles.lessonTooltip}
            >
                <div className={'flex flex-column gap-2'}>
                    <div>Группа: {group.name}</div>
                    <div>Предмет: {subject.name}</div>
                    <div>Время: {lessonStartDate}</div>
                    <div>Преподаватель: {teacher.fio}</div>
                    <Link
                        style={{ display: 'block' }}
                        to={{
                            pathname: attendancePathname,
                            search: searchParams.toString(),
                        }}
                    >
                        <Button
                            className={'flex w-full justify-content-center'}
                        >
                            Открыть
                        </Button>
                    </Link>
                </div>
            </Tooltip>
            <Lesson
                id={lessonHtmlId}
                status={props.attendanceLesson.status.id}
                children={props.attendanceLesson.index}
            />
        </>
    )
}
