import { NotificationTypes, _notify } from 'src/utils/notify'
import { SortingData, useCreateGroupMutation } from 'src/api'
import { AddModalContent } from './lib/AddModalContent';
import { MultiSelect } from 'primereact/multiselect';
import { SelectItem } from 'primereact/selectitem';
import { Dropdown } from 'src/components/Dropdown';
import { Modal } from 'src/components/Modal/Modal';
import styles from './GroupActionTab.module.scss';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useDebounce } from 'src/hooks'
import { Filters } from './lib/types';
import classNames from 'classnames';
import {
    Dispatch,
    FC,
    ReactElement,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react'

export type GroupCreationForm = {
    name?: string;
    capacity?: number;
    year?: number;
    expectedLessons?: number;
};

export type GroupActionTabProps = {
    isFetching: boolean;
    filterData: SortingData;
    filters: Filters;
    setFilters: Dispatch<SetStateAction<Filters>>;
};

export const GroupActionTab: FC<GroupActionTabProps> = (
    props: GroupActionTabProps
): ReactElement => {
    const { isFetching, filterData, filters, setFilters } = props;

    const toast = useRef<Toast>(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [add, { isLoading: isAddLoading }] = useCreateGroupMutation()

    const [sortingOptions, setSortingOptions] = useState<SelectItem[]>([]);

    const [init, setInit] = useState(false);
    const [search, debouncedSearch, setSearch] = useDebounce(
        filters.search,
        400
    );
    
    const createGroup = (data: GroupCreationForm) => {
        if (!data.name || !data.capacity || !data.year || !data.expectedLessons)
            return Promise.reject();

        return add({
            name: data.name,
            capacity: data.capacity,
            year: data.year,
            expectedLessons: data.expectedLessons,
        })
            .unwrap()
            .then(() => {
                _notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Вы успешно создали группу!',
                    },
                    true
                );
                setShowAddModal(false);
            })
            .catch(e => {
                _notify(toast, {
                    type: NotificationTypes.error,
                    content: e.data,
                });
            });
    };

    useEffect(() => {
        if (filterData.sorting.length && !init) {
            setFilters(prev => ({
                ...prev,
                sort: filterData.sorting.find(el => el.default)?.key,
                statuses: filterData.statuses
                    .filter(el =>
                        ['Заморожена', 'Действующая'].includes(el.name)
                    )
                    .map(el => el.id),
            }));
            setInit(true);
        }
    }, [filterData]);

    useEffect((): void => {
        const sortingList = [...filterData.sorting];
        sortingList.reverse();

        setSortingOptions(
            sortingList.map(
                (sortingElement): SelectItem => ({
                    label: sortingElement.label,
                    value: sortingElement.key,
                })
            )
        );
    }, [filterData.sorting]);

    useEffect(() => {
        setFilters(prev => ({ ...prev, search: debouncedSearch }));
    }, [debouncedSearch]);

    return (
        <>
            <div className={styles.filter}>
                <div className="flex flex-row justify-content-between flex-wrap">
                    <Button
                        onClick={() => setShowAddModal(true)}
                        className="mr-3 mt-2"
                    >
                        Добавить
                    </Button>
                    <MultiSelect
                        filter
                        placeholder="Выберите год запуска"
                        className={classNames('mr-2 mt-2', {
                            [styles.loading]: isFetching,
                        })}
                        value={filters.years}
                        options={filterData.years.map(year => ({
                            label: year.toString(),
                            value: year,
                        }))}
                        onChange={event =>
                            setFilters(prev => ({
                                ...prev,
                                years: event.value,
                            }))
                        }
                    />
                    <MultiSelect
                        filter
                        placeholder="Выберите статус"
                        className={classNames('mt-2', {
                            [styles.loading]: isFetching,
                        })}
                        value={filters.statuses}
                        options={filterData.statuses.map(status => ({
                            label: status.name,
                            value: status.id,
                        }))}
                        onChange={event =>
                            setFilters(prev => ({
                                ...prev,
                                statuses: event.value,
                            }))
                        }
                    />
                </div>
                <div className="flex flex-row justify-content-between flex-wrap">
                    <Dropdown
                        loading={isFetching}
                        filter
                        placeholder="Сортировать по"
                        className="mt-2 mr-2"
                        value={filters.sort}
                        options={sortingOptions}
                        onChange={event =>
                            setFilters(prev => ({
                                ...prev,
                                sort: event.value,
                            }))
                        }
                    />
                    <span className="p-input-icon-right mt-2">
                        <i
                            className={classNames('pi', {
                                'pi-spin pi-spinner': isFetching,
                                'pi-search': !isFetching,
                            })}
                        />
                        <InputText
                            placeholder="Поиск"
                            value={search}
                            className={classNames(styles.search, {
                                [styles.loading]: isFetching,
                            })}
                            onChange={event => setSearch(event.target.value)}
                        />
                    </span>
                </div>
            </div>
            <Toast ref={toast} />
            <Modal
                header={'Создание Группы'}
                visible={showAddModal}
                onHide={() => setShowAddModal(false)}
            >
                <AddModalContent onSubmit={createGroup} isLoading={isAddLoading} />
            </Modal>
        </>
    );
};
