import { lessonAttendanceTransformer } from 'src/api/endpoints/attendance/attendance.functions'
import { StudentAttendance } from 'src/@types/modules/attendance/attendance.types'
import { HttpMethod } from 'src/@types/modules/api.types'
import { baseApi, QueryPayload } from 'src/api'
import {
    FinishLessonMarkingRequestPayload,
    FinishLessonMarkingResponse,
    GetAttendanceMarkingDataRequestPayload,
    GetAttendanceMarkingDataResponse,
    GetAvailableGroupsWithAttendanceRequestPayload,
    GetAvailableGroupsWithAttendanceResponse,
    GetGroupAttendanceAvailableSubjectsRequestPayload,
    GetGroupAttendanceAvailableSubjectsResponse,
    GetGroupAttendanceBySubjectRequestPayload,
    GetGroupAttendanceBySubjectResponse,
    GetLessonAttendanceRequestPayload,
    GetLessonAttendanceResponse,
    UpdateStudentAttendanceRequestPayload,
    UpdateStudentAttendanceResponse,
} from 'src/api/endpoints/attendance/attendance.types'

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        // Окончание отметки урока (кнопка завершить отметку) [Преподаватель]
        finishLessonMarking: builder.mutation<
            FinishLessonMarkingResponse,
            FinishLessonMarkingRequestPayload
        >({
            invalidatesTags: (
                _response: FinishLessonMarkingResponse | undefined,
                _error: unknown,
                payload: FinishLessonMarkingRequestPayload,
            ) => [{ type: 'LessonAttendance', id: payload.path.lessonId }],
            query: (payload: FinishLessonMarkingRequestPayload): QueryPayload => ({
                url: `lessons/${payload.path.lessonId}/attendance/marking/finish`,
                method: HttpMethod.Post,
                errorMessage: 'Не удалось завершить отметку урока.',
            }),
        }),
        
        // Обновление информации о посещаемости студента [Преподаватель + Учебный отдел]
        updateStudentAttendance: builder.mutation<
            UpdateStudentAttendanceResponse,
            UpdateStudentAttendanceRequestPayload
        >({
            invalidatesTags: (result?: StudentAttendance) =>
                result
                ? [{ type: 'StudentAttendance' as const, id: result.id }]
                : ['StudentAttendance'],
            query: (payload: UpdateStudentAttendanceRequestPayload): QueryPayload => ({
                url: `lessons/${payload.path.lessonId}/attendance/${payload.path.attendanceId}`,
                method: HttpMethod.Patch,
                data: payload.body,
                errorMessage: 'Не удалось обновить информацию о посещаемости студента',
            }),
        }),
        
        // Получение информация о посещаемости (таблица посещаемости) по уроку [Преподаватель + Учебный отдел]
        getStudentsAttendanceByLesson: builder.query<
            GetLessonAttendanceResponse,
            GetLessonAttendanceRequestPayload
        >({
            providesTags: (
                result: StudentAttendance[] | undefined,
                _error: unknown,
                payload: GetLessonAttendanceRequestPayload,
            ) =>
                [
                    { type: 'LessonAttendance', id: payload.path.lessonId },
                    ...(result
                        ? [...result.map(
                            (attendance: StudentAttendance) =>
                                ({ type: 'StudentAttendance' as const, id: attendance.id }),
                        )]
                        : []),
                ],
            query: (payload: GetLessonAttendanceRequestPayload): QueryPayload => ({
                url: `lessons/${payload.path.lessonId}/attendance`,
                method: HttpMethod.Get,
                errorMessage: 'Не удалось получить информацию о таблице посещаемости',
            }),
        }),
        
        getAvailableGroupsWithAttendance: builder.query<
            GetAvailableGroupsWithAttendanceResponse,
            GetAvailableGroupsWithAttendanceRequestPayload
        >({
            query: (): QueryPayload => ({
                url: 'attendance/groups',
                method: HttpMethod.Get,
                errorMessage: 'Не удалось получить список группы для просмотра посещаемости',
            }),
        }),
        
        // Получение информации о доступных предметах группы для просмотра посещаемости [Учебный отдел]
        getAvailableGroupSubjectsWithAttendance: builder.query<
            GetGroupAttendanceAvailableSubjectsResponse,
            GetGroupAttendanceAvailableSubjectsRequestPayload
        >({
            query: (payload: GetGroupAttendanceBySubjectRequestPayload): QueryPayload => ({
                url: `groups/${payload.path.groupId}/attendance/subjects`,
                method: HttpMethod.Get,
                errorMessage: 'Не удалось получить информацию об урока предмета группы',
            }),
        }),
        
        // Получение информации о доступных уроках предмета группы для просмотра посещаемости [Учебный отдел]
        getGroupAttendanceLessonsBySubject: builder.query<
            GetGroupAttendanceBySubjectResponse,
            GetGroupAttendanceBySubjectRequestPayload
        >({
            query: (payload: GetGroupAttendanceBySubjectRequestPayload): QueryPayload => ({
                url: `groups/${payload.path.groupId}/attendance/subjects/${payload.path.subjectId}`,
                method: HttpMethod.Get,
                errorMessage: 'Не удалось получить информацию об уроках предмета группы',
            }),
            transformResponse: lessonAttendanceTransformer,
        }),
        
        // Получение данных для отметки посещаемости
        getDataForAttendanceMarking: builder.query<
            GetAttendanceMarkingDataResponse,
            GetAttendanceMarkingDataRequestPayload
        >({
            query: (): QueryPayload => ({
                url: '/attendance/_marking/data',
                method: HttpMethod.Get,
                errorMessage: 'Не удалось получить данные для отметки посещаемости',
            }),
        }),
    }),
})

export const {
    useFinishLessonMarkingMutation,
    useUpdateStudentAttendanceMutation,
    useGetStudentsAttendanceByLessonQuery,
    useGetAvailableGroupsWithAttendanceQuery,
    useGetAvailableGroupSubjectsWithAttendanceQuery,
    useGetGroupAttendanceLessonsBySubjectQuery,
    useGetDataForAttendanceMarkingQuery,
} = api
