import { EducationDepartmentPaths, EducationDepartmentRoutesNavigation } from 'src/routes/roles/EducationDepartment'
import styles from 'src/pages/edu/GroupPage/components/DetailGroupStudentTable/DetailGroupStudentTable.module.scss'
import { AvatarWithDisplayValueColumn, DataTable } from 'src/components/DataTable'
import { getUserInitials } from 'src/utils/getFullname'
import { Student } from 'src/api/endpoints/students'
import { Column } from 'primereact/column'
import { FC, ReactElement } from 'react'
import classNames from 'classnames'
import { Group } from 'src/api'

interface GroupPageStudentsTableProps {
    group: Group;
    students: Student[];
    isFetching: boolean;
}

export const DetailGroupStudentTable: FC<GroupPageStudentsTableProps> = (props: GroupPageStudentsTableProps): ReactElement => {
    const { group, students, isFetching } = props
    
    return (
        <>
            <div className="new-area detail-group-student-table">
                {students.length === 0 ? (
                                           <div className={'select-none'}>
                                               Студенты отсутствуют
                                           </div>) :
                 (<>
                     <DataTable
                         className={classNames(styles.groupStudentsDatatable)}
                         scrollable={true}
                         scrollHeight="470px"
                         paginator={false}
                         stripedRows={true}
                         loading={isFetching}
                         value={students}
                         dataKey={'id'}
                         rowClassName={(): string => classNames(styles.groupStudentsDatatableRow)}
                     >
                         <Column
                             headerClassName={classNames(styles.tableHeader)}
                             header="ФИО"
                             body={(rowData: Student): ReactElement => (
                                 <AvatarWithDisplayValueColumn
                                     data={{
                                         avatar: rowData.avatarURL,
                                         displayValue: getUserInitials(rowData),
                                     }}
                                     displayValueStyles={{
                                         fontSize: '12px',
                                     }}
                                     avatarStyles={{
                                         size: '30px',
                                     }}
                                     link={EducationDepartmentRoutesNavigation[EducationDepartmentPaths.StudentPage](rowData.id)}
                                 />
                             )}
                         />
                         <Column
                             headerClassName={classNames(styles.tableHeader, styles.statusHeader)}
                             sortable field={'status.name'} header="Статус"
                             body={(data: any): ReactElement => (<div style={{
                                 fontSize: '12px',
                             }}>{data.status.name}</div>)}
                         />
                     </DataTable>
                     <div className={'capacity'}
                          style={{
                              color: '#C1C9D1',
                              fontSize: '16px',
                              padding: '10px',
                              width: 'auto',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                          }}
                     >
                         <div className="capacity-title">
                             Количество студентов:
                         </div>
                         <div className="capacity-value">
                                     <span className={'capacity-value-accent'} style={{
                                         color: '#495057',
                                     }}
                                     >{group.details.capacity.occupied}</span>
                             /{group.details.capacity.total}
                         </div>
                     </div>
                 </>)
                }
            </div>
        </>
    )
}
