import style from './DetailGroupStatistic.module.scss'
import { FC, ReactElement } from 'react'
import classNames from 'classnames'
import { Group } from 'src/api'

export type GroupStatisticProps = {
    group: Group;
}

export const DetailGroupStatistic: FC<GroupStatisticProps> = (props: GroupStatisticProps): ReactElement => {
    const { group } = props
    
    return (
        <div className="new-area">
            <div className="flex flex-row justify-content-between flex-wrap">
                <div
                    className={'flex flex-column justify-content-center items-center new-text-prime new-heading font-light'}>
                    Общее состояние группы
                </div>
                <div>
                    <span className={classNames('new-text-blue', style.occupied)}>
                        {group.details.morale}
                    </span>
                    /100
                </div>
            </div>
        </div>
    )
}