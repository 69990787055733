import { Nullable } from 'primereact/ts-helpers'
import { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from '../../Avatar'

export type DisplayValueStyles = {
    fontSize?: string;
}

export type AvatarStyles = {
    size?: string;
}

export type AvatarWithDisplayValueColumnProps = {
    data: {
        avatar: Nullable<string>;
        displayValue: string;
    }
    avatarStyles?: AvatarStyles;
    displayValueStyles?: DisplayValueStyles;
    link: Nullable<string>;
}

export const AvatarWithDisplayValueColumn: FC<AvatarWithDisplayValueColumnProps> =
    (props: AvatarWithDisplayValueColumnProps): ReactElement => {
        const { data, link, displayValueStyles, avatarStyles } = props
        
        const avatarSize: string = avatarStyles?.size || '56px'
        
        return (
            <div className={'flex align-items-center'} style={{ gap: 10 }}>
                <Avatar
                    src={data.avatar}
                    style={{
                        width: avatarSize,
                        height: avatarSize,
                        aspectRatio: 1,
                    }}
                />
                {link
                 ? (
                     <Link
                         to={link}
                         style={displayValueStyles}
                     >{data.displayValue}</Link>
                 )
                 : (
                     <span
                         className="image-text"
                         style={displayValueStyles}
                     >{data.displayValue}</span>
                 )}
            </div>
        )
    }
