import { Group, useUpdateGroupMutation, useDisbandGroupMutation, useUnfreezeGroupMutation } from 'src/api'
import { EducationDepartmentPaths } from 'src/routes/roles/EducationDepartment'
import { EDRNavigation } from 'src/routes/roles/EducationDepartment/navigation'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { FreezeModal, MergeModal, ReleaseModal } from './lib'
import { NotificationTypes, _notify } from 'src/utils/notify'
import { FC, ReactElement, useRef, useState } from 'react'
import { confirmDialog } from 'primereact/confirmdialog'
import { ProgressBar } from 'primereact/progressbar'
import { MenuItem } from 'primereact/menuitem'
import styles from './GroupCard.module.scss'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { useDebounce } from 'src/hooks'
import { Menu } from 'primereact/menu'
import {
    AddModalContent,
    GroupModalSubmitValues,
} from 'src/pages/edu/Groups/components/GroupActionTab/lib/AddModalContent'
import { Modal } from 'src/components/Modal/Modal'

export type GroupCardProps = Group;

export const GroupCard: FC<GroupCardProps> = (props: GroupCardProps): ReactElement => {
    const { id, name, status, details, meta } = props
    
    const navigate: NavigateFunction = useNavigate()
    
    const toast = useRef<Toast>(null)
    
    const [isUpdatingFormOpen, setIsUpdatingModalOpen] = useState<boolean>(false)
    
    const [updateGroup, { isLoading: isGroupUpdating }] = useUpdateGroupMutation()
    
    const [freezeModalVisible, setFreezeModalVisible] = useState(false)
    const [releaseModalVisible, setReleaseModalVisible] = useState(false)
    
    const [
        mergeModalVisible,
        debouncedMergeModalVisible,
        setMergeModalVisible,
    ] = useDebounce(false, 100)
    
    const menuRef = useRef<Menu>(null)
    
    const [disbandGroup] = useDisbandGroupMutation()
    const [unfreezeGroup] = useUnfreezeGroupMutation()
    
    const disbandGroupAction = () =>
        confirmDialog({
            message: `Вы действительно хотите расформировать группу ${name}?`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () =>
                disbandGroup({ id })
                    .unwrap()
                    .then((): void => {
                        if (!toast.current) return
                        
                        _notify(
                            toast.current,
                            {
                                type: NotificationTypes.success,
                                content: 'Вы успешно расформировали группу!',
                            },
                            true,
                        )
                    })
                    .catch(e => {
                        if (!toast.current) return
                        
                        _notify(toast.current, {
                            type: NotificationTypes.error,
                            content: e.data,
                        })
                    }),
        })
    
    const unfreezeGroupAction = () =>
        confirmDialog({
            message: `Вы действительно хотите разморозить группу ${name}?`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () =>
                unfreezeGroup({ id })
                    .unwrap()
                    .then((): void => {
                        if (!toast.current) return
                        
                        _notify(
                            toast.current,
                            {
                                type: NotificationTypes.success,
                                content: 'Вы успешно разморозили группу!',
                            },
                            true,
                        )
                    })
                    .catch(e => {
                        if (!toast.current) return
                        
                        _notify(toast.current, {
                            type: NotificationTypes.error,
                            content: e.data,
                        })
                    }),
        })
    
    const navigateToGroup: VoidFunction = (): void =>
        navigate(EDRNavigation[EducationDepartmentPaths.GroupPage](id))
    
    const BUTTON_ACTIONS: MenuItem[] = [
        {
            label: 'Перейти',
            icon: 'pi pi-external-link',
            command: navigateToGroup,
        },
        {
            label: 'Изменить',
            icon: 'pi pi-pencil',
            command: () => setIsUpdatingModalOpen(true),
        },
        ...(meta.actions.canBeFrozen
            ? [
                {
                    label: 'Заморозить',
                    icon: 'pi pi-ban',
                    command: () => setFreezeModalVisible(true),
                },
            ]
            : []),
        ...(meta.actions.canBeUnfrozen
            ? [
                {
                    label: 'Разморозить',
                    icon: 'pi pi-history',
                    command: unfreezeGroupAction,
                },
            ]
            : []),
        ...(meta.actions.canBeMerged
            ? [
                {
                    label: 'Объединить',
                    icon: 'pi pi-arrow-right-arrow-left',
                    command: () => setMergeModalVisible(true),
                },
            ]
            : []),
        ...(meta.actions.canBeReleased
            ? [
                {
                    label: 'Выпустить',
                    icon: 'pi pi-flag',
                    command: () => setReleaseModalVisible(true),
                },
            ]
            : []),
        ...(meta.actions.canBeDisband
            ? [
                {
                    label: 'Расформировать',
                    icon: 'pi pi-trash',
                    command: disbandGroupAction,
                },
            ]
            : []),
    ]
    
    const FOOTER = [
        {
            secondary: 'Статус',
            prime: status.name,
        },
        {
            secondary: 'Студентов',
            prime: `${details.capacity.occupied}/${details.capacity.total}`,
        },
        {
            secondary: 'Год запуска',
            prime: details.launchYear,
        },
    ]
    
    const onModalSubmit = (values: GroupModalSubmitValues): Promise<unknown> => {
        return updateGroup({
            path: {
                groupId: id,
            },
            body: values,
        })
            .unwrap()
            .then(() => {
                _notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Информация о группе успешно обновлена!',
                    },
                    true,
                )
                setIsUpdatingModalOpen(false)
            })
            .catch(e => {
                _notify(toast, {
                    type: NotificationTypes.error,
                    content: e.data,
                })
            })
    }
    
    return (
        <>
            <div
                className={'col-12 md:col-6 lg:col-6 xl:col-4 select-none'}
                style={{ boxSizing: 'border-box' }}
            >
                <div className={'new-area cursor-pointer'} onDoubleClick={navigateToGroup}>
                    <div className={styles.header}>
                        <div>
                            <div className="new-text-secondary">Группа</div>
                            <div className="new-text-prime new-heading">
                                {name}
                            </div>
                        </div>
                        <Menu
                            model={BUTTON_ACTIONS}
                            popup
                            ref={menuRef}
                            id="popup_menu_left"
                        />
                        <Button
                            label="Действия"
                            icon="pi pi-chevron-down"
                            iconPos="right"
                            onClick={event => {
                                if (menuRef.current) {
                                    menuRef.current.toggle(event)
                                }
                            }}
                        />
                    </div>
                    <div className={styles.main}>
                        <div className="new-text-secondary">
                            Общее состояние группы
                        </div>
                        <ProgressBar
                            className={styles.progress}
                            value={details.morale}
                            showValue={false}
                        />
                    </div>
                    <div className={styles.footer}>
                        {FOOTER.map(content => (
                            <div
                                key={content.secondary}
                                className={styles.statistic}
                            >
                                <div className="new-text-secondary">
                                    {content.secondary}
                                </div>
                                <div className="new-text-prime">
                                    {content.prime}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Toast ref={toast} />
            <FreezeModal
                visible={freezeModalVisible}
                onHide={() => setFreezeModalVisible(false)}
                groupName={name}
                groupId={id}
            />
            <ReleaseModal
                visible={releaseModalVisible}
                onHide={() => setReleaseModalVisible(false)}
                groupName={name}
                groupId={id}
            />
            {debouncedMergeModalVisible ? (
                <MergeModal
                    visible={mergeModalVisible}
                    onHide={() => setMergeModalVisible(false)}
                    groupName={name}
                    groupId={id}
                />
            ) : null}
            <Modal
                header={'Изменение группы'}
                visible={isUpdatingFormOpen}
                onHide={() => setIsUpdatingModalOpen(false)}
            >
                <AddModalContent
                    group={props}
                    onSubmit={onModalSubmit}
                    isLoading={isGroupUpdating}
                />
            </Modal>
        </>
    )
}
