import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { FC, useRef } from 'react';
import { useExtendAcademicVacationMutation } from 'src/api/endpoints/academic';
import { requireField } from 'src/constants/constants';
import { NotificationTypes, _notify } from 'src/utils/notify'
import { Modal } from '../Modal/Modal';
import { ModalHeader } from 'src/pages/edu/Groups/components/ModalHeader';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Calendar } from '../Calendar';
import { InputTextarea } from 'primereact/inputtextarea';

interface Props {
    studentName: string;
    academicId: string;
    visible: boolean;
    onHide: () => void;
}

interface Form {
    extendedTo?: Date;
    reason?: string;
}

export const ContinueAcademicVacationModal: FC<Props> = ({
    studentName,
    academicId,
    visible,
    onHide,
}) => {
    const [extend, { isLoading: isExtending }] =
        useExtendAcademicVacationMutation();

    const toast = useRef<Toast>(null);
    const formik = useFormik<Form>({
        initialValues: {},
        validate: data => {
            const errors: Partial<Record<keyof Form, string>> = {};

            if (!data.extendedTo) errors.extendedTo = requireField;
            if (!data.reason?.trim()) errors.reason = requireField;

            return errors;
        },
        onSubmit(data) {
            if (!data.extendedTo) return;
            if (!data.reason) return;

            extend({
                academicLeaveId: academicId,
                extendedTo: data.extendedTo?.getTime(),
                reason: data.reason,
            })
                .unwrap()
                .then(() => {
                    if (!toast.current) return;
                    
                    _notify(
                        toast.current,
                        {
                            type: NotificationTypes.success,
                            content:
                                'Вы успешно продлили студенту академ. отпуск!',
                        },
                        true
                    );
                    onHide();
                    formik.resetForm();
                })
                .catch(error => {
                    if (!toast.current) return;
                    
                    _notify(
                        toast.current,
                        {
                            type: NotificationTypes.error,
                            content: error.data,
                        },
                        true
                    );
                });
        },
    });

    const isFormFieldValid = (name: keyof Form) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: keyof Form) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    return (
        <>
            <Modal
                visible={visible}
                header={
                    <ModalHeader>
                        Продление академического отпуска для студента{' '}
                        <span>{studentName}</span>
                    </ModalHeader>
                }
                onHide={onHide}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="field w-full">
                        <span className="p-float-label">
                            <Calendar
                                id="extendedTo"
                                name="extendedTo"
                                value={formik.values.extendedTo}
                                onChange={formik.handleChange}
                                className={classNames('w-full', {
                                    'p-invalid': isFormFieldValid('extendedTo'),
                                })}
                            />
                            <label
                                htmlFor="extendedTo"
                                className={classNames({
                                    'p-error': isFormFieldValid('extendedTo'),
                                })}
                            >
                                Продлить до
                            </label>
                        </span>
                        {getFormErrorMessage('extendedTo')}
                    </div>
                    <div className="field w-full">
                        <span className="p-float-label">
                            <InputTextarea
                                id="reason"
                                name="reason"
                                value={formik.values.reason}
                                onChange={formik.handleChange}
                                className={classNames('w-full', {
                                    'p-invalid': isFormFieldValid('reason'),
                                })}
                            />
                            <label
                                htmlFor="reason"
                                className={classNames({
                                    'p-error': isFormFieldValid('reason'),
                                })}
                            >
                                Причина заморозки
                            </label>
                        </span>
                        {getFormErrorMessage('reason')}
                    </div>
                    <Button
                        type="submit"
                        loading={isExtending}
                        className="mt-24"
                    >
                        Подтверждаю
                    </Button>
                </form>
            </Modal>
            <Toast ref={toast} />
        </>
    );
};
