import { AttendanceStatus, LessonType, StudentAttendance } from 'src/@types/modules/attendance/attendance.types'
import { StudentAttendanceStatus } from 'src/@types/modules/attendance/attendance.enums'
import { getMarkClassname, isDisabled, marks, Statuses } from '../utils/utils'
import { FC, ReactElement, useEffect, useState } from 'react'
import { DropdownChangeEvent } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'src/components/Dropdown'
import { SelectItem } from 'primereact/selectitem'
import styles from '../AttendantPage.module.scss'
import { DataTable } from 'primereact/datatable'
import { classNames } from 'primereact/utils'
import tableStyles from './Table.module.scss'
import { Column } from 'primereact/column'
import { useDebounce } from 'src/hooks'
import { Rating } from './Rating'
import { map, size } from 'lodash'

interface AttendanceTableProps {
    lessonType?: LessonType;
    attendance: StudentAttendance[];
    disabled?: boolean;
    statusLoading: boolean;
    //
    statusOptions: SelectItem<AttendanceStatus>[];
    onStatusSelected?: (status: AttendanceStatus, attendance: StudentAttendance) => void;
    //
    onMarkSelected?: (mark: number, attendance: StudentAttendance) => void;
    onRewardSelected?: (reward: number, attendance: StudentAttendance) => void;
    onCommentChange?: (value: string | null, attendance: StudentAttendance) => void;
    //
    onAttendanceChange?: (attendance: StudentAttendance) => void;
}

type CommentInputProps = {
    disabled?: boolean;
    lessonType?: LessonType;
    attendance: StudentAttendance;
    onCommentChange: (value: string, attendance: StudentAttendance) => void;
};

const CommentInput: FC<CommentInputProps> =
    (props: CommentInputProps): ReactElement => {
        const {
            attendance,
            disabled,
            lessonType,
            onCommentChange,
        } = props
        
        const [inputValue, debouncedValue, setInputValue] = useDebounce(
            attendance.comment ?? '',
            400,
        )
        
        const [wasInit, setWasInit] = useState(false)
        
        useEffect((): void => {
            if (wasInit && debouncedValue !== attendance.comment) {
                onCommentChange(debouncedValue, attendance)
            }
            
            setWasInit(true)
        }, [debouncedValue])
        
        useEffect((): void => {
            if (attendance.status.id !== StudentAttendanceStatus.Absent) return
            
            setInputValue('')
        }, [attendance])
        
        return (
            <InputTextarea
                className={classNames('w-full', {
                    [tableStyles.disabledField]:
                    disabled || isDisabled(attendance, lessonType),
                })}
                value={inputValue}
                disabled={disabled || isDisabled(attendance, lessonType)}
                onChange={event => setInputValue(event.target.value)}
            />
        )
    }

export const AttendanceTable: FC<AttendanceTableProps> =
    (props: AttendanceTableProps): ReactElement => {
        const {
            disabled,
            attendance,
            statusOptions,
            statusLoading,
            lessonType,
        } = props
        
        const updateAttendance =
            <Field extends keyof StudentAttendance, >
            (
                attendance: StudentAttendance,
                field: Field,
                value: StudentAttendance[Field],
            ): StudentAttendance => {
                const updatedAttendance: StudentAttendance = {
                    ...attendance,
                    [field]: value,
                }
                
                if (props.onAttendanceChange)
                    props.onAttendanceChange(updatedAttendance)
                
                return updatedAttendance
            }
        
        const onStatusSelected = (
            event: DropdownChangeEvent,
            attendance: StudentAttendance,
        ): void => {
            const updatedAttendance: StudentAttendance =
                updateAttendance(attendance, 'status', event.value)
            
            if (!props.onStatusSelected) return
            
            return props.onStatusSelected(
                event.value,
                updatedAttendance,
            )
        }
        
        const onMarkSelected = (
            event: DropdownChangeEvent,
            attendance: StudentAttendance,
        ): void => {
            const updatedAttendance: StudentAttendance =
                updateAttendance(attendance, 'mark', event.value)
            
            
            if (!props.onMarkSelected) return
            
            return props.onMarkSelected(
                event.value,
                updatedAttendance,
            )
        }
        
        const onRewardSelected = (
            reward: number,
            attendance: StudentAttendance,
        ): void => {
            const updatedAttendance: StudentAttendance =
                updateAttendance(attendance, 'reward', reward)
            
            if (!props.onRewardSelected) return
            
            return props.onRewardSelected(
                reward,
                updatedAttendance,
            )
        }
        
        const onCommentChange = (
            comment: string,
            attendance: StudentAttendance,
        ): void => {
            const formattedComment: string | null = size(comment.trim()) ? comment : null
            
            const updatedAttendance: StudentAttendance =
                updateAttendance(attendance, 'comment', formattedComment)
            
            
            if (!props.onCommentChange) return
            
            return props.onCommentChange(
                formattedComment,
                updatedAttendance,
            )
        }
        
        return (
            <DataTable
                className={styles.tableWrapper}
                tableClassName={styles.table}
                value={attendance}
            >
                <Column field={'fullname'} header={'Студент'} align={'left'} />
                <Column
                    field={'status'}
                    header={'Статус'}
                    align={'left'}
                    style={{ minWidth: 204 }}
                    body={(attendance: StudentAttendance): ReactElement => {
                        return (
                            <Dropdown
                                disabled={disabled || !lessonType}
                                loading={statusLoading}
                                value={attendance.status}
                                className={classNames(
                                    'w-full',
                                    Statuses[attendance.status.id]
                                        ?.className,
                                    {
                                        [tableStyles.disabledField]:
                                        disabled || !lessonType,
                                    },
                                )}
                                onChange={(event: DropdownChangeEvent): void => onStatusSelected(event, attendance)}
                                options={statusOptions}
                            />
                        )
                    }}
                />
                <Column
                    header={'Работа в классе'}
                    style={{ minWidth: 230 }}
                    align={'left'}
                    body={(attendance: StudentAttendance): ReactElement => {
                        return (
                            <Dropdown
                                value={attendance.mark}
                                className={classNames(
                                    'w-full',
                                    getMarkClassname(attendance.mark),
                                    {
                                        [tableStyles.disabledField]:
                                        disabled ||
                                        isDisabled(attendance, lessonType),
                                    },
                                )}
                                disabled={
                                    disabled || isDisabled(attendance, lessonType)
                                }
                                onChange={event => onMarkSelected(event, attendance)}
                                options={map(marks, (mark: string, index: number) => ({
                                    value: index,
                                    label: mark,
                                    className: getMarkClassname(index),
                                }))}
                            />
                        )
                    }}
                />
                <Column
                    field={'reward'}
                    header={'Награда'}
                    style={{ width: 142 }}
                    align={'left'}
                    body={(attendance: StudentAttendance): ReactElement => {
                        return (
                            <Rating
                                className={classNames({
                                    [tableStyles.disabledField]:
                                    disabled || isDisabled(attendance, lessonType),
                                })}
                                disabled={
                                    disabled || isDisabled(attendance, lessonType)
                                }
                                value={attendance.reward}
                                onChange={(reward: number): void => onRewardSelected(reward, attendance)}
                            />
                        )
                    }}
                />
                <Column
                    field={'comment'}
                    header={'Комментарий'}
                    align={'left'}
                    body={(attendance: StudentAttendance): ReactElement => (
                        <CommentInput
                            key={attendance.id}
                            disabled={disabled}
                            attendance={attendance}
                            lessonType={lessonType}
                            onCommentChange={onCommentChange}
                        />
                    )}
                />
            </DataTable>
        )
    }
