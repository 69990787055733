import { ProgressSpinner } from 'primereact/progressspinner'
import { FC, ReactElement } from 'react'
import './Loader.scss'

export const Loader: FC = (): ReactElement => {
  return (
    <div className='w-full flex align-items-center justify-content-center loader-container'>
      <ProgressSpinner animationDuration='1s' className='loader-item' />
    </div>
  )
}
