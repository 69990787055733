import style from 'src/components/NearestLesson/NearestLesson.module.scss'
import { Lesson } from 'src/api/endpoints/lessons/lessons.types'
import { dayWorker } from 'src/utils/dateWorker'
import { classNames } from 'primereact/utils'
import { FC } from 'react'

export type TeacherLessonCardProps = {
    lesson: Lesson;
    isSelected: boolean;
    onClick: (lesson: Lesson) => void;
    className?: any;
}

function getTime(date: Date) {
    return dayWorker(date).format('HH:mm')
}

function getMonthWithDay(date: Date) {
    return dayWorker(date).format('DD MMMM')
}

export const TeacherLessonCard: FC<TeacherLessonCardProps> = (props: TeacherLessonCardProps) => {
    const { lesson, isSelected, onClick: onLessonClick, className } = props
    
    const onClick = () => {
        if (!lesson.active) return
        onLessonClick(lesson)
    }
    
    return (
        <div
            className={classNames(className, style.lesson, {
                [style.activeLesson]: isSelected,
                [style.disabled]: !lesson.active,
            })}
            onClick={onClick}
        >
            <div className={style.dates}>
                <div className={'bold'}>{getMonthWithDay(lesson.startsAt)}</div>
                <div className={style.delimiter}></div>
                <div className={'bold'}>
                    {getTime(lesson.startsAt)}-{getTime(lesson.endsAt)}
                </div>
            </div>
            <div className={style.row}>
                <div className={style.rowTitle}>Предмет</div>
                <div>{lesson.subject.name}</div>
            </div>
            <div className={style.row}>
                <div className={style.rowTitle}>Группа</div>
                <div>{lesson.group.name}</div>
            </div>
            <div className={style.row}>
                <div className={style.rowTitle}>Аудитория</div>
                <div>{lesson.audience.name}</div>
            </div>
        </div>
    )
}
