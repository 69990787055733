import { requireField } from 'src/constants/constants'
import { GroupCreationForm } from '../GroupActionTab'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { FC, ReactElement } from 'react'
import classNames from 'classnames'
import { useFormik } from 'formik'
import { Group } from 'src/api'

export type GroupModalSubmitValues = {
    name: string,
    capacity: number,
    expectedLessons: number,
    year: number,
}

export type GroupModalProps = {
    group?: Group;
    onSubmit: (form: GroupModalSubmitValues) => Promise<unknown>;
    isLoading: boolean;
}

export const AddModalContent: FC<GroupModalProps> = (props: GroupModalProps): ReactElement => {
    const {
        onSubmit,
        isLoading,
        group,
    } = props
    
    const getInitialValues = (): GroupCreationForm => {
        if (!group) return {
            year: new Date().getFullYear(),
        }
        
        return {
            name: group.name,
            capacity: group.details.capacity.total,
            expectedLessons: group.details.expectedLessons,
            year: group.details.launchYear,
        }
    }
    
    const formik = useFormik<GroupCreationForm>({
        initialValues: getInitialValues(),
        validate: ({ name, capacity, year, expectedLessons }) => {
            const errors: Partial<Record<keyof GroupCreationForm, string>> = {}
            
            if (!name) errors.name = requireField
            
            if (!capacity) errors.capacity = requireField
            
            if (capacity && capacity <= 0)
                errors.capacity = 'Число должно быть положительным!'
            
            if (!year) errors.capacity = requireField
            
            if (year && year <= 0)
                errors.capacity = 'Число должно быть положительным!'
            
            if (!expectedLessons) errors.capacity = requireField
            
            if (expectedLessons && expectedLessons <= 0)
                errors.capacity = 'Число должно быть положительным!'
            
            return errors
        },
        onSubmit: values => {
            if (
                !values.name
                || !values.expectedLessons
                || !values.year
                || !values.capacity
            ) return
            
            onSubmit(values as GroupModalSubmitValues).then(() => {
                formik.resetForm()
            })
        },
    })
    
    const isFormFieldValid = (name: keyof GroupCreationForm) =>
        !!(formik.touched[name] && formik.errors[name])
    
    const getFormErrorMessage = (name: keyof GroupCreationForm) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        )
    }
    
    const { name, capacity, year, expectedLessons } = formik.values
    
    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="field">
                <span className="p-float-label">
                    <InputText
                        id="name"
                        name="name"
                        value={name}
                        onChange={formik.handleChange}
                        className={classNames({
                            'p-invalid': isFormFieldValid('name'),
                        })}
                    />
                    <label
                        htmlFor="name"
                        className={classNames({
                            'p-error': isFormFieldValid('name'),
                        })}
                    >
                        Наименование
                    </label>
                </span>
                {getFormErrorMessage('name')}
            </div>
            <div className="field">
                <span className="p-float-label">
                    <InputNumber
                        id="capacity"
                        name="capacity"
                        value={capacity}
                        placeholder="16"
                        onChange={event =>
                            formik.setFieldValue('capacity', event.value)
                        }
                        defaultValue={16}
                        className={classNames({
                            'p-invalid': isFormFieldValid('capacity'),
                        })}
                        min={1}
                    />
                    <label
                        htmlFor="capacity"
                        className={classNames({
                            'p-error': isFormFieldValid('capacity'),
                        })}
                    >
                        Количество мест
                    </label>
                </span>
                {getFormErrorMessage('capacity')}
            </div>
            <div className="field">
                <span className="p-float-label">
                    <InputNumber
                        useGrouping={false}
                        id="year"
                        name="year"
                        value={year}
                        onChange={event =>
                            formik.setFieldValue('year', event.value)
                        }
                        className={classNames({
                            'p-invalid': isFormFieldValid('year'),
                        })}
                        placeholder={new Date().getFullYear().toString()}
                        defaultValue={new Date().getFullYear()}
                    />
                    <label
                        htmlFor="year"
                        className={classNames({
                            'p-error': isFormFieldValid('year'),
                        })}
                    >
                        Год запуска
                    </label>
                </span>
                {getFormErrorMessage('capacity')}
            </div>
            <div className="field">
                <span className="p-float-label">
                    <InputNumber
                        id="expectedLessons"
                        name="expectedLessons"
                        value={expectedLessons}
                        placeholder="16"
                        onChange={event =>
                            formik.setFieldValue('expectedLessons', event.value)
                        }
                        defaultValue={16}
                        className={classNames({
                            'p-invalid': isFormFieldValid('expectedLessons'),
                        })}
                        min={1}
                    />
                    <label
                        htmlFor="expectedLessons"
                        className={classNames({
                            'p-error': isFormFieldValid('expectedLessons'),
                        })}
                    >
                        Количество пар
                    </label>
                </span>
                {getFormErrorMessage('capacity')}
            </div>
            <Button loading={isLoading} type="submit" className={'w-auto'}>
                Сохранить
            </Button>
        </form>
    )
}
