export type PathEntityIdentifier<
    EntityIdentifierFieldName extends string,
    EntityIdentifierType = number
> = {
    path: {
        [FieldName in EntityIdentifierFieldName]: EntityIdentifierType
    }
}

export type RequestBody<BodyType = any> = {
    body: BodyType;
};

export enum HttpMethod {
    Post = 'POST',
    Get = 'GET',
    Patch = 'PATCH',
    Delete = 'DELETE'
}

