import { LessonMaterial, LessonMaterialWithHomework, ShortMaterial } from 'src/api/endpoints/lessons/lessons.types'
import { Nullable } from 'primereact/ts-helpers'

export const isLessonMaterialHasHomework =
    (lessonAttachedMaterial: LessonMaterial): lessonAttachedMaterial is LessonMaterialWithHomework => {
        return lessonAttachedMaterial.material._meta.hasHomework
    }

export const isMaterialHasHomework = (material: Nullable<ShortMaterial>): material is ShortMaterial<true> => {
    return material?._meta?.hasHomework || false
}