import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { IDropdownItem } from '../student/ProgressPage/ProgressPage.types'
import { Material } from 'src/store/admin/materials/materials.types'
import { Dropdown as AppDropDown } from 'src/components/Dropdown'
import { CanAttachPackageSelector } from 'src/store/selectors'
import React, { FC, useEffect, useRef, useState } from 'react'
import { _notify, NotificationTypes } from 'src/utils/notify'
import useDateFormatter from 'src/hooks/useDateFormatter'
import { InputTextarea } from 'primereact/inputtextarea'
import { requireField } from 'src/constants/constants'
import { ConfirmPopup } from 'primereact/confirmpopup'
import useAppSelector from 'src/hooks/useAppSelector'
import useAppDispatch from 'src/hooks/useAppDispatch'
import { filter, isEmpty, map, size } from 'lodash'
import { validateUrl } from 'src/utils/validateUrl'
import { confirmTooltip } from 'src/utils/confirm'
import { Modal } from 'src/components/Modal/Modal'
import { InputText } from 'primereact/inputtext'
import { downloadFile } from 'src/downloadFile'
import { Dropdown } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import { Helmet } from 'react-helmet-async'
import Loader from 'src/components/Loader'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { useFormik } from 'formik'
import { createMaterial, deleteMaterial, getMaterials } from 'src/store/admin/materials/materials.actions'
import { Source, SourceColor } from 'src/store/student/material/material.types'
import { FileUpload, FileUploadType } from 'src/components/FileUpload/FileUpload'
import {
    NewFile,
    UpdateFile,
    useGetMaterialByIdQuery,
    useGetProgrammeNamesQuery,
    useUpdateMaterialMutation,
} from 'src/api/endpoints/materials'

export interface Form {
    source: Source | null;
    name: number | null;
    theme: string;
    link?: string;
    file?: Array<File>;
    homeWorkFile?: Array<File>;
    practice?: Array<File>;
    presentation?: Array<File>;
}

interface SourceFilterProps {
    id: number;
    label: Source;
    onClick: () => void;
    source?: Source | null;
    className?: string;
}

const SourceFilter: FC<SourceFilterProps> = ({
                                                 id,
                                                 label,
                                                 onClick,
                                                 source,
                                                 className,
                                             }) => {
    return (
        <Button
            type={'button'}
            label={label}
            onClick={onClick}
            className={classNames(
                `p-button-rounded id-${id} min-w-fit`,
                className,
            )}
            style={{
                borderColor: SourceColor[label],
                background:
                    source === label
                    ? SourceColor[label]
                    : SourceColor[label] + '20',
                color: source === label ? '#fff' : SourceColor[label],
            }}
        />
    )
}

function generateFile(name: string): File {
    return new File([], name, { lastModified: 0 }) // пустые файлы с lastModified : 0
}

const toBase64 = (file: File): Promise<NewFile> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = () =>
            resolve({
                content: Array.from(
                    reader.result
                    ? new Uint8Array(reader.result as ArrayBufferLike)
                    : [],
                ),
                name: file.name,
            })
        reader.onerror = error => reject(error)
    })

function formatFiles(
    files: Array<File> | null | undefined,
): Promise<UpdateFile> {
    if (!files)
        return new Promise(resolve => {
            resolve({
                news: [],
                old: [],
            })
        })
    const old = map(
        filter(files, file => file.lastModified === 0),
        file => file.name,
    )
    return Promise.all(
        map(
            filter(files, file => file.lastModified !== 0),
            file => toBase64(file),
        ),
    ).then(convertedFiles => {
        return {
            news: convertedFiles,
            old,
        }
    })
}

const ModalContent = ({
                          onSubmit,
                          id,
                          onValid,
                      }: {
    id: number | null;
    onSubmit: (values: Form) => void;
    onValid: () => void;
}) => {
    const formik = useFormik<Form>({
        initialValues: {
            name: null,
            theme: '',
            source: null,
            link: '',
            file: [],
            homeWorkFile: [],
            practice: [],
            presentation: [],
        },
        validate: ({ name, source, theme, file, link }) => {
            const errors: Partial<Record<keyof Form, any>> = {}
            
            if (!name) errors.name = requireField
            if (!theme) errors.theme = requireField
            if (!source) errors.source = requireField
            if (!link?.trim().length && isEmpty(file)) {
                errors.link = 'Введите ссылку или выберите файл'
                errors.file = 'Введите ссылку или выберите файл'
            } else {
                if (link && !validateUrl(link)) {
                    errors.link = 'Ссылка не прошла валидацию'
                }
            }
            return errors
        },
        onSubmit: values => {
            if (!id) return onSubmit(values)
            Promise.all([
                    formatFiles(values.file),
                    formatFiles(values.homeWorkFile),
                    formatFiles(values.practice),
                    formatFiles(values.presentation),
                ])
                .then(
                    ([files, homeworkFile, practiceFile, presentationFile]) => {
                        update({
                            link: values.link!,
                            theme: values.theme!,
                            name: values.name!,
                            source: values.source!,
                            materialId: id,
                            files,
                            homeworkFile,
                            practiceFile,
                            presentationFile,
                        })
                            .unwrap()
                            .then(() => {
                                onValid()
                                _notify(
                                    window.Toast,
                                    {
                                        type: NotificationTypes.success,
                                        content: 'Материал успешно обновлён!',
                                    },
                                    true,
                                )
                            })
                    },
                )
                .catch(console.error)
        },
    })
    
    const { link, theme, file, homeWorkFile, name, presentation, practice } =
        formik.values
    
    const isFormFieldValid = (name: keyof Form) =>
        !!(formik.touched[name] && formik.errors[name])
    const getFormErrorMessage = (name: keyof Form) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        )
    }
    
    const { data: themes, isFetching: isThemesFetching } =
        useGetProgrammeNamesQuery()
    const { pending } = useAppSelector(
        state => state.adminMaterialReducer.createMaterial,
    )
    const { data } = useGetMaterialByIdQuery({ id }, { skip: !id })
    const [update, { isLoading }] = useUpdateMaterialMutation()
    useEffect(() => {
        if (
            data &&
            fileRef.current &&
            homeworkRef.current &&
            practiceRef.current &&
            presentationRef.current
        ) {
            const file = map(data.fileMaterials, name => generateFile(name)),
                homework = map(data.homeworkFiles, name => generateFile(name)),
                practice = map(data.practiceFiles, name => generateFile(name)),
                presentation = map(data.presentationFiles, name =>
                    generateFile(name),
                )
            formik.setFieldValue('file', file)
            formik.setFieldValue('homework', homework)
            formik.setFieldValue('practice', practice)
            formik.setFieldValue('presentation', presentation)
            formik.setFieldValue('link', data.link)
            formik.setFieldValue('theme', data.theme)
            formik.setFieldValue('name', data.nameId)
            formik.setFieldValue('source', data.source)
            fileRef.current.setFiles(file)
            homeworkRef.current.setFiles(homework)
            practiceRef.current.setFiles(practice)
            presentationRef.current.setFiles(presentation)
        }
    }, [data])
    const fileRef = useRef<FileUploadType>(null)
    const homeworkRef = useRef<FileUploadType>(null)
    const practiceRef = useRef<FileUploadType>(null)
    const presentationRef = useRef<FileUploadType>(null)
    return (
        <div>
            <form onSubmit={formik.handleSubmit} className="p-fluid">
                <div className="field">
                    <span className="p-float-label">
                        <AppDropDown
                            filter
                            id="name"
                            name="name"
                            options={map(themes, ({ name, id }) => ({
                                label: name,
                                value: id,
                            }))}
                            loading={isThemesFetching}
                            value={name}
                            onChange={formik.handleChange}
                            className={classNames({
                                'p-invalid': isFormFieldValid('name'),
                            })}
                        />
                        <label
                            htmlFor="name"
                            className={classNames({
                                'p-error': isFormFieldValid('name'),
                            })}
                        >
                            Предмет
                        </label>
                    </span>
                    {getFormErrorMessage('name')}
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <InputTextarea
                            id="theme"
                            name="theme"
                            value={theme}
                            onChange={formik.handleChange}
                            className={classNames({
                                'p-invalid': isFormFieldValid('theme'),
                            })}
                        />
                        <label
                            htmlFor="theme"
                            className={classNames({
                                'p-error': isFormFieldValid('theme'),
                            })}
                        >
                            Тема
                        </label>
                    </span>
                    {getFormErrorMessage('theme')}
                </div>
                <div className={'field'}>
                    <div className={'flex gap-24 flex-wrap'}>
                        {map(
                            Object.keys(SourceColor),
                            (source: Source, index) => (
                                <SourceFilter
                                    id={index}
                                    key={source}
                                    label={source}
                                    source={formik.values.source}
                                    className={'flex-1'}
                                    onClick={() => {
                                        formik.setFieldValue('source', source)
                                    }}
                                />
                            ),
                        )}
                    </div>
                    {getFormErrorMessage('source')}
                </div>
                <small className="date">Вставьте ссылку</small>
                <div className="field mt-8">
                    <InputText
                        disabled={!!file?.length}
                        id="link"
                        name="link"
                        className={classNames({
                            'p-invalid': isFormFieldValid('link'),
                        })}
                        onChange={formik.handleChange}
                        placeholder="https://example.com/"
                        value={link}
                    />
                    {getFormErrorMessage('link')}
                </div>
                <small className="date">Или прикрепите файлы</small>
                <div className="field mt-8">
                    <FileUpload
                        ref={fileRef}
                        disabled={!!link?.trim().length}
                        onSelect={event =>
                            formik.setFieldValue('file', [
                                ...(file ? file : []),
                                ...event.files,
                            ])
                        }
                        onClear={() => formik.setFieldValue('file', null)}
                        onRemove={e =>
                            formik.setFieldValue(
                                'file',
                                filter(
                                    file,
                                    _file => _file.name !== e.file.name,
                                ),
                            )
                        }
                    />
                    {getFormErrorMessage('file')}
                </div>
                <small className={'date'}>Домашнее задание</small>
                <div className="field mt-8">
                    <FileUpload
                        ref={homeworkRef}
                        onSelect={event =>
                            formik.setFieldValue('homeWorkFile', [
                                ...(homeWorkFile ? homeWorkFile : []),
                                ...event.files,
                            ])
                        }
                        onClear={() =>
                            formik.setFieldValue('homeWorkFile', null)
                        }
                        onRemove={e =>
                            formik.setFieldValue(
                                'homeWorkFile',
                                filter(
                                    homeWorkFile,
                                    _file => _file.name !== e.file.name,
                                ),
                            )
                        }
                    />
                    {getFormErrorMessage('homeWorkFile')}
                </div>
                <small className={'date'}>Презентация</small>
                <div className="field mt-8">
                    <FileUpload
                        ref={presentationRef}
                        onSelect={event =>
                            formik.setFieldValue('presentation', [
                                ...(presentation ? presentation : []),
                                ...event.files,
                            ])
                        }
                        onClear={() =>
                            formik.setFieldValue('presentation', null)
                        }
                        onRemove={e =>
                            formik.setFieldValue(
                                'presentation',
                                filter(
                                    presentation,
                                    _file => _file.name !== e.file.name,
                                ),
                            )
                        }
                    />
                    {getFormErrorMessage('presentation')}
                </div>
                <small className={'date'}>Практическая</small>
                <div className="field mt-8">
                    <FileUpload
                        ref={practiceRef}
                        onSelect={event =>
                            formik.setFieldValue('practice', [
                                ...(practice ? practice : []),
                                ...event.files,
                            ])
                        }
                        onClear={() => formik.setFieldValue('practice', null)}
                        onRemove={e =>
                            formik.setFieldValue(
                                'practice',
                                filter(
                                    practice,
                                    _file => _file.name !== e.file.name,
                                ),
                            )
                        }
                    />
                    {getFormErrorMessage('practice')}
                </div>
                <Button
                    loading={id ? isLoading : pending}
                    type="submit"
                    label="Сохранить"
                    className={'w-auto'}
                />
            </form>
        </div>
    )
}

interface MaterialsPageProps {
    allowEdit?: boolean;
}

export const MaterialsPage: FC<MaterialsPageProps> = ({
                                                          allowEdit,
                                                      }: MaterialsPageProps) => {
    const dispatch = useAppDispatch()
    const canAttachPackage = useAppSelector(CanAttachPackageSelector)
    const toast = useRef<Toast>(null)
    const [modal, setModal] = useState<{
        id: number | null;
        visible: boolean;
    }>({ id: null, visible: false })
    const [loadingMaterial, setLoadingMaterial] = useState<number | null>(null)
    
    function getData() {
        dispatch(getMaterials())
            .unwrap()
            .catch(e => {
                _notify(toast, { type: NotificationTypes.error, content: e })
            })
    }
    
    function onHide() {
        setModal({ id: null, visible: false })
    }
    
    function onSubmit(form: Form) {
        dispatch(
            createMaterial({
                ...form,
                homeWorkFile: size(form.homeWorkFile)
                              ? form.homeWorkFile
                              : undefined,
            }),
        )
            .unwrap()
            .then(() => {
                _notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Вы успешно создали материал!',
                    },
                    true,
                )
                onHide()
            })
            .then(() => {
                getData()
            })
            .catch(e => {
                _notify(toast, { type: NotificationTypes.error, content: e })
            })
    }
    
    useEffect(() => {
        getData()
    }, [])
    
    const { data } = useAppSelector(state => state.adminMaterialReducer)
    
    const { pending } = useAppSelector(
        state => state.adminMaterialReducer.getMaterials,
    )
    
    const [materials, setMaterials] = useState<Material[]>([])
    const [filter, setFilter] = useState<{
        source?: Source;
        subject?: IDropdownItem<string>;
        theme?: IDropdownItem<string>;
        search?: string;
    }>({})
    
    const _ = useDateFormatter()
    
    useEffect(() => {
        setMaterials(data.materials)
    }, [data.materials])
    
    useEffect(() => {
        if (
            !filter.search &&
            !filter.source &&
            !filter.subject &&
            !filter.theme
        ) {
            setMaterials(data.materials)
            return
        }
        
        let qs = data.materials
        
        if (filter.source) {
            qs = qs.filter(m => m.source === filter.source)
        }
        
        if (filter.subject && filter.subject.code !== 'all') {
            qs = qs.filter(m => m.theme === filter.subject?.name)
        }
        
        if (filter.theme && filter.theme.code !== 'all') {
            qs = qs.filter(m => m.name === filter.theme?.code)
        }
        
        if (filter.search && filter.search.trim().length) {
            qs = qs.filter(m =>
                m.name
                    .toLocaleLowerCase()
                    .includes(filter.search!.toLocaleLowerCase().trim()),
            )
        }
        
        setMaterials(qs)
    }, [filter, data])
    
    function onFilterChange(source: Source) {
        setFilter(prev => {
            if (prev.source === source) {
                return {
                    ...prev,
                    source: undefined,
                }
            }
            
            return { ...prev, source }
        })
    }
    
    function onDelete(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        id: number,
    ) {
        if (event) {
            confirmTooltip(event.currentTarget, {
                message: 'Вы уверены что хотите удалить?',
                accept: () => {
                    setLoadingMaterial(id)
                    dispatch(
                        deleteMaterial({
                            id,
                        }),
                    )
                        .unwrap()
                        .then(() =>
                            _notify(
                                toast,
                                {
                                    type: NotificationTypes.success,
                                    content: 'Вы успешно удалили материал!',
                                },
                                true,
                            ),
                        )
                        .then(getData)
                        .catch(e => {
                            _notify(toast, {
                                type: NotificationTypes.error,
                                content: e,
                            })
                        })
                        .finally(() => {
                            setLoadingMaterial(null)
                        })
                },
            })
        }
    }
    
    function onUpdate(material: Material) {
        setModal({ id: material.id, visible: true })
    }
    
    const allow = allowEdit && canAttachPackage
    return (
        <StudentLayout pageName="materials-page">
            <Helmet title={'Методические материалы'} />
            <ConfirmPopup />
            <Toast ref={toast} />
            {allow ? (
                <Modal
                    header={`${modal.id ? 'Редактирование' : 'Создание'} материала`}
                    visible={modal.visible}
                    onHide={onHide}
                >
                    <ModalContent
                        id={modal.id}
                        onSubmit={onSubmit}
                        onValid={() => {
                            getData()
                            onHide()
                        }}
                    />
                </Modal>
            ) : null}
            <div className="card mt-24">
                <div className="header flex align-items-center justify-content-between materials-bg">
                    <div className="title big">Методические материалы</div>
                </div>
                {pending ? (
                    <Loader />
                ) : (
                    <div className="content">
                        <div className="text fz-20 bold">
                            Методические материалы
                        </div>
                        {allow ? (
                            <Button
                                className={'mt-24'}
                                onClick={() =>
                                    setModal({ id: null, visible: true })
                                }
                            >
                                Загрузить материалы
                            </Button>
                        ) : null}
                        <div className="flex mt-24 p-12 pl-4 source-filter">
                            <div className="pair">
                                <SourceFilter
                                    id={0}
                                    label="Уроки"
                                    source={filter.source}
                                    onClick={() => onFilterChange('Уроки')}
                                />
                                <SourceFilter
                                    id={1}
                                    label="Библиотека"
                                    source={filter.source}
                                    className={'ml-24'}
                                    onClick={() => onFilterChange('Библиотека')}
                                />
                            </div>
                            <div className="pair">
                                <SourceFilter
                                    id={2}
                                    label="Видео"
                                    source={filter.source}
                                    className={'ml-24'}
                                    onClick={() => onFilterChange('Видео')}
                                />
                                <SourceFilter
                                    id={3}
                                    label="Статьи"
                                    source={filter.source}
                                    className={'ml-24'}
                                    onClick={() => onFilterChange('Статьи')}
                                />
                            </div>
                        </div>
                        <div className="flex filter-container filter-container mt-12">
                            <Dropdown
                                value={filter.subject}
                                className="w-300"
                                options={[
                                    {
                                        name: '------',
                                        code: 'all',
                                    },
                                    ...data.subjects.map(s => ({
                                        name: s.name,
                                        code: s.name,
                                    })),
                                ]}
                                onChange={event =>
                                    setFilter(prev => ({
                                        ...prev,
                                        subject: event.value,
                                        theme: data.subjects
                                                   .find(
                                                       s => s.name === event.value.name,
                                                   )
                                                   ?.themes.includes(
                                                prev.theme
                                                ? prev.theme!.name
                                                : '',
                                            )
                                               ? prev.theme
                                               : undefined,
                                    }))
                                }
                                optionLabel="name"
                                placeholder="Выберите предмет"
                            />
                            <Dropdown
                                value={filter.theme}
                                className="mt ml-24 w-300"
                                options={[
                                    {
                                        name: '------',
                                        code: 'all',
                                    },
                                    ...(data.subjects
                                        .find(
                                            s => s.name === filter.subject?.name,
                                        )
                                        ?.themes.map(t => ({
                                            name: t,
                                            code: t,
                                        })) || []),
                                ]}
                                onChange={event =>
                                    setFilter(prev => ({
                                        ...prev,
                                        theme: event.value,
                                    }))
                                }
                                optionLabel="name"
                                placeholder="Выберите тему"
                            />
                            <span className="p-input-icon-right ml-24 mt">
                                <i className="pi pi-search" />
                                <InputText
                                    value={filter.search}
                                    onChange={event =>
                                        setFilter(prev => ({
                                            ...prev,
                                            search: event.target.value,
                                        }))
                                    }
                                    placeholder="Поиск"
                                />
                            </span>
                        </div>
                        <div className="grid mt-48">
                            {materials.length ? (
                                materials.map(material => (
                                    <div
                                        className="lg:col-3 md:col-6 col-12"
                                        key={material.id}
                                    >
                                        <div
                                            className="area material bg flex flex-column justify-content-between h-full">
                                            <div
                                                className={
                                                    'body flex flex-column justify-content-between'
                                                }
                                            >
                                                <div
                                                    className={
                                                        'flex flex-column'
                                                    }
                                                >
                                                    <div className="flex justify-content-between preview-container">
                                                        <div
                                                            className="source-label"
                                                            style={{
                                                                color: SourceColor[
                                                                    material
                                                                        .source
                                                                    ],
                                                                backgroundColor:
                                                                    SourceColor[
                                                                        material
                                                                            .source
                                                                        ] + '20',
                                                            }}
                                                        >
                                                            {material.source[0]}
                                                        </div>
                                                        <small className="date block ml-72">
                                                            {_(
                                                                material.createdDate,
                                                            )}
                                                        </small>
                                                    </div>
                                                    <div
                                                        className="text mt-24"
                                                        style={{ fontSize: 18 }}
                                                    >
                                                        {material.theme}
                                                    </div>
                                                    <div className="text bold mt-24 mb-64">
                                                        {material.name}
                                                    </div>
                                                </div>
                                                <div
                                                    className={'flex flex-wrap'}
                                                    style={{ gap: 12 }}
                                                >
                                                    <Button
                                                        style={{ flex: 1 }}
                                                        className="p-button-sm"
                                                        onClick={_ =>
                                                            onUpdate(material)
                                                        }
                                                        loading={
                                                            material.id ===
                                                            loadingMaterial
                                                        }
                                                        icon={'pi pi-pencil'}
                                                    />
                                                    {allow ? (
                                                        <Button
                                                            style={{ flex: 1 }}
                                                            className="p-button-sm"
                                                            loading={
                                                                material.id ===
                                                                loadingMaterial
                                                            }
                                                            onClick={event =>
                                                                onDelete(
                                                                    event,
                                                                    material.id,
                                                                )
                                                            }
                                                            icon={'pi pi-trash'}
                                                        />
                                                    ) : null}
                                                    {material.pathFileMaterial ? (
                                                        <Button
                                                            className="p-button-sm flex-grow-1"
                                                            loading={
                                                                material.id ===
                                                                loadingMaterial
                                                            }
                                                            icon={
                                                                'pi pi-download'
                                                            }
                                                            onClick={() => {
                                                                linkOrCallback(
                                                                    material.pathFileMaterial,
                                                                    () => {
                                                                        if (
                                                                            !material.pathFileMaterial
                                                                        )
                                                                            return
                                                                        
                                                                        setLoadingMaterial(
                                                                            material.id,
                                                                        )
                                                                        downloadFile(
                                                                            material.pathFileMaterial,
                                                                        )
                                                                            .catch(
                                                                                e => {
                                                                                    if (
                                                                                        e instanceof
                                                                                        Error
                                                                                    ) {
                                                                                        _notify(
                                                                                            window.Toast,
                                                                                            {
                                                                                                type: NotificationTypes.error,
                                                                                                content:
                                                                                                e.message,
                                                                                            },
                                                                                            true,
                                                                                        )
                                                                                    }
                                                                                },
                                                                            )
                                                                            .finally(
                                                                                () => {
                                                                                    setLoadingMaterial(
                                                                                        null,
                                                                                    )
                                                                                },
                                                                            )
                                                                    },
                                                                )
                                                            }}
                                                            label={'Материал'}
                                                        />
                                                    ) : null}
                                                    {material.pathHomeworkFile ? (
                                                        <Button
                                                            className="p-button-sm flex-grow-1"
                                                            icon={
                                                                'pi pi-download'
                                                            }
                                                            loading={
                                                                material.id ===
                                                                loadingMaterial
                                                            }
                                                            onClick={() => {
                                                                linkOrCallback(
                                                                    material.pathHomeworkFile,
                                                                    () => {
                                                                        if (
                                                                            !material.pathHomeworkFile
                                                                        )
                                                                            return
                                                                        
                                                                        setLoadingMaterial(
                                                                            material.id,
                                                                        )
                                                                        downloadFile(
                                                                            material.pathHomeworkFile,
                                                                        )
                                                                            .catch(
                                                                                e => {
                                                                                    if (
                                                                                        e instanceof
                                                                                        Error
                                                                                    ) {
                                                                                        _notify(
                                                                                            window.Toast,
                                                                                            {
                                                                                                type: NotificationTypes.error,
                                                                                                content:
                                                                                                e.message,
                                                                                            },
                                                                                            true,
                                                                                        )
                                                                                    }
                                                                                },
                                                                            )
                                                                            .finally(
                                                                                () => {
                                                                                    setLoadingMaterial(
                                                                                        null,
                                                                                    )
                                                                                },
                                                                            )
                                                                    },
                                                                )
                                                            }}
                                                            label={
                                                                'Домашнее задание'
                                                            }
                                                        />
                                                    ) : null}
                                                    {material.pathPresentationFile ? (
                                                        <Button
                                                            className="p-button-sm flex-grow-1"
                                                            icon={
                                                                'pi pi-download'
                                                            }
                                                            loading={
                                                                material.id ===
                                                                loadingMaterial
                                                            }
                                                            onClick={() => {
                                                                linkOrCallback(
                                                                    material.pathPresentationFile,
                                                                    () => {
                                                                        if (
                                                                            !material.pathPresentationFile
                                                                        )
                                                                            return
                                                                        
                                                                        setLoadingMaterial(
                                                                            material.id,
                                                                        )
                                                                        downloadFile(
                                                                            material.pathPresentationFile,
                                                                        )
                                                                            .catch(
                                                                                e => {
                                                                                    if (
                                                                                        e instanceof
                                                                                        Error
                                                                                    ) {
                                                                                        _notify(
                                                                                            window.Toast,
                                                                                            {
                                                                                                type: NotificationTypes.error,
                                                                                                content:
                                                                                                e.message,
                                                                                            },
                                                                                            true,
                                                                                        )
                                                                                    }
                                                                                },
                                                                            )
                                                                            .finally(
                                                                                () => {
                                                                                    setLoadingMaterial(
                                                                                        null,
                                                                                    )
                                                                                },
                                                                            )
                                                                    },
                                                                )
                                                            }}
                                                            label={
                                                                'Презентация'
                                                            }
                                                        />
                                                    ) : null}
                                                    {material.pathPracticeFile ? (
                                                        <Button
                                                            className="p-button-sm flex-grow-1"
                                                            icon={
                                                                'pi pi-download'
                                                            }
                                                            loading={
                                                                material.id ===
                                                                loadingMaterial
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    !material.pathPracticeFile
                                                                )
                                                                    return
                                                                
                                                                linkOrCallback(
                                                                    material.pathPracticeFile,
                                                                    () => {
                                                                        if (
                                                                            !material.pathPracticeFile
                                                                        )
                                                                            return
                                                                        
                                                                        setLoadingMaterial(
                                                                            material.id,
                                                                        )
                                                                        downloadFile(
                                                                            material.pathPracticeFile,
                                                                        )
                                                                            .catch(
                                                                                e => {
                                                                                    if (
                                                                                        e instanceof
                                                                                        Error
                                                                                    ) {
                                                                                        _notify(
                                                                                            window.Toast,
                                                                                            {
                                                                                                type: NotificationTypes.error,
                                                                                                content:
                                                                                                e.message,
                                                                                            },
                                                                                            true,
                                                                                        )
                                                                                    }
                                                                                },
                                                                            )
                                                                            .finally(
                                                                                () => {
                                                                                    setLoadingMaterial(
                                                                                        null,
                                                                                    )
                                                                                },
                                                                            )
                                                                    },
                                                                )
                                                            }}
                                                            label={
                                                                'Практическая'
                                                            }
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                 <div className="fz-20 px-2">
                                     Здесь пока ничего нет
                                 </div>
                             )}
                        </div>
                    </div>
                )}
            </div>
        </StudentLayout>
    )
}

function linkOrCallback(link: string | null, callback: () => void) {
    if (link) {
        if (link.startsWith('http')) {
            window.open(link, '_blank')!.focus()
        } else {
            callback()
        }
    }
}
