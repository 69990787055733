import { ModalHeader } from 'src/pages/edu/Groups/components/ModalHeader'
import { NotificationTypes, _notify } from 'src/utils/notify'
import { FC, ReactElement, RefObject, useRef } from 'react'
import { FormikHelpers, useFormik, Formik } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import { requireField } from 'src/constants/constants'
import { Calendar } from 'src/components/Calendar'
import { Modal } from 'src/components/Modal/Modal'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { Dropdown } from '../Dropdown'
import classNames from 'classnames'
import {
    useGetAvailableToAcademicLeaveStudentsQuery,
    useCreateAcademicVacationMutation,
    AvailableToAcademicLeaveStudent,
} from 'src/api/endpoints/academic'

export type RegisterAcademicLeaveForm = {
    id?: number;
    startAt?: Date;
    endsAt?: Date;
    reason?: string;
}

export type AcademicLeaveCreationModalProps = {
    visible: boolean;
    onHide: () => void;
    studentId?: number;
}

export const CreateAcademicVacationModal: FC<AcademicLeaveCreationModalProps> =
    (props: AcademicLeaveCreationModalProps): ReactElement => {
        const { visible, onHide, studentId } = props
        
        const toast: RefObject<Toast> = useRef<Toast>(null)
        
        const { data, isLoading } = useGetAvailableToAcademicLeaveStudentsQuery({
            // TODO: Подключить выбор группы для уменьшение списка студентов
            groupId: null,
        })
        
        const [create, { isLoading: isCreating }] =
            useCreateAcademicVacationMutation()
        
        const formik: Formik<RegisterAcademicLeaveForm> = useFormik<RegisterAcademicLeaveForm>({
            initialValues: { id: studentId },
            validate: (form: RegisterAcademicLeaveForm) => {
                const errors: Partial<Record<keyof RegisterAcademicLeaveForm, string>> = {}
                
                if (!form.startAt) errors.startAt = requireField
                if (!form.endsAt) errors.endsAt = requireField
                if (!form.reason) errors.reason = requireField
                if (!form.id) errors.id = requireField
                
                if (form.startAt && form.endsAt && form.endsAt < form.startAt) {
                    errors.startAt = 'Неверный временной интервал'
                    errors.endsAt = 'Неверный временной интервал'
                }
                
                return errors
            },
            onSubmit: (form: RegisterAcademicLeaveForm, helpers: FormikHelpers<RegisterAcademicLeaveForm>): void | Promise<any> => {
                if (
                    !form.startAt ||
                    !form.endsAt ||
                    !form.reason ||
                    !form.id
                )
                    return
                
                create({
                    studentId: form.id,
                    reason: form.reason,
                    startAt: form.startAt.getTime(),
                    endsAt: form.endsAt.getTime(),
                })
                    .unwrap()
                    .then((): void => {
                        if (!toast.current) return
                        
                        _notify(
                            toast.current,
                            {
                                type: NotificationTypes.success,
                                content: 'Вы успешно создали академ. отпуск!',
                            },
                            true,
                        )
                        onHide()
                        helpers.resetForm()
                    })
                    .catch((error: any): void => {
                        if (!toast.current) return
                        
                        _notify(
                            toast.current,
                            {
                                type: NotificationTypes.error,
                                content: error.data,
                            },
                            true,
                        )
                    })
            },
        })
        
        const isFormFieldValid = (name: keyof RegisterAcademicLeaveForm) =>
            !!(formik.touched[name] && formik.errors[name])
        
        const getFormErrorMessage = (name: keyof RegisterAcademicLeaveForm) => {
            return (
                isFormFieldValid(name) && (
                    <small className="p-error">{formik.errors[name]}</small>
                )
            )
        }
        
        return (
            <>
                <Modal
                    visible={visible}
                    header={
                        <ModalHeader>Регистрация академического отпуска</ModalHeader>
                    }
                    onHide={onHide}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <div className="flex flex-row justify-content-between flex-wrap">
                            <div className="field w-half pl-4">
                            <span className="p-float-label">
                                <Calendar
                                    id="startAt"
                                    name="startAt"
                                    value={formik.values.startAt}
                                    maxDate={formik.values.endsAt ?? undefined}
                                    onChange={formik.handleChange}
                                    className={classNames('w-400', {
                                        'p-invalid':
                                            isFormFieldValid('startAt'),
                                    })}
                                />
                                <label
                                    htmlFor="startAt"
                                    className={classNames({
                                        'p-error': isFormFieldValid('startAt'),
                                    })}
                                >
                                    Дата начала академического отпуска
                                </label>
                            </span>
                                {getFormErrorMessage('startAt')}
                            </div>
                            <div className="field w-half">
                            <span className="p-float-label">
                                <Calendar
                                    id="endsAt"
                                    name="endsAt"
                                    value={formik.values.endsAt}
                                    onChange={formik.handleChange}
                                    className={classNames('w-400', {
                                        'p-invalid': isFormFieldValid('endsAt'),
                                    })}
                                />
                                <label
                                    htmlFor="endsAt"
                                    className={classNames({
                                        'p-error': isFormFieldValid('endsAt'),
                                    })}
                                >
                                    Дата окончания академического отпуска
                                </label>
                            </span>
                                {getFormErrorMessage('endsAt')}
                            </div>
                        </div>
                        <div className="field w-full">
                        <span className="p-float-label">
                            <Dropdown
                                id="id"
                                name="id"
                                options={data?.map((student: AvailableToAcademicLeaveStudent) => ({
                                    label: student.fullname,
                                    value: student.id,
                                }))}
                                loading={isLoading}
                                filterMatchMode={'contains'}
                                value={formik.values.id}
                                onChange={formik.handleChange}
                                className={classNames('w-full', {
                                    'p-invalid': isFormFieldValid('id'),
                                })}
                            />
                            <label
                                htmlFor="studentId"
                                className={classNames({
                                    'p-error': isFormFieldValid('id'),
                                })}
                            >
                                Студент
                            </label>
                        </span>
                            {getFormErrorMessage('id')}
                        </div>
                        
                        <div className="field w-full">
                        <span className="p-float-label">
                            <InputTextarea
                                id="reason"
                                name="reason"
                                value={formik.values.reason}
                                onChange={formik.handleChange}
                                className={classNames('w-full', {
                                    'p-invalid': isFormFieldValid('reason'),
                                })}
                            />
                            <label
                                htmlFor="reason"
                                className={classNames({
                                    'p-error': isFormFieldValid('reason'),
                                })}
                            >
                                Причина заморозки
                            </label>
                        </span>
                            {getFormErrorMessage('reason')}
                        </div>
                        <Button type="submit" loading={isLoading || isCreating}>
                            Заморозить
                        </Button>
                    </form>
                </Modal>
                <Toast ref={toast} />
            </>
        )
    }
