// region
export enum LessonStatus {
    EndedAndMarked = 0,
    EndedAndNotMarked = 1,
    EndedAndWaitForMark = 2,
    Now = 3,
    Scheduled = 4
}

export enum StudentAttendanceStatus {
    Present,
    Absent,
    Late,
}

type LessonStatusConfig = {
    name: string;
    color: string;
    clickable: boolean;
}

// TODO: Подключить и вывести на странице группы
export const LessonStatusMessages: Record<LessonStatus, LessonStatusConfig> = {
    [LessonStatus.EndedAndMarked]: {
        name: 'Урок отмечен преподавателем',
        color: '',
        clickable: true,
    },
    [LessonStatus.EndedAndNotMarked]: {
        name: 'Урок не отмечен преподавателем',
        color: '',
        clickable: true,
    },
    [LessonStatus.EndedAndWaitForMark]: {
        name: 'Урок ожидает отметки преподавателем',
        color: '',
        clickable: true,
    },
    [LessonStatus.Now]: {
        name: 'Урок в текущий момент проводится',
        color: '',
        clickable: true,
    },
    [LessonStatus.Scheduled]: {
        name: 'Урок запланирован',
        color: '',
        clickable: false,
    },
}
// endregion