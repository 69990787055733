import { DataTableArgs } from 'src/components/DataTable/DataTable.types';
import { getUserFullname } from 'src/utils/getFullname'
import { baseApi } from 'src/api/baseApi';
import {
    GetDataForCreateUserResponse,
    UpdateUserAccountArg,
    GetTeachersResponse,
    AddUserAccountArg,
    GetUsersResponse,
} from 'src/api/endpoints/users/users.types';

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query<GetUsersResponse, DataTableArgs>({
            providesTags: ['User'],
            query: arg => ({
                url: '/users/page',
                data: arg,
                method: 'post',
                errorMessage: 'Не удалось получить информацию о пользователях!',
            }),
            transformResponse(val: any) {
                return {
                    data: {
                        content: val.items.map(el => ({
                            ...el,
                            filial: el.filial.name,
                            filialId: el.filial.id,
                            role: el.role.id,
                            roleName: el.role.name,
                        })),
                    },
                    metaData: {
                        totalOffset: val.meta.offset,
                        totalRecordsElement: val.meta.total,
                    },
                };
            },
        }),
        addUserAccount: builder.mutation<void, AddUserAccountArg>({
            invalidatesTags: ['User'],
            query: arg => ({
                url: 'users',
                method: 'post',
                data: {
                    ...arg,
                    roleCode: arg.role,
                    filialId: arg.filial,
                },
                errorMessage: 'Не удалось добавить пользователя!',
            }),
        }),
        updateUserAccount: builder.mutation<void, UpdateUserAccountArg>({
            invalidatesTags: ['User'],
            query: arg => ({
                url: `users/${arg.id}`,
                method: 'PATCH',
                data: arg,
                errorMessage: 'Не удалось обновить студента!',
            }),
        }),
        getTeachers: builder.query<GetTeachersResponse, null>({
            providesTags: ['User'],
            query: _ => ({
                url: '/users/teachers',
                method: 'get',
                errorMessage:
                    'Не удалось получить информацию о преподавателях!',
            }),
            transformResponse(val: any) {
                return val.map(el => ({
                    ...el,
                    fio: getUserFullname(el),
                }));
            },
        }),
        getDataForCreateUser: builder.query<
            GetDataForCreateUserResponse,
            void | null
        >({
            query: _ => ({
                url: '/users/data/create',
                method: 'get',
                errorMessage:
                    'Не удалось получить информацию для создания пользователя!',
            }),
            transformResponse(val: any) {
                return {
                    filials: val.filials,
                    typeRoleUsers: val.roles.map(el => ({
                        roleId: el.id,
                        role: el.name,
                    })),
                };
            },
        }),
    }),
});

export const {
    useGetUsersQuery,
    useAddUserAccountMutation,
    useUpdateUserAccountMutation,
    useGetDataForCreateUserQuery,
} = api;
