import { EducationDepartmentPaths } from 'src/routes/roles/EducationDepartment'

export const EducationDepartmentRoutesNavigation: Record<EducationDepartmentPaths, CallableFunction> = {
    [EducationDepartmentPaths.MaterialsPage]:
        (): string => '/materials',
    
    [EducationDepartmentPaths.StudentAttendancePage]:
        (studentId: string): string => `/students/${studentId}/attendance/`,
    
    [EducationDepartmentPaths.SubjectsPage]:
        (): string => '/subjects',
    
    [EducationDepartmentPaths.SchedulePage]:
        (): string => '/schedule',
    
    [EducationDepartmentPaths.NotificationsPage]:
        (): string => '/notifications',
    
    // Groups pages
    [EducationDepartmentPaths.GroupsPage]:
        (): string => '/groups',
    
    [EducationDepartmentPaths.GroupPage]:
        (groupId: number): string => `/groups/${groupId}/`,
    
    [EducationDepartmentPaths.GroupsStudentsPage]:
        (groupId: number): string => `/groups/${groupId}/students/`,
    
    // Students pages
    [EducationDepartmentPaths.StudentsPage]:
        (): string => '/students',
    
    [EducationDepartmentPaths.StudentPage]:
        (studentId: number): string => `/students/${studentId}/`,
    
    [EducationDepartmentPaths.StudentAttendancePage]:
        (studentId: number): string => `/students/${studentId}/attendance`,
    
    [EducationDepartmentPaths.AttendancePage]:
        (): string => `/attendance`,
    
    [EducationDepartmentPaths.AppealsPage]:
        (): string => '/mail',
    
    [EducationDepartmentPaths.AudiencesPage]:
        (): string => '/audiences',
    
    [EducationDepartmentPaths.StatisticsPage]:
        (): string => '/statistics',
    
    [EducationDepartmentPaths.AcademicLeavesPage]:
        (): string => '/academic',
}

export const EDRNavigation: Record<EducationDepartmentPaths, CallableFunction> = EducationDepartmentRoutesNavigation
