import {
    AcademicVacation,
    AvailableToAcademicLeaveStudent,
    GetAvailableToAcademicLeaveStudentsParams,
} from 'src/api/endpoints/academic/academic.types'
import { baseApi, QueryPayload } from 'src/api'

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getAvailableToAcademicLeaveStudents: builder.query<
            AvailableToAcademicLeaveStudent[],
            GetAvailableToAcademicLeaveStudentsParams
        >({
            providesTags: ['Student', 'Academic'],
            query: (args: GetAvailableToAcademicLeaveStudentsParams): QueryPayload => ({
                url: `/academicLeaves/students/available`,
                method: 'GET',
                errorMessage: 'Не удалось получить информацию о доступных студентах',
                params: args.groupId ? { groupId: args.groupId } : {},
            }),
        }),
        getAcademicLeaves: builder.query<AcademicVacation[], void>({
            providesTags: ['Academic'],
            query: (): QueryPayload => ({
                url: `/academicLeaves`,
                method: 'get',
                errorMessage:
                    'Не удалось получить информацию об академических отпусках!',
            }),
        }),
        getAcademicLeaveById: builder.query<AcademicVacation, number>({
            providesTags: ['Academic'],
            query: (id): QueryPayload => ({
                url: `/students/${id}/academicLeaves/current`,
                method: 'get',
                errorMessage:
                    'Не удалось получить информацию об академических отпусках!',
            }),
        }),
        finishAcademicVacation: builder.mutation<
            void,
            { academicLeaveId: string; finishedAt: number; groupId: number }
        >({
            invalidatesTags: ['Student', 'Academic'],
            query: ({ academicLeaveId, ...rest }) => ({
                url: `/academicLeaves/${academicLeaveId}/finish`,
                method: 'PATCH',
                data: rest,
                errorMessage: 'Не удалось завершить академических отпуск!',
            }),
        }),
        extendAcademicVacation: builder.mutation<
            void,
            {
                academicLeaveId: string;
                extendedTo: number;
                reason: string;
            }
        >({
            invalidatesTags: ['Student', 'Academic'],
            query: ({ academicLeaveId, ...rest }) => ({
                url: `/academicLeaves/${academicLeaveId}/extend`,
                method: 'PATCH',
                data: rest,
                errorMessage: 'Не удалось продлить академический отпуск!',
            }),
        }),
        createAcademicVacation: builder.mutation<
            void,
            {
                startAt: number;
                endsAt: number;
                reason: string;
                studentId: number;
            }
        >({
            invalidatesTags: ['Student', 'Academic'],
            query: data => ({
                url: `/academicLeaves`,
                method: 'post',
                data,
                errorMessage: 'Не удалось создать академ. отпуск!',
            }),
        }),
    }),
})

export const {
    useGetAvailableToAcademicLeaveStudentsQuery,
    useGetAcademicLeavesQuery,
    useFinishAcademicVacationMutation,
    useExtendAcademicVacationMutation,
    useCreateAcademicVacationMutation,
    useGetAcademicLeaveByIdQuery,
} = api
