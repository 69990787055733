import { AnswerMailModal, AnswerMailModalForm } from './components/AnswerMailModal'
import { CreateMailModal, CreateMailModalForm } from './components/CreateMailModal'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { DataTable, usePagination } from 'src/components/DataTable'
import React, { ReactElement, useRef, useState } from 'react'
import { DataTableExpandedRows } from 'primereact/datatable'
import { dateFormatWithTime } from 'src/constants/constants'
import { NotificationTypes, _notify } from 'src/utils/notify'
import { MessageView } from './components/MessageView'
import { Modal } from 'src/components/Modal/Modal'
import { Toolbar } from 'src/components/Toolbar'
import { dayWorker } from 'src/utils/dateWorker'
import { Card } from 'src/components/Card/Card'
import { Divider } from 'primereact/divider'
import { Helmet } from 'react-helmet-async'
import Loader from 'src/components/Loader'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Tag } from 'primereact/tag'
import {
    createMessageArg,
    Message,
    useCreateMessageMutation,
    useGetRequestMessagesQuery,
} from 'src/api/endpoints/chat'

interface MailPageProps {
    student?: boolean;
}

export const MailPage = ({ student }: MailPageProps) => {
    const toast = useRef<Toast>(null)
    const [config, setConfig] = usePagination()
    const [createModal, setCreateModal] = useState(false)
    const [answerModal, setAnswerModal] = useState(false)
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>()
    const [initialValues, setInitialValues] = useState<Omit<
        createMessageArg,
        'text'
    > | null>(null)
    const { data, isLoading, isFetching } = useGetRequestMessagesQuery(config)
    const [add, { isLoading: isCreateLoading }] = useCreateMessageMutation()
    
    function onAnswerSubmit(values: AnswerMailModalForm) {
        if (!initialValues) return
        add({
            text: values.response,
            ...initialValues,
        })
            .unwrap()
            .then(_ => {
                setAnswerModal(false)
                setInitialValues(null)
                _notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Вы успешно ответили на обращение!',
                    },
                    true,
                )
            })
    }
    
    function onCreateSubmit({ theme, message, receiver }: CreateMailModalForm) {
        return add({
            id: null,
            title: theme,
            text: message,
            sendTo: receiver,
        })
            .unwrap()
            .then(_ => {
                setCreateModal(false)
                _notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Обращение успешно создано!',
                    },
                    true,
                )
            })
    }
    
    function rowExpansionTemplate(message: Message): ReactElement {
        return (
            <div>
                <MessageView
                    id={message.id}
                    text={message.text}
                    title={message.title}
                    time={dayWorker(message.creationDate).format(dateFormatWithTime)}
                    from={message.sendFromFIO}
                    filial={message.filial}
                />
                {message.messageAnswer ? (
                    <>
                        <Divider />
                        <MessageView
                            text={message.messageAnswer.answerMessage}
                            title={'Ответ'}
                            time={dayWorker(
                                message.messageAnswer.answerCreationDate,
                            ).format(dateFormatWithTime)}
                            id={message.messageAnswer.id}
                            from={message.messageAnswer.fromToFIO}
                            filial={message.filial}
                        />
                    </>
                ) : null}
            </div>
        )
    }
    
    function statusBodyTemplate({ answer }: Message) {
        return (
            <Tag
                value={answer ? 'Отвечено' : 'Не отвечено'}
                severity={answer ? 'success' : 'danger'}
            />
        )
    }
    
    return (
        <StudentLayout>
            <Helmet title={'Обращения'} />
            <Toast ref={toast} />
            {student ? (
                <Modal
                    header={'Создать обращение'}
                    visible={createModal}
                    onHide={() => setCreateModal(false)}
                >
                    <CreateMailModal
                        onSubmit={onCreateSubmit}
                        loading={isCreateLoading}
                    />
                </Modal>
            ) : (
                <Modal
                    header={'Ответ на обращение'}
                    visible={answerModal}
                    onHide={() => setAnswerModal(false)}
                >
                    <AnswerMailModal
                        onSubmit={onAnswerSubmit}
                        loading={isCreateLoading}
                    />
                </Modal>
            )}
            <Card headerClassName={'mt-24'} header={'Обращения'}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <DataTable
                        selectionMode={student ? undefined : 'single'}
                        header={
                            student ? (
                                <Toolbar
                                    add={{
                                        onClick: () => setCreateModal(true),
                                        loading: isCreateLoading,
                                    }}
                                />
                            ) : null
                        }
                        isDataSelectable={data => {
                            const message = data.data as unknown as Message
                            return !message.answer
                        }}
                        onRowSelect={row => {
                            const rowData = row.data as Message
                            setAnswerModal(true)
                            setInitialValues({
                                id: rowData.id,
                                title: rowData.titleId,
                                sendTo: rowData.sendFrom,
                            })
                        }}
                        expandedRows={expandedRows}
                        onRowToggle={e => {
                            setExpandedRows(e.data as DataTableExpandedRows)
                        }}
                        rowExpansionTemplate={rowExpansionTemplate}
                        onPagination={setConfig}
                        loading={isFetching}
                        value={data?.data.content}
                        config={{
                            ...config,
                            totalRecords:
                                data?.metaData.totalRecordsElement || 0,
                        }}
                    >
                        <Column expander style={{ width: '2rem' }} />
                        <Column field={'title'} header="Тип" />
                        <Column
                            field={'id'}
                            body={statusBodyTemplate}
                            header="Статус"
                        />
                    </DataTable>
                )}
            </Card>
        </StudentLayout>
    )
}
