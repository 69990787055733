import { isLessonMaterialHasHomework, isMaterialHasHomework } from 'src/api/endpoints/lessons/lessons.functions'
import { LessonMaterialInformation, ShortMaterial } from 'src/api/endpoints/lessons/lessons.types'
import { Formik, FormikErrors, FormikValues } from 'formik'
import { requireField } from 'src/constants/constants'
import {
    FormValuesInitializationFunction,
    FormikGetErrorMessageFunction,
    LessonMaterialFormValues,
    FormSubmitterWithNested,
    FormValidationFunction,
    FormikFieldValidator,
    FormSubmitValues,
} from 'src/pages/Attendant/form/form.types'

// TODO: Перенести в глобальные
// region globals
export const isFormikFormFieldValid: FormikFieldValidator = <Values extends FormikValues>(formik: Formik<Values>, name: keyof Values): boolean =>
    !!(formik.touched[name] && formik.errors[name])

export const getFormikFormErrorMessage: FormikGetErrorMessageFunction = <Values extends FormikValues>(formik: Formik<Values>, name: keyof Values) => {
    if (!isFormikFormFieldValid(formik, name)) return
    
    const fieldError: string = formik.errors[name] as string
    
    return <small className="p-error">{fieldError}</small>
}
// endregion

export const getLessonMaterialFormInitialValues: FormValuesInitializationFunction<LessonMaterialInformation, LessonMaterialFormValues> = (initialValues: LessonMaterialInformation): LessonMaterialFormValues => {
    const currentDate: Date = new Date(Date.now() + (1000 * 3600 * 24 * 7))
    currentDate.setMinutes(0)
    
    const values: LessonMaterialFormValues = {
        material: null,
        expiresAt: currentDate,
    }
    
    if (!initialValues.isAttached) return values
    
    values.material = initialValues.attachment.material
    
    if (!isLessonMaterialHasHomework(initialValues.attachment)) return values
    
    values.expiresAt = new Date(initialValues.attachment.expiresAt)
    
    return values
}

export const lessonMaterialFormValidator: FormValidationFunction<LessonMaterialFormValues> = (values: LessonMaterialFormValues): FormikErrors<LessonMaterialFormValues> => {
    const errors: FormikErrors<LessonMaterialFormValues> = {}
    
    if (!values.material) errors.material = requireField
    
    if (values.material && !isMaterialHasHomework(values.material))
        return errors
    
    if (!values.expiresAt)
        errors.expiresAt = requireField
    
    return errors
}

export const lessonMaterialFormSubmitter: FormSubmitterWithNested<LessonMaterialFormValues, FormSubmitValues> = (
    values: LessonMaterialFormValues,
    nextSubmitter?: FormSubmitterWithNested<FormSubmitValues, any>,
): void => {
    const { material, expiresAt } = values
    
    // Restrict submitting if material is not selected
    // else if selected material has homework, but expiration time is not selected
    if (!material || (isMaterialHasHomework(material) && !expiresAt)) return
    
    if (!nextSubmitter) return
    
    nextSubmitter(
        isMaterialHasHomework(material) && expiresAt
        ? { material: material, expiresAt: expiresAt }
        : { material: material as ShortMaterial<false>, expiresAt: null },
    )
}
