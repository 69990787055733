import { getLessonComponents } from 'src/pages/admin/Homework/functions/getLessonComponents'
import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import styles from 'src/pages/admin/Homework/Homework.module.scss'
import { Form, ModalContent } from './components/ModalContent'
import { NotificationTypes, _notify } from 'src/utils/notify'
import useAppDispatch from 'src/hooks/useAppDispatch'
import useAppSelector from 'src/hooks/useAppSelector'
import { Modal } from 'src/components/Modal/Modal'
import { SelectItem } from 'primereact/selectitem'
import { Card } from 'src/components/Card/Card'
import { classNames } from 'primereact/utils'
import { Helmet } from 'react-helmet-async'
import Loader from 'src/components/Loader'
import { Toast } from 'primereact/toast'
import { map } from 'lodash'
import {
    checkHomework,
    getHomeTaskDataTeacher,
    getHomeTaskFilterTeacher,
} from 'src/store/admin/homework/homework.actions'
import {
    GroupSubjectLesson,
    GroupSubjectLessons,
    HomeworkHomework,
    GroupSubjects,
} from 'src/store/admin/homework/homework.types'
import {
    getHomeworkCardComponents,
    HomeworkClickEventHandler,
} from 'src/pages/admin/Homework/functions/getHomeworkCardComponents'

export const HomeworkPage: FC = (): ReactElement => {
    const toast = useRef<Toast>(null)
    const dispatch = useAppDispatch()
    const filters = useAppSelector(state => state.adminHomeWorkSlice.filters)
    const students = useAppSelector(state => state.adminHomeWorkSlice.students)
    
    const [groupHomeworks, setGroupHomeworks] = useState<GroupSubjects>()
    const [subjectLessons, setSubjectLessons] = useState<GroupSubjectLessons>()
    const [selectedLesson, setSelectedLesson] = useState<GroupSubjectLesson>()
    
    const [modal, setModal] = useState(false)
    
    const [homework, setHomework] = useState<HomeworkHomework | null>(null)
    
    const { pending: pendingFilterTeacher } = useAppSelector(
        state => state.adminHomeWorkSlice.getHomeTaskFilterTeacher,
    )
    
    const { pending: pendingDataTeacher } = useAppSelector(
        state => state.adminHomeWorkSlice.getHomeTaskDataTeacher,
    )
    
    useEffect((): void => {
        dispatch(getHomeTaskFilterTeacher())
            .unwrap()
            .catch(e => {
                _notify(toast, { type: NotificationTypes.error, content: e })
            })
    }, [])
    
    useEffect((): void => {
        setSelectedLesson(undefined)
    }, [subjectLessons])
    
    useEffect((): void => {
        getData()
    }, [selectedLesson])
    
    const getData = (): void => {
        if (!selectedLesson) return
        
        dispatch(getHomeTaskDataTeacher({ lessonId: selectedLesson.id }))
            .unwrap()
            .catch(e => {
                _notify(toast, {
                    type: NotificationTypes.error,
                    content: e,
                })
            })
    }
    
    function onSubmit(form: Form): void {
        if (!homework || !selectedLesson) return
        
        dispatch(
            checkHomework({
                mark: form.mark,
                desc: form.comment,
                homeWorkId: homework.homeWorkId,
                userId: homework.userId,
                subjectTheme: selectedLesson.theme,
            }),
        )
            .unwrap()
            .then((): void => {
                _notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Задание успешно проверено!',
                    },
                    true,
                )
                onHide()
            })
            .then((_: any): void => {
                getData()
            })
            .catch(e => {
                _notify(toast, { type: NotificationTypes.error, content: e })
            })
    }
    
    function onHide() {
        setModal(false)
        setHomework(null)
    }
    
    const getGroupsSelectItems: () => SelectItem[] = useCallback(
        (): SelectItem[] => {
            return map(filters, (filter: GroupSubjects): SelectItem => {
                return {
                    value: filter,
                    label: filter.group.name,
                }
            })
        },
        [filters],
    )
    
    const onGroupSelectChange = (event: DropdownChangeEvent): void => {
        const groupHomeworks = event.value as GroupSubjects
        setGroupHomeworks(groupHomeworks)
    }
    
    const getSubjectSelectItems: () => SelectItem[] = useCallback(
        (): SelectItem[] => {
            if (!groupHomeworks) return []
            
            return map(
                groupHomeworks.subjects,
                (subjectWithLessons: GroupSubjectLessons): SelectItem => {
                    return {
                        value: subjectWithLessons,
                        label: subjectWithLessons.subject.name,
                    }
                },
            )
        },
        [groupHomeworks],
    )
    
    const onSubjectSelectChange = (event: DropdownChangeEvent): void => {
        const subjectLessons = event.value as GroupSubjectLessons
        setSubjectLessons(subjectLessons)
    }
    
    const onLessonClick = (lesson: GroupSubjectLesson): void => {
        setSelectedLesson(lesson)
    }
    
    const onHomeworkClick: HomeworkClickEventHandler = (homework: HomeworkHomework): void => {
        setHomework(homework)
        setModal(true)
    }
    
    return (
        <StudentLayout>
            <Toast ref={toast} />
            <Helmet title={'Домашнее задание'} />
            <Modal
                visible={modal}
                header={
                    homework?.status === 'Выполнено'
                    ? 'Просмотр домашнего задания'
                    : 'Проверка домашнего задания'
                }
                onHide={() => setModal(false)}
                className={styles.modal}
            >
                {homework ? (
                    <ModalContent homework={homework} onSubmit={onSubmit} />
                ) : null}
            </Modal>
            <Card
                className={styles.page}
                contentClassName={styles.pageContent}
                headerClassName={styles.pageHeader}
                header={'Домашнее задание'}
            >
                {pendingFilterTeacher ? <Loader /> : null}
                <div className={styles.header}>Выбор группы</div>
                <div className={'mt-24 flex flex-wrap gap-24'}>
                    <Dropdown
                        filter
                        value={groupHomeworks || null}
                        options={getGroupsSelectItems()}
                        onChange={onGroupSelectChange}
                    />
                    {groupHomeworks ? (
                        <Dropdown
                            filter
                            value={subjectLessons || null}
                            options={getSubjectSelectItems()}
                            onChange={onSubjectSelectChange}
                        />
                    ) : null}
                </div>
                {subjectLessons ? (
                    <>
                        <div className={classNames('mt-48', styles.header)}>
                            Урок №
                        </div>
                        <div
                            className={'flex flex-wrap'}
                            style={{ gap: 12, marginTop: 12 }}
                        >
                            {
                                getLessonComponents<GroupSubjectLesson>({
                                    lessons: subjectLessons.lessons.map((lesson) => ({
                                        ...lesson,
                                        index: lesson.numberLesson,
                                    })),
                                    onLessonClick: onLessonClick,
                                    isSelected: (lesson: GroupSubjectLesson): boolean => {
                                        return lesson.id === selectedLesson?.id
                                    },
                                    isDisabled: (lesson: GroupSubjectLesson): boolean => {
                                        return !lesson.active
                                    },
                                })
                            }
                        </div>
                    </>
                ) : null}
                {selectedLesson ? (
                    <>
                        <div className={classNames('mt-48', styles.header)}>
                            Тема урока:
                        </div>
                        <div className={styles.class}>
                            {`Урок №${selectedLesson.index}. ${selectedLesson.theme}`}
                        </div>
                    </>
                ) : null}
                {selectedLesson ? (
                    <>
                        <div className={classNames('mt-48', styles.header)}>
                            Студенты:
                        </div>
                        {pendingDataTeacher ? (
                            <Loader />
                        ) : (
                            <div className={'grid'}>
                                {getHomeworkCardComponents({
                                    homeworks: students,
                                    onHomeworkClick: onHomeworkClick,
                                })}
                            </div>
                        )}
                    </>
                ) : null}
            </Card>
        </StudentLayout>
    )
}
