import { useGetAvailableMaterialsForUploadToLessonQuery } from 'src/api/endpoints/lessons/lessons.api'
import { LessonMaterialInformation, ShortMaterial } from 'src/api/endpoints/lessons/lessons.types'
import { FormSubmitter, FormSubmitValues } from 'src/pages/Attendant/form/form.types'
import { isMaterialHasHomework } from 'src/api/endpoints/lessons/lessons.functions'
import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import { useLessonMaterialForm } from 'src/pages/Attendant/form'
import { displayFormatWithTime } from 'src/constants/constants'
import { SelectItem } from 'primereact/selectitem'
import { InputMask } from 'primereact/inputmask'
import { dayWorker } from 'src/utils/dateWorker'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { classNames } from 'primereact/utils'
import { Button } from 'primereact/button'
import { map } from 'lodash'

export type LessonMaterialAttachingFormProps = {
    lessonId: number;
    initialValues: LessonMaterialInformation;
    onSubmit: FormSubmitter<FormSubmitValues>;
}

export const LessonMaterialAttachingForm: FC<LessonMaterialAttachingFormProps> = (props: LessonMaterialAttachingFormProps): ReactElement => {
    const { onSubmit, initialValues, lessonId } = props
    
    const [form, fieldIsValid, getFieldError] = useLessonMaterialForm(initialValues, onSubmit)
    const { material: selectedMaterial, expiresAt: currentExpiresAt } = form.values
    
    const [availableMaterials, setAvailableMaterials] = useState<ShortMaterial[]>([])
    
    const {
        data: availableMaterialsForAttaching,
        isLoading: availableMaterialsIsLoading,
    } = useGetAvailableMaterialsForUploadToLessonQuery({
        path: { lessonId },
    })
    
    useEffect((): void => {
        if (availableMaterialsIsLoading || !availableMaterialsForAttaching) return
        
        setAvailableMaterials(availableMaterialsForAttaching)
    }, [availableMaterialsForAttaching, availableMaterialsIsLoading])
    
    const getLessonMaterialSelectorOptions: () => SelectItem<ShortMaterial>[] = useCallback(
        (): SelectItem<ShortMaterial>[] => {
            return map(availableMaterials, (availableMaterial: ShortMaterial): SelectItem => ({
                label: availableMaterial.name,
                value: availableMaterial,
            }))
        },
        [availableMaterials],
    )
    
    return (
        <form onSubmit={form.handleSubmit} className="p-fluid">
            <div className="field">
                <span className="p-float-label">
                    <Dropdown
                        filter
                        id="material"
                        name="material"
                        options={getLessonMaterialSelectorOptions()}
                        value={selectedMaterial || null}
                        onChange={form.handleChange}
                        className={classNames({
                            'p-invalid': fieldIsValid('material'),
                        })}
                    />
                    <label
                        htmlFor="material"
                        className={classNames({
                            'p-error': fieldIsValid('material'),
                        })}
                    >
                        Методический материал
                    </label>
                </span>
                {getFieldError('material')}
            </div>
            
            {isMaterialHasHomework(selectedMaterial) ? (
                <div className="field">
                    <span className="p-float-label field">
                        <InputMask
                            mask="99/99/9999 99:99"
                            id="expiresAt"
                            readOnly={true}
                            value={dayWorker(currentExpiresAt).format(
                                displayFormatWithTime,
                            )}
                        />
                        <label
                            htmlFor="deadLine"
                            className={classNames({
                                'p-error': fieldIsValid('expiresAt'),
                            })}
                        >
                            Выполнить до
                        </label>
                    </span>
                    {getFieldError('expiresAt')}
                    <Calendar
                        inline
                        name="expiresAt"
                        value={currentExpiresAt}
                        onChange={form.handleChange}
                        showTime
                        minDate={new Date()}
                        dateFormat={'dd/mm/yy'}
                        className={classNames({
                            'p-invalid': fieldIsValid('expiresAt'),
                        })}
                    />
                </div>
            ) : null}
            <Button
                loading={availableMaterialsIsLoading}
                type="submit"
                label="Сохранить"
                className={'w-auto'}
            />
        </form>
    )
}
