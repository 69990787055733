import { StudentLayout } from 'src/components/Layout/student/StudentLayout';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async'
import Loader from '../../../components/Loader';
import { useGetGroupSearchDataQuery, useGetGroupsQuery } from 'src/api';
import { GroupCard } from './components';
import { type Filters, GroupActionTab } from './components/GroupActionTab';
import { ConfirmDialog } from 'primereact/confirmdialog';

const filtersToQuery = (filters: Filters): string => {
    let query = '';

    if (filters.search) {
        query += `search=${filters.search}&`;
    }
    if (filters.sort) {
        query += `sort=${filters.sort}&`;
    }
    if (filters.statuses && filters.statuses.length) {
        query += `statuses=${filters.statuses.join(',')}&`;
    }
    if (filters.years && filters.years.length) {
        query += `years=${filters.years.join(',')}&`;
    }

    return query;
};

export const GroupsPage: FC = () => {
    const [filters, setFilters] = useState<Filters>({});
    const { data, isLoading, isFetching } = useGetGroupsQuery(
        filtersToQuery(filters)
    );
    const { data: filterData, isLoading: isFilterDataLoading } =
        useGetGroupSearchDataQuery();

    return (
        <StudentLayout>
            <Helmet title={'Группы'} />
            <main className="grid ml-0 mr-0">
                <GroupActionTab
                    isFetching={isFetching}
                    filterData={
                        filterData ?? {
                            sorting: [],
                            statuses: [],
                            years: [],
                        }
                    }
                    filters={filters}
                    setFilters={setFilters}
                />
                {isLoading || isFilterDataLoading ? (
                    <Loader />
                ) : data?.length ? (
                    data?.map(group => <GroupCard key={group.id} {...group} />)
                ) : (
                    'Ничего не найдено'
                )}
            </main>
            <ConfirmDialog />
        </StudentLayout>
    );
};
