import { soundNotification } from './soundNotification'
import { Toast, ToastMessage } from 'primereact/toast'
import { ReactNode, RefObject } from 'react'
import { toast } from 'react-toastify'

export enum NotificationTypes {
    success = 'success',
    error = 'error',
}

interface Options extends ToastMessage {
    type: NotificationTypes;
    header?: ReactNode;
    content?: ReactNode;
}

export function _notify(
    _ref: RefObject<Toast> | Toast,
    { type, content }: Options,
    sound?: boolean,
): void {
    toast[type](content, {
        position: 'top-center',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
    })
    
    if (sound) soundNotification()
}

export type ToastNotificationOptions = {
    toast?: RefObject<Toast>;
    type: NotificationTypes;
    message: string;
    sound?: boolean;
}

export type ToastNotificationAction<Options> = (options: Options) => void;

export type ToastDirectNotificationOptions = Omit<ToastNotificationOptions, 'type'>;

export const notify: ToastNotificationAction<ToastNotificationOptions> = (options: ToastNotificationOptions): void => {
    if (!options.toast?.current) return
    
    _notify(
        options.toast.current,
        {
            type: options.type,
            content: options.message,
        },
        options.sound !== undefined ? options.sound : true,
    )
}

export const notifyAboutSuccess: ToastNotificationAction<ToastDirectNotificationOptions> = (
    options: ToastDirectNotificationOptions,
): void => {
    return notify({ ...options, type: NotificationTypes.success })
}

export const notifyAboutException: ToastNotificationAction<ToastDirectNotificationOptions> = (
    options: ToastDirectNotificationOptions,
): void => {
    return notify({ ...options, type: NotificationTypes.error })
}
