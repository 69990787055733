import {
    RefWithId,
    UseNumberParamHook,
    UseNumberParamOptions,
    UseNumberParamReturn,
} from 'src/hooks/params/useNumberParam/types'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { Nullable } from 'primereact/ts-helpers'
import { useEffect, useState } from 'react'
import { parseInt } from 'lodash'
import { isNaN } from 'formik'

export const useNumberParam: UseNumberParamHook = <RefType extends RefWithId, >(
    options: UseNumberParamOptions<RefType>,
): UseNumberParamReturn => {
    const { fieldName } = options
    
    const [search] = useSearchParams()
    const navigate: NavigateFunction = useNavigate()
    
    const [value, setValue] = useState<Nullable<number>>()
    
    const removeParam: VoidFunction = (): void => {
        search.delete(fieldName)
        navigate({ search: search.toString() })
    }
    
    const updateParam: VoidFunction = (): void => {
        search.set(fieldName, value + '')
        navigate({ search: search.toString() })
    }
    
    useEffect((): void => {
        if (value === undefined) return
        
        if (value === null)
            return removeParam()
        
        updateParam()
    }, [value])
    
    useEffect((): void => {
        if (!options.ref) return
        
        setValue(options.ref.id)
    }, [options.ref])
    
    const setParamValue = (updated: number | null): void => {
        setValue(updated)
    }
    
    useEffect((): void => {
        if (value !== undefined) return
        
        if (!search.has(fieldName))
            return setValue(null)
        
        const paramValue: number = parseInt(search.get(fieldName) || '')
        
        if (isNaN(paramValue))
            return setValue(null)
        
        setValue(paramValue)
    }, [search, fieldName])
    
    return [value, setParamValue]
}