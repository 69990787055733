import { ResponseWrapper } from 'src/api/endpoints/users/users.types.new'
import { baseApi, QueryPayload } from 'src/api'
import {
    GetAvailableMaterialsForUploadToLessonRequestPayload,
    GetLessonMaterialRequestPayload,
    Lesson,
    LessonMaterialInformation,
    ShortMaterial,
    UploadLessonMaterialRequestPayload,
} from 'src/api/endpoints/lessons/lessons.types'

export const normalizeLessons: (lesson: Lesson<number>[]) => Lesson[] =
    (lessons: Lesson<number>[]): Lesson[] => {
        return lessons.map((lesson: Lesson<number>): Lesson => ({
            ...lesson,
            startsAt: new Date(lesson.startsAt),
            endsAt: new Date(lesson.endsAt),
        }))
    }

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getTeacherActiveLessons: builder.query<Lesson<number>[], void>({
            query: (): QueryPayload => ({
                url: '/lessons/attendance',
                method: 'GET',
                errorMessage: 'Не удалось получить информацию об уроках',
            }),
        }),
        getLessonMaterial: builder.query<
            LessonMaterialInformation,
            GetLessonMaterialRequestPayload
        >({
            providesTags: (
                _response: LessonMaterialInformation | undefined,
                _error: unknown,
                payload: GetLessonMaterialRequestPayload,
            ) => [{ type: 'LessonMaterial', id: payload.path.lessonId }],
            query: (payload: GetLessonMaterialRequestPayload): QueryPayload => ({
                url: `/lessons/${payload.path.lessonId}/material`,
                method: 'GET',
                errorMessage: 'Не удалось получить информацию об уроках',
            }),
        }),
        uploadLessonMaterial: builder.mutation<
            ResponseWrapper<null>,
            UploadLessonMaterialRequestPayload
        >({
            invalidatesTags: (
                _response: ResponseWrapper<null> | undefined,
                _error: unknown,
                payload: UploadLessonMaterialRequestPayload,
            ) => [
                { type: 'LessonMaterial', id: payload.path.lessonId },
                { type: 'LessonAttendance', id: payload.path.lessonId },
            ],
            query: (payload: UploadLessonMaterialRequestPayload): QueryPayload => ({
                url: `/lessons/${payload.path.lessonId}/material`,
                method: 'POST',
                data: payload.body,
                errorMessage: 'Не удалось прикрепить материал к уроку',
            }),
        }),
        getAvailableMaterialsForUploadToLesson: builder.query<
            ShortMaterial[],
            GetAvailableMaterialsForUploadToLessonRequestPayload
        >({
            query: (payload: GetAvailableMaterialsForUploadToLessonRequestPayload): QueryPayload => ({
                url: `/lessons/${payload.path.lessonId}/materials/available`,
                method: 'GET',
                errorMessage: 'Не удалось получить материалы для прикрепления к занятию',
            }),
        }),
    }),
})

export const {
    useGetAvailableMaterialsForUploadToLessonQuery,
    useUploadLessonMaterialMutation,
    useGetTeacherActiveLessonsQuery,
    useGetLessonMaterialQuery,
} = api