import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column'
import { GroupHoverCard } from 'src/components/GroupHoverCard/GroupHoverCard'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { DataTable, AvatarWithDisplayValueColumn } from 'src/components/DataTable'
import React, { FC, ReactElement, useRef, useState } from 'react'
import { NotificationTypes, _notify } from 'src/utils/notify'
import { generatePass } from 'src/utils/password/password'
import { requireField } from 'src/constants/constants'
import { Modal } from 'src/components/Modal/Modal'
import { Dropdown } from 'src/components/Dropdown'
import { InputText } from 'primereact/inputtext'
import { Toolbar } from 'src/components/Toolbar'
import { Card } from 'src/components/Card/Card'
import { FormItem } from 'src/components/Form'
import { useParams } from 'react-router-dom'
import { useGetGroupsQuery } from 'src/api'
import { Helmet } from 'react-helmet-async'
import { Button } from 'primereact/button'
import Loader from 'src/components/Loader'
import { Toast } from 'primereact/toast'
import { useFormik } from 'formik'
import { map } from 'lodash'
import {
    useUpdateStudentAccountMutation,
    useAddStudentAccountMutation,
    UpdateStudentAccountArg,
    AddStudentAccountArg,
    useGetStudentsQuery,
    Student,
} from 'src/api/endpoints/students'
import { EducationDepartmentRoutesNavigation } from 'src/routes/roles/EducationDepartment/navigation'
import { EducationDepartmentPaths } from 'src/routes/roles/EducationDepartment/paths'

type StudentsInitialState = Partial<UpdateStudentAccountArg> &
    Partial<AddStudentAccountArg>;

type StudentsForm = UpdateStudentAccountArg & AddStudentAccountArg;

export const StudentsPage: FC = (): ReactElement => {
    const { id: groupId } = useParams()
    let url = 'students'
    
    if (groupId) {
        url = `/groups/${groupId}/students`
    }
    
    const [modal, setModal] = useState<boolean>(false)
    const toast = useRef<Toast>(null)
    
    const { data, isLoading, isFetching } = useGetStudentsQuery(url)
    
    const [add, { isLoading: isAddLoading }] = useAddStudentAccountMutation()
    
    const [update, { isLoading: isUpdateLoading }] =
        useUpdateStudentAccountMutation()
    
    const [initialState, setInitialState] =
        useState<StudentsInitialState | null>(null)
    
    const onCreate = async (form: AddStudentAccountArg): Promise<void> => {
        await add(form).unwrap()
        
        _notify(
            toast,
            {
                type: NotificationTypes.success,
                content: 'Вы успешно добавили студента!',
            },
            true,
        )
    }
    const onUpdate = async (form: UpdateStudentAccountArg): Promise<void> => {
        await update(form).unwrap()
        
        setModal(false)
        setInitialState(null)
        
        _notify(
            toast,
            {
                type: NotificationTypes.success,
                content: 'Вы успешно обновили студента!',
            },
            true,
        )
    }
    
    return (
        <>
            <StudentLayout>
                <Helmet title={'Студенты'} />
                <Toast ref={toast} />
                <Modal
                    header={`${initialState ? 'Обновление' : 'Добавление'} студента`}
                    visible={modal}
                    onHide={(): void => {
                        setInitialState(null)
                        setModal(false)
                    }}
                >
                    <ModalContent
                        initialState={initialState}
                        onSubmit={initialState ? onUpdate : onCreate}
                        update={!!initialState?.studentId}
                        loading={initialState ? isUpdateLoading : isAddLoading}
                    />
                </Modal>
                <Card
                    headerClassName={'mt-24'}
                    header={
                        groupId
                        ? `Студенты группы ${data ? data[0].group.name : ''}`
                        : 'Студенты'
                    }
                >
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <DataTable
                            header={
                                <Toolbar
                                    add={{
                                        onClick: (): void => {
                                            if (groupId) {
                                                setInitialState(prev => ({
                                                    ...prev,
                                                    curGroup: Number(groupId),
                                                }))
                                            }
                                            setModal(true)
                                        },
                                        loading: isAddLoading,
                                    }}
                                />
                            }
                            loading={isFetching}
                            value={data}
                            dataKey={'id'}
                        >
                            <Column
                                filter
                                sortable
                                field={'name'}
                                header="Имя"
                                body={rowData => (
                                    <AvatarWithDisplayValueColumn
                                        data={{
                                            avatar: rowData.avatarURL,
                                            displayValue: rowData.name,
                                        }}
                                        link={EducationDepartmentRoutesNavigation[EducationDepartmentPaths.StudentPage](rowData.id)}
                                    />
                                )}
                            />
                            <Column
                                filter
                                sortable
                                field={'surname'}
                                header="Фамилия"
                            />
                            <Column
                                filter
                                sortable
                                field={'patronymic'}
                                header="Отчество"
                            />
                            <Column
                                filter
                                showFilterMenuOptions={false}
                                showFilterMatchModes={false}
                                filterMatchMode={'contains'}
                                showAddButton={false}
                                field={'contractNumber'}
                                header="Договор"
                            />
                            <Column
                                filter
                                sortable
                                header="Группа"
                                filterField={'group.name'}
                                body={(rowData: Student): ReactElement => (
                                    <GroupHoverCard group={rowData.group} />
                                )}
                            />
                            <Column
                                filter
                                showFilterMatchModes={false}
                                showFilterOperator={false}
                                showAddButton={false}
                                filterField={'status.id'}
                                filterElement={
                                    (options: ColumnFilterElementTemplateOptions) => (
                                        <Dropdown
                                            options={[
                                                {
                                                    value: 0,
                                                    label: 'Действующие',
                                                },
                                                {
                                                    value: 1,
                                                    label: 'Выпущенные',
                                                },
                                                {
                                                    value: 2,
                                                    label: 'Отчисленные',
                                                },
                                                {
                                                    value: 3,
                                                    label: 'В академическом отпуске',
                                                },
                                            ]}
                                            value={options.value}
                                            onChange={(e) => options.filterApplyCallback(e.value)}
                                        />
                                    )}
                                filterMatchMode={'equals'}
                                field={'status.name'}
                                header="Статус"
                            />
                            <Column
                                filter
                                sortable
                                field={'login'}
                                header="Логин"
                            />
                            <Column
                                filter
                                sortable
                                field={'phone'}
                                header="Телефон"
                            />
                            <Column
                                filter
                                sortable
                                field={'email'}
                                header="Почта"
                            />
                            <Column
                                body={(row: Student) => (
                                    <Button
                                        icon="pi pi-pencil"
                                        onClick={() => {
                                            setModal(true)
                                            setInitialState({
                                                studentId: row.id,
                                                curGroup: row.group.id,
                                                contract: row.contractNumber,
                                                password: undefined,
                                            })
                                        }}
                                    />
                                )}
                            />
                        </DataTable>
                    )}
                </Card>
            </StudentLayout>
        </>
    )
}

interface ModalContentProps {
    onSubmit: (value: StudentsForm) => Promise<void>;
    initialState: StudentsInitialState | null;
    update: boolean;
    loading: boolean;
    freezeGroup?: boolean;
}

export const ModalContent: FC<ModalContentProps> = ({
                                                        onSubmit,
                                                        initialState,
                                                        update,
                                                        loading,
                                                        freezeGroup = false,
                                                    }) => {
    const formik = useFormik<StudentsForm>({
        initialValues: {
            login: '',
            password: '',
            curGroup: null,
            contract: '',
            studentId: null,
            ...initialState,
        },
        validate: ({ login, password, curGroup, contract }) => {
            const errors: Partial<Record<keyof StudentsForm, any>> = {}
            
            if (!update && !login) errors.login = requireField
            if (!update && !password) errors.password = requireField
            if (!curGroup) errors.curGroup = requireField
            if (!contract) errors.contract = requireField
            
            return errors
        },
        onSubmit: values => {
            onSubmit(values).then(() => {
                formik.resetForm()
            })
        },
    })
    
    function generate() {
        formik.setFieldValue('password', generatePass())
    }
    
    const { login, password, curGroup, contract } = formik.values
    const { data, isLoading, isFetching } = useGetGroupsQuery('')
    
    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid">
            {update ? null : (
                <FormItem
                    value={login}
                    name={'login'}
                    label={'Логин'}
                    formik={formik}
                >
                    <InputText />
                </FormItem>
            )}
            <FormItem
                value={contract}
                name={'contract'}
                label={'Договор'}
                formik={formik}
            >
                <InputText />
            </FormItem>
            <FormItem
                value={curGroup}
                name={'curGroup'}
                label={'Группа'}
                formik={formik}
            >
                <Dropdown
                    loading={isLoading || isFetching}
                    options={map(data, ({ id, name }) => ({
                        value: id,
                        label: name,
                    }))}
                    disabled={freezeGroup}
                />
            </FormItem>
            <FormItem
                value={password}
                name={'password'}
                label={'Пароль'}
                formik={formik}
            >
                <InputText />
            </FormItem>
            <Button
                type={'button'}
                className={'mb-24'}
                style={{ width: 'auto', display: 'block' }}
                onClick={generate}
            >
                Сгенерировать пароль
            </Button>
            <Button
                loading={loading}
                type="submit"
                label={update ? 'Обновить' : 'Сохранить'}
                className={'w-auto'}
            />
        </form>
    )
}
