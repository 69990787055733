import { Audience, AudienceEditableFields, AudienceId } from 'src/api/endpoints/classroom/classroom.types'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import React, { FC, MouseEvent, ReactElement, useRef, useState } from 'react'
import { NotificationTypes, _notify } from 'src/utils/notify'
import { DataTableSelectEvent } from 'primereact/datatable'
import { DeleteButton } from 'src/components/DeleteButton'
import { requireField } from 'src/constants/constants'
import { InputNumber } from 'primereact/inputnumber'
import { DataTable } from 'src/components/DataTable'
import { Modal } from 'src/components/Modal/Modal'
import { InputText } from 'primereact/inputtext'
import { Toolbar } from 'src/components/Toolbar'
import { Card } from 'src/components/Card/Card'
import { FormItem } from 'src/components/Form'
import { Helmet } from 'react-helmet-async'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import Loader from 'src/components/Loader'
import { Toast } from 'primereact/toast'
import { useFormik } from 'formik'
import {
    useCreateAudienceMutation,
    useDeleteAudienceMutation,
    useGetAllAudienceQuery,
    useUpdateAudienceMutation,
} from 'src/api/endpoints/classroom'

export const AudiencesPage = () => {
    const [modal, setModal] = useState(false)
    const toast = useRef<Toast>(null)
    
    const [audience, setAudience] = useState<Audience | null>(null)
    
    const { data, isLoading, isFetching, refetch } = useGetAllAudienceQuery()
    
    const [createAudience, { isLoading: isAddLoading }] = useCreateAudienceMutation()
    const [updateAudience, { isLoading: isUpdateLoading }] = useUpdateAudienceMutation()
    
    const [deleteAudience] = useDeleteAudienceMutation()
    
    const onSubmit = (form: AudienceEditableFields) => {
        if (audience) {
            return updateAudience({
                path: {
                    id: audience.id,
                },
                body: { name: form.name, capacity: form.capacity },
            })
                .unwrap()
                .then(() => {
                    _notify(
                        toast,
                        {
                            type: NotificationTypes.success,
                            content: 'Вы успешно обновили группу!',
                        },
                        true,
                    )
                    onHide()
                })
                .catch(e => {
                    _notify(toast, {
                        type: NotificationTypes.error,
                        content: e,
                    })
                })
        }
        return createAudience({
            body: {
                name: form.name,
                capacity: form.capacity,
            },
        })
            .unwrap()
            .then(() => {
                _notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Вы успешно создали группу!',
                    },
                    true,
                )
            })
            .catch(e => {
                _notify(toast, { type: NotificationTypes.error, content: e })
            })
    }
    
    const onDelete = async (id: AudienceId): Promise<void> => {
        await deleteAudience({ id })
            .unwrap()
            .then(() => {
                _notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Вы успешно удалили аудиторию!',
                    },
                    true,
                )
            })
            .then(() => refetch())
            .catch(e => {
                if (e.data) {
                    _notify(toast, {
                        type: NotificationTypes.error,
                        content: e.data,
                    })
                }
            })
    }
    
    function onHide() {
        setModal(false)
        setAudience(null)
    }
    
    return (
        <StudentLayout>
            <Helmet title={'Аудитории'} />
            <Toast ref={toast} />
            <Modal
                header={`${audience ? 'Редактирование' : 'Создание'} аудитории`}
                visible={modal}
                onHide={onHide}
            >
                <AudienceForm
                    audience={audience}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    loading={audience ? isUpdateLoading : isAddLoading}
                />
            </Modal>
            <Card headerClassName={'mt-24'} header={'Аудитории'}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <DataTable
                        selectionMode={'single'}
                        onRowSelect={(rowData: DataTableSelectEvent<Audience>) => {
                            setModal(true)
                            setAudience(rowData.data)
                        }}
                        alwaysShowPaginator={false}
                        rows={25}
                        header={
                            <Toolbar
                                add={{
                                    onClick: () => setModal(true),
                                    loading: isAddLoading,
                                }}
                            />
                        }
                        loading={isFetching}
                        value={data?.data}
                    >
                        <Column field={'name'} header={'Название'} />
                        <Column field={'capacity'} header={'Вместимость'} />
                    </DataTable>
                )}
            </Card>
        </StudentLayout>
    )
}

type AudienceFormProps = {
    audience: Audience | null;
    onSubmit: (form: AudienceEditableFields) => Promise<void>;
    onDelete: (id: AudienceId) => Promise<void>;
    loading: boolean;
}

const AudienceForm: FC<AudienceFormProps> = (props: AudienceFormProps): ReactElement => {
    const { audience, onSubmit, onDelete, loading } = props
    
    const onFormSubmit = (data: AudienceEditableFields) => {
        onSubmit(data).then(() => formik.resetForm())
    }
    
    const onAudienceDelete = (
        event: MouseEvent<HTMLElement>,
        id: AudienceId,
    ) => {
        event.preventDefault()
        onDelete(id).then(() => formik.resetForm())
    }
    
    const formik = useFormik<AudienceEditableFields>({
        initialValues: audience || {
            name: '',
            capacity: 0,
        },
        validate: (form: AudienceEditableFields) => {
            const errors: Partial<Record<keyof AudienceEditableFields, any>> = {}
            
            if (!form.name) errors.name = requireField
            
            if (form.capacity === null || form.capacity === undefined) errors.capacity = requireField
            
            if (form.capacity < 0)
                errors.capacity = 'Число должно быть положительным!'
            
            return errors
        },
        onSubmit: onFormSubmit,
    })
    
    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid">
            <FormItem
                value={formik.values.name}
                name={'name'}
                label={'Наименование'}
                formik={formik}
            >
                <InputText />
            </FormItem>
            <FormItem
                value={formik.values.capacity}
                name={'capacity'}
                label={'Вместимость'}
                formik={formik}
            >
                <InputNumber
                    min={0}
                    defaultValue={formik.initialValues.capacity}
                />
            </FormItem>
            <Button
                loading={loading}
                type="submit"
                label="Сохранить"
                className={'w-auto'}
            />
            {audience && (
                <DeleteButton onClick={(event: MouseEvent<HTMLElement>) => onAudienceDelete(event, audience.id)} />
            )}
        </form>
    )
}
