import { CreateAcademicVacationModal } from 'src/components/AcademicVacationModals';
import { FC, ReactElement, useRef, useState } from 'react';
import styles from './AcademicVacation.module.scss'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

export type AcademicVacationActionTabProps = {
    isFetching: boolean;
};

export const AcademicVacationActionTab: FC<AcademicVacationActionTabProps> = (
    _props: AcademicVacationActionTabProps,
): ReactElement => {

    const toast = useRef<Toast>(null);
    const [createAcademicModalVisible, setCreateAcademicModalVisible] =
        useState(false);

    return (
        <>
            <div className={styles.filter}>
                <div className="flex flex-row justify-content-between flex-wrap">
                    <Button
                        onClick={() => setCreateAcademicModalVisible(true)}
                        className="mr-3 mt-2"
                    >
                        Добавить
                    </Button>
                </div>
            </div>
            <Toast ref={toast} />
            <CreateAcademicVacationModal
                visible={createAcademicModalVisible}
                onHide={(): void => setCreateAcademicModalVisible(false)}
            />
        </>
    );
};
