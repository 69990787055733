import {
    AcademicVacationActionTab,
    AcademicVacationList,
} from 'src/pages/edu/AcademicVacations/components';
import { StudentLayout } from 'src/components/Layout/student/StudentLayout';
import { useGetAcademicLeavesQuery } from 'src/api/endpoints/academic';
import Loader from 'src/components/Loader';
import { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet-async'

export const AcademicVacations: FC = (): ReactElement => {
    const { data, isLoading, isFetching } = useGetAcademicLeavesQuery();

    return (
        <StudentLayout>
            <Helmet title={'Академические отпуска'} />
            <main className="mt-2 ml-0 mr-0">
                <AcademicVacationActionTab isFetching={isFetching} />
                {isLoading ? (
                    <Loader />
                ) : data?.length ? (
                    <AcademicVacationList
                        data={data ?? []}
                        isFetching={isFetching}
                    />
                ) : (
                    'Ничего не найдено'
                )}
            </main>
        </StudentLayout>
    );
};
