import { CSSProperties, FC, ReactElement, useEffect, useLayoutEffect, useState } from 'react'
import { uniqueId } from 'lodash'

export type PercentageCircleDiagramValues = {
    isPercentage: true;
}

export type ValueCircleDiagram = {
    isPercentage: false;
    max: number;
}

export type CircleDiagramValuesProps = (PercentageCircleDiagramValues | ValueCircleDiagram) & {
    rotate?: number;
    workingAreaRatio?: number;
    value: number;
}

export type CircleDiagramClickActions = {
    onClick: VoidFunction;
}

export type ClickableCircleDiagram = {
    clickable: false;
} | {
    clickable: true;
    actions: CircleDiagramClickActions
}

export type CircleDiagramProps = ClickableCircleDiagram & {
    values: CircleDiagramValuesProps;
    color: string;
    title: string;
}

export const CircleDiagram: FC<CircleDiagramProps> = (props: CircleDiagramProps): ReactElement => {
    const id: string = uniqueId()
    
    const { values, color, title } = props
    
    const rotateValue: number = values.rotate || 135
    const workingAreaRatio: number = values.workingAreaRatio || 0.75
    
    const percentage: number = Math.floor(Math.min(
        values.isPercentage
        ? values.value
        : values.value / values.max * 100,
        100,
    ))
    
    const [isHover, setIsHover] = useState(false)
    
    const [value, setValue] = useState(0)
    const [ratio, setRatio] = useState<number>(0)
    
    const [progress, setProgress] = useState(0)
    const [size, setSize] = useState(250)
    const [strokeWidth, setStrokeWidth] = useState(20)
    const [fontSize, setFontSize] = useState(24)
    
    const styles: CSSProperties = {
        transition: 'box-shadow .3s ease-in-out',
        cursor: props.clickable ? 'pointer' : 'auto',
    }
    
    const hoverStyle: CSSProperties = {
        boxShadow: `0 0 10px 1px ${color}BB`,
    }
    
    useEffect((): void => {
        setProgress(percentage)
    }, [percentage])
    
    useEffect((): void => {
        const currentValue: number = values.value * ratio / percentage
        
        setValue(Math.trunc(Math.floor(currentValue * 100) / 100) || 0)
    }, [values.value, ratio, percentage])
    
    useEffect((): VoidFunction => {
        let counter: number = 0
        
        const interval: NodeJS.Timer = setInterval((): void => {
            if (counter === percentage) return clearInterval(interval)
            
            setRatio((previousValue: number): number => {
                if (previousValue >= percentage) return percentage
                return previousValue + 1
            })
            
            counter++
        }, 5)
        
        return (): void => clearInterval(interval)
    }, [])
    
    useLayoutEffect((): VoidFunction => {
        const resizeDiagram: VoidFunction = (): void => {
            const w = window.innerWidth
            
            if (w <= 400 && size !== 100) {
                setSize(100)
                setStrokeWidth(16)
                setFontSize(18)
            } else if (w > 400 && w <= 600 && size !== 150) {
                setSize(150)
                setStrokeWidth(24)
                setFontSize(22)
            } else if (w > 600 && w <= 990 && size !== 200) {
                setSize(200)
                setStrokeWidth(32)
                setFontSize(25)
            } else if (w > 990 && w <= 1940 && size !== 150) {
                setSize(150)
                setStrokeWidth(24)
                setFontSize(22)
            } else if (w > 1940 && size !== 200) {
                setSize(200)
                setStrokeWidth(32)
                setFontSize(25)
            }
        }
        
        window.addEventListener('resize', resizeDiagram)
        resizeDiagram()
        
        return (): void => window.removeEventListener('reset', resizeDiagram)
    }, [size])
    
    const diagramRadius: number = (size - strokeWidth) / 2
    
    // Центральная точка окружности (x === y)
    const diagramCenterPoint: number = size / 2
    
    // Изначальная длина окружности
    const initialCircumference: number = 2 * Math.PI * diagramRadius
    
    // Длина дуги нижний окружности
    const backgroundCircleCircumference: number = initialCircumference * workingAreaRatio
    
    // Длина заполненной дуги верхней окружности
    const filledCircumference: number = initialCircumference * progress * 0.01 * workingAreaRatio
    
    // Длина незаполненной дуги верхней окружности
    const unfilledCircumference: number = initialCircumference - filledCircumference
    
    return (
        <div
            className={`new-area flex flex-column justify-content-center align-items-center select-none`}
            onMouseEnter={(): void => setIsHover(true)}
            onMouseLeave={(): void => setIsHover(false)}
            style={{ ...styles, ...(isHover && props.clickable ? hoverStyle : {}) }}
            onClick={
                (): void => props.clickable
                            ? props.actions.onClick()
                            : undefined
            }
        >
            <div className="new-text-prime fz-16">{title}</div>
            <div
                className="mt-24 relative"
                style={{ width: size, height: size }}
            >
                <svg
                    width={size}
                    height={size}
                    style={{ transform: `rotate(${rotateValue}deg)` }}
                >
                    <circle
                        cx={diagramCenterPoint}
                        cy={diagramCenterPoint}
                        r={diagramRadius}
                        strokeDasharray={backgroundCircleCircumference}
                        fill="none"
                        stroke="#f0f0f0"
                        strokeWidth={strokeWidth}
                        strokeLinecap="round"
                    />
                    <circle
                        cx={diagramCenterPoint}
                        cy={diagramCenterPoint}
                        r={diagramRadius}
                        fill="none"
                        stroke={`url(#gradient-${id})`}
                        strokeWidth={strokeWidth}
                        strokeDasharray={`${filledCircumference} ${unfilledCircumference}`}
                        strokeLinecap="round"
                        style={{
                            transition: `stroke-dasharray ${percentage * 0.01}s ease-out`,
                        }}
                    />
                    <defs>
                        <linearGradient
                            id={`gradient-${id}`}
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="100%"
                        >
                            <stop offset="0%" stopColor={color} />
                            <stop offset="100%" stopColor={color + '00'} />
                        </linearGradient>
                    </defs>
                </svg>
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize,
                        fontWeight: 'bold',
                        color,
                        userSelect: 'none',
                    }}
                >
                    {`${value}${values.isPercentage ? '%' : ''}`}
                </div>
            </div>
        </div>
    )
}
