export enum EducationDepartmentPaths {
    MaterialsPage = '/materials',
    SubjectsPage = '/subjects',
    SchedulePage = '/schedule',
    NotificationsPage = '/notifications',
    GroupsPage = '/groups',
    GroupPage = '/groups/:id',
    StudentsPage = '/students',
    StudentPage = '/students/:id',
    AttendancePage = '/attendance',
    AppealsPage = '/appeals',
    AudiencesPage = '/audiences',
    StatisticsPage = '/statistics',
    StudentAttendancePage = `/students/:id/attendance`,
    AcademicLeavesPage = '/academic',
    GroupsStudentsPage = '/groups/:groupId/students/'
}