import { defaultApiPath, serverApiPath, serverHost } from './constants';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { NotificationTypes, _notify } from './utils/notify'

export const getServerPath = (host?: string, apiPath?: string): string => {
    host = host || serverHost;
    apiPath = apiPath || serverApiPath || defaultApiPath;

    if (!host) throw new Error('Host variable cannot be undefined');

    return `${host}${apiPath}`;
};

export const baseServerPath: string = process.env.REACT_APP_BACKEND_HOST || '';

export const isValidCode = (code: number) => code.toString().startsWith('2');

const http: AxiosInstance = axios.create({
    withCredentials: true,
    baseURL: getServerPath(),
});

http.interceptors.request.use(config => {
    if (config.headers) {
        config.headers.Authorization = localStorage.getItem('access-token');
    }

    return config;
});

http.interceptors.response.use(
    async (config: AxiosResponse): Promise<AxiosResponse> => {
        if (isValidCode(config.data.statusCode)) {
            config.data = config.data?.data;

            return config;
        }
        _notify(
            window.Toast,
            {
                type: NotificationTypes.error,
                content: config.data?.message,
            },
            false
        );
        return Promise.reject(config);
    },
    async error => {
        const originalRequest = error.config;

        if (error.response?.status === 401) {
            if (
                error.response.data?.error === 'Unauthorized' &&
                originalRequest &&
                originalRequest._isRetry !== true
            ) {
                originalRequest._isRetry = true;

                const { data } = await axios.post(
                    baseServerPath + '/api/auth/refresh',
                    {
                        id: Number(localStorage.getItem('userId')),
                        refreshToken: localStorage.getItem('refresh-token'),
                    },
                    {
                        withCredentials: true,
                    }
                );

                localStorage.setItem('access-token', data?.data?.accessToken);
                localStorage.setItem('refresh-token', data?.data?.refreshToken);

                return http.request(originalRequest);
            } else {
                localStorage.removeItem('userId');
                localStorage.removeItem('access-token');
                localStorage.removeItem('refresh-token');
                window.location.replace('/login');
            }
        }
        _notify(
            window.Toast,
            {
                type: NotificationTypes.error,
                content: error?.errorMessage,
            },
            true
        );
        return Promise.reject(error);
    }
);

export default http;
