import { IUpdateProfileActionPayload } from 'src/store/student/profile/profile.types';
import {
    getProfile,
    setProfile,
} from 'src/store/student/profile/profile.actions';
import { StudentLayout } from 'src/components/Layout/student/StudentLayout';
import { dateIsNotNegative } from 'src/utils/date/dateIsNotNegative';
import { NotificationTypes, _notify } from 'src/utils/notify'
import { setAvatarURL } from 'src/store/user/user.actions';
import { num_word } from 'src/pages/Attendant/utils/utils';
import useErrorHandler from 'src/hooks/useErrorHandler';
import {
    ChangeEvent,
    ChangeEventHandler,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';
import { validateEmail } from 'src/utils/validateEmail';
import useAppSelector from 'src/hooks/useAppSelector';
import useAppDispatch from 'src/hooks/useAppDispatch';
import { InputNumber } from 'primereact/inputnumber';
import { actions } from 'src/store/user/user.slice';
import { dayWorker } from 'src/utils/dateWorker';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import avatarImg from 'src/static/avatar.svg';
import { Button } from 'primereact/button';
import Loader from 'src/components/Loader';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import './ProfilePage.scss';
import { Nullable } from 'primereact/ts-helpers';

export const ProfilePage: FC = (): ReactElement => {
    const getInitialValues = () => ({
        fio: firstName
            ? `${lastName} ${firstName}` +
              (patronymicName ? ` ${patronymicName}` : '')
            : '',
        contractNumber: String(data.contractNumber).replaceAll(' ', ''),
        password: '',
        birthday: data.birthday || new Date().toString(),
    });

    // state and ref
    const [avatarLoading, setAvatarLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [putInitialData, setPutInitialData] = useState(false);
    const [visible, setVisible] = useState(false);
    const toastRef = useRef<Toast>(null);

    // redux
    const { profileCompleted } = useAppSelector(state => state.userReducer);
    const { id, firstName, lastName, patronymicName, avatarURL } =
        useAppSelector(state => state.userReducer.user);
    const { setFIO, setProfileCompleted } = actions;
    const { isLoading, hasLoaded, data } = useAppSelector(
        state => state.profileReducer
    );
    const dispatch = useAppDispatch();

    // hooks
    const errorHandler = useErrorHandler();

    const formik = useFormik<IUpdateProfileActionPayload>({
        initialValues: {
            ...data,
            ...getInitialValues(),
        },
        validate(data: IUpdateProfileActionPayload) {
            const errors: Partial<
                Record<keyof IUpdateProfileActionPayload, string>
            > = {};
            const fioLength: number = data.fio
                ? data.fio.trim().split(' ').length
                : 0;

            if (!data.fio?.trim().length) errors.fio = 'ФИО обязательно!';

            if (!data.birthday) errors.birthday = 'Дата рождения обязательна!';

            if (!dateIsNotNegative(dayWorker(data.birthday)))
                errors.birthday = 'Возраст должен быть положительным!';

            if (!data.email?.trim().length) errors.email = 'Email обязателен!';

            if (!data.phone?.toString().trim().length)
                errors.phone = 'Телефон обязателен!';

            if (!data.country?.trim().length)
                errors.country = 'Страна обязательна!';

            if (!data.city?.trim().length) errors.city = 'Город обязателен!';

            if (!data.address?.trim().length)
                errors.address = 'Адрес обязателен!';

            if (!data.password?.trim().length && !profileCompleted)
                errors.password = 'Пароль обязателен!';

            if (!data.contractNumber?.trim().length)
                errors.contractNumber = '№ договора обязателен!';

            if (fioLength !== 2 && fioLength !== 3)
                errors.fio = 'Введите фамилию и имя';

            if (!validateEmail(data.email))
                errors.email = 'Введите корректный email!';

            return errors;
        },
        onSubmit: (data: IUpdateProfileActionPayload): void => {
            setButtonLoading(true);

            const name: string[] = data.fio.split(' ');

            if (avatarURL.endsWith('undefined')) {
                setButtonLoading(false);
                return toastRef.current?.show({
                    severity: 'info',
                    summary: 'Обратите внимание',
                    detail: 'Для завершения регистрации необходимо загрузить фотографию',
                    life: 5000,
                });
            }

            dispatch(
                setProfile({
                    id,
                    data,
                    profileCompleted,
                })
            )
                .unwrap()
                .then((): void => {
                    setButtonLoading(false);
                    
                    _notify(
                        toastRef,
                        {
                            type: NotificationTypes.success,
                            content: 'Ваши данные были успешно обновлены',
                        },
                        true
                    );

                    dispatch(setProfileCompleted(true));
                })
                .catch(error => {
                    setButtonLoading(false);
                    
                    _notify(toastRef, {
                        type: NotificationTypes.error,
                        content: error,
                    });
                });

            dispatch(
                setFIO({
                    patronymicName: name[2],
                    firstName: name[1],
                    lastName: name[0],
                })
            );
        },
    });

    useEffect((): void => {
        if (!hasLoaded) {
            dispatch(getProfile())
                .unwrap()
                .catch(error => errorHandler(error));
        }
    }, []);

    useEffect((): void => {
        if (profileCompleted) return;

        setTimeout((): void => {
            toastRef.current?.show({
                severity: 'info',
                summary: 'Обратите внимание',
                detail: 'Завершите регистрацию и загрузите фотографию, чтобы приступить к обучению',
                life: 5000,
            });
        }, 1000);
    }, [profileCompleted]);

    useEffect((): void => {
        if (!data.contractNumber || putInitialData) return;

        formik.setValues(
            (prev): IUpdateProfileActionPayload => ({
                ...prev,
                ...data,
                ...getInitialValues(),
            })
        );

        setPutInitialData(true);
    }, [data]);

    const isFormFieldValid = (name: string) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name: string) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const toggleVisible = () => setVisible(prev => !prev);
    const diff: number = dayWorker().diff(dayWorker(data.birthday), 'year');

    const onAvatarChange: ChangeEventHandler = (
        event: ChangeEvent<HTMLInputElement>
    ): void => {
        const avatar: Nullable<File> = event.target.files?.item(0);

        if (!avatar) return;

        setAvatarLoading(true);

        dispatch(
            setAvatarURL({
                id,
                avatar,
            })
        )
            .unwrap()
            .catch((reason: any) =>
                _notify(toastRef, {
                    type: NotificationTypes.error,
                    content: reason,
                })
            )
            .finally(() => setAvatarLoading(false));
    };

    return (
        <StudentLayout pageName="profile-page">
            <div className="card mt-24">
                <div className="header flex align-items-center justify-content-between profile-bg">
                    <div className="title big">Личный кабинет</div>
                </div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="content">
                        <div className="profile flex flex-column align-items-center mt-24">
                            <div className="img">
                                <img
                                    src={
                                        avatarURL.endsWith('undefined') ||
                                        avatarURL.endsWith('null')
                                            ? avatarImg
                                            : avatarURL
                                    }
                                    alt="avatar"
                                />
                                <label
                                    htmlFor="avatar"
                                    className={classNames({
                                        loading: avatarLoading,
                                    })}
                                >
                                    {avatarLoading ? (
                                        <i className="pi pi-spin pi-spinner text-white"></i>
                                    ) : (
                                        <i className="pi pi-arrow-up text-white"></i>
                                    )}
                                </label>
                                <input
                                    disabled={avatarLoading}
                                    type="file"
                                    id="avatar"
                                    accept="image/*"
                                    onChange={onAvatarChange}
                                />
                            </div>
                            {profileCompleted ? (
                                <>
                                    <div className="mt-48 fz-40 text-black">
                                        {lastName} {firstName}
                                    </div>
                                    <small className="date mt-24">
                                        {data.country}, {data.city}, {diff}{' '}
                                        {num_word(diff, ['год', 'года', 'лет'])}
                                    </small>
                                </>
                            ) : null}
                        </div>
                        <form
                            onSubmit={formik.handleSubmit}
                            className="grid form mt-64"
                        >
                            <div className="field lg:col-3 md:col-6 col-12">
                                <span className="p-float-label">
                                    <InputText
                                        id="fio"
                                        value={formik.values.fio}
                                        onChange={formik.handleChange}
                                        className={
                                            classNames({
                                                'p-invalid':
                                                    isFormFieldValid('fio'),
                                            }) + ' w-full'
                                        }
                                    />
                                    <label
                                        htmlFor="fio"
                                        className={classNames({
                                            'p-error': isFormFieldValid('fio'),
                                        })}
                                    >
                                        ФИО
                                    </label>
                                </span>
                                {getFormErrorMessage('fio')}
                            </div>
                            <div className="field lg:col-3 md:col-6 col-12">
                                <span className="p-float-label">
                                    <Calendar
                                        id="birthday"
                                        value={new Date(formik.values.birthday)}
                                        onChange={formik.handleChange}
                                        dateFormat="dd-mm-yy"
                                        className={
                                            classNames({
                                                'p-invalid':
                                                    isFormFieldValid(
                                                        'birthday'
                                                    ),
                                            }) + ' w-full'
                                        }
                                    />
                                    <label
                                        htmlFor="birthday"
                                        className={classNames({
                                            'p-error':
                                                isFormFieldValid('birthday'),
                                        })}
                                    >
                                        Дата рождения
                                    </label>
                                </span>
                                {getFormErrorMessage('birthday')}
                            </div>
                            <div className="field lg:col-3 md:col-6 col-12">
                                <span className="p-float-label">
                                    <InputText
                                        id="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        className={
                                            classNames({
                                                'p-invalid':
                                                    isFormFieldValid('email'),
                                            }) + ' w-full'
                                        }
                                    />
                                    <label
                                        htmlFor="email"
                                        className={classNames({
                                            'p-error':
                                                isFormFieldValid('email'),
                                        })}
                                    >
                                        Email
                                    </label>
                                </span>
                                {getFormErrorMessage('email')}
                            </div>
                            <div className="field lg:col-3 md:col-6 col-12">
                                <span className="p-float-label">
                                    <InputMask
                                        id="phone"
                                        mask="+7 (999) 999 99 99"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        className={
                                            classNames({
                                                'p-invalid':
                                                    isFormFieldValid('phone'),
                                            }) + ' w-full'
                                        }
                                    />
                                    <label
                                        htmlFor="phone"
                                        className={classNames({
                                            'p-error':
                                                isFormFieldValid('phone'),
                                        })}
                                    >
                                        Телефон
                                    </label>
                                </span>
                                {getFormErrorMessage('phone')}
                            </div>
                            <div className="field lg:col-3 md:col-6 col-12">
                                <span className="p-float-label">
                                    <InputText
                                        id="country"
                                        value={formik.values.country}
                                        onChange={formik.handleChange}
                                        className={
                                            classNames({
                                                'p-invalid':
                                                    isFormFieldValid('country'),
                                            }) + ' w-full'
                                        }
                                    />
                                    <label
                                        htmlFor="country"
                                        className={classNames({
                                            'p-error':
                                                isFormFieldValid('country'),
                                        })}
                                    >
                                        Страна
                                    </label>
                                </span>
                                {getFormErrorMessage('country')}
                            </div>
                            <div className="field lg:col-3 md:col-6 col-12">
                                <span className="p-float-label">
                                    <InputText
                                        id="city"
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                        className={
                                            classNames({
                                                'p-invalid':
                                                    isFormFieldValid('city'),
                                            }) + ' w-full'
                                        }
                                    />
                                    <label
                                        htmlFor="city"
                                        className={classNames({
                                            'p-error': isFormFieldValid('city'),
                                        })}
                                    >
                                        Город
                                    </label>
                                </span>
                                {getFormErrorMessage('city')}
                            </div>
                            <div className="field lg:col-3 md:col-6 col-12">
                                <span className="p-float-label">
                                    <InputText
                                        id="address"
                                        value={formik.values.address}
                                        onChange={formik.handleChange}
                                        className={
                                            classNames({
                                                'p-invalid':
                                                    isFormFieldValid('address'),
                                            }) + ' w-full'
                                        }
                                    />
                                    <label
                                        htmlFor="address"
                                        className={classNames({
                                            'p-error':
                                                isFormFieldValid('address'),
                                        })}
                                    >
                                        Улица, дом
                                    </label>
                                </span>
                                {getFormErrorMessage('address')}
                            </div>
                            {profileCompleted ? (
                                <div className="field lg:col-3 md:col-6 col-12">
                                    <span className="p-float-label">
                                        <InputNumber
                                            readOnly
                                            id="contractNumber"
                                            value={Number(
                                                formik.values.contractNumber
                                            )}
                                            onChange={formik.handleChange}
                                            className={
                                                classNames({
                                                    'p-invalid':
                                                        isFormFieldValid(
                                                            'contractNumber'
                                                        ),
                                                }) + ' w-full'
                                            }
                                        />
                                        <label
                                            htmlFor="contractNumber"
                                            className={classNames({
                                                'p-error':
                                                    isFormFieldValid(
                                                        'contractNumber'
                                                    ),
                                            })}
                                        >
                                            № договора
                                        </label>
                                    </span>
                                    {getFormErrorMessage('contractNumber')}
                                </div>
                            ) : (
                                <div className="field flex flex-column lg:col-3 md:col-6 col-12">
                                    <span className="p-float-label p-input-icon-right">
                                        {visible ? (
                                            <i
                                                className="pi pi-eye"
                                                onClick={toggleVisible}
                                            />
                                        ) : (
                                            <i
                                                className="pi pi-eye-slash"
                                                onClick={toggleVisible}
                                            />
                                        )}
                                        <InputText
                                            id="password"
                                            type={visible ? 'text' : 'password'}
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            className={classNames(
                                                {
                                                    'p-invalid':
                                                        isFormFieldValid(
                                                            'password'
                                                        ),
                                                },
                                                'w-full'
                                            )}
                                        />
                                        <label
                                            htmlFor="password"
                                            className={classNames({
                                                'p-error':
                                                    isFormFieldValid(
                                                        'password'
                                                    ),
                                            })}
                                        >
                                            Пароль
                                        </label>
                                    </span>
                                    {getFormErrorMessage('password')}
                                </div>
                            )}
                            <div className="col-12 flex justify-content-center">
                                <Button type="submit" loading={buttonLoading}>
                                    Сохранить
                                </Button>
                            </div>
                        </form>
                    </div>
                )}
                <Toast ref={toastRef} />
            </div>
        </StudentLayout>
    );
};
