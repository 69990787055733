import { Lesson, LessonMaterial, LessonMaterialInformation } from 'src/api/endpoints/lessons/lessons.types'
import { StudentAttendanceStatus } from 'src/@types/modules/attendance/attendance.enums'
import React, { FC, ReactElement, RefObject, useEffect, useRef, useState } from 'react'
import { LessonMaterialAttachingForm } from 'src/pages/Attendant/components/Modal'
import { NoParamFunction, ReasonFunction, SingleParamFunction } from 'src/@types'
import { TeacherLessonCard } from 'src/components/NearestLesson/LessonForMarking'
import styles from 'src/pages/admin/ActiveLessons/ActiveLessonsPage.module.scss'
import { ConfirmButton } from 'src/pages/Attendant/components/ConfirmButton'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { notifyAboutException, notifyAboutSuccess } from 'src/utils/notify'
import { AttendanceTable } from 'src/pages/Attendant/components/Table'
import { FormSubmitter } from 'src/pages/Attendant/form/form.types'
import { ClassType } from 'src/components/ClassType/ClassType'
import { FormItem } from 'src/pages/Attendant/utils/utils'
import { SelectedLessonType } from 'src/pages/Attendant'
import { SelectItem } from 'primereact/selectitem'
import { skipToken } from '@reduxjs/toolkit/query'
import { Modal } from 'src/components/Modal/Modal'
import { useNumberParam } from 'src/hooks/params'
import { Nullable } from 'primereact/ts-helpers'
import { Card } from 'src/components/Card/Card'
import { Helmet } from 'react-helmet-async'
import { Button } from 'primereact/button'
import Loader from 'src/components/Loader'
import { filter, map, size } from 'lodash'
import { Toast } from 'primereact/toast'
import classNames from 'classnames'
import {
    normalizeLessons,
    useGetLessonMaterialQuery,
    useGetTeacherActiveLessonsQuery,
    useUploadLessonMaterialMutation,
} from 'src/api/endpoints/lessons/lessons.api'
import {
    useFinishLessonMarkingMutation,
    useGetDataForAttendanceMarkingQuery,
    useGetStudentsAttendanceByLessonQuery,
    useUpdateStudentAttendanceMutation,
} from 'src/api/endpoints/attendance'
import {
    AttendanceStatus,
    LessonType,
    StudentAttendance,
    UpdatingStudentAttendance,
    UpdatingStudentAttendanceBase,
} from 'src/@types/modules/attendance/attendance.types'

// TODO: Сепарировать компонент Таблицы посещаемости от страниц, минимизировать дубли
export const ActiveLessonsPage: FC = (): ReactElement => {
    const toast: RefObject<Toast> = useRef<Toast>(null)
    
    const [isLessonMaterialModalVisible, setLessonMaterialModalVisible] = useState(false)
    
    const [selectedLessonsTypes, setSelectedLessonsTypes] = useState<SelectedLessonType[]>([])
    
    const [
        finishMarking,
        { isLoading: isFinishing },
    ] = useFinishLessonMarkingMutation()
    
    // region Get active teacher lessons
    const [lessons, setLessons] = useState<Lesson[]>([])
    const {
        data: teacherLessonsResponse,
        isLoading: teacherLessonsResponseIsLoading,
    } = useGetTeacherActiveLessonsQuery()
    
    useEffect((): void => {
        if (teacherLessonsResponseIsLoading || !teacherLessonsResponse) return
        
        setLessons(normalizeLessons(teacherLessonsResponse))
    }, [teacherLessonsResponseIsLoading, teacherLessonsResponse])
    // endregion
    
    // region Selected Lesson
    const [selectedLesson, selectLesson] = useState<Lesson | null>(null)
    
    const [selectedLessonId, setSelectedLessonId] = useNumberParam<Lesson>({
        fieldName: 'lessonId',
        ref: selectedLesson,
    })
    
    useEffect((): void => {
        if (!teacherLessonsResponse || teacherLessonsResponseIsLoading) return
        if (!lessons) return
        
        const foundedLesson: Nullable<Lesson> = lessons.find(
            (lesson: Lesson): boolean => lesson.id === selectedLessonId,
        )
        
        if (!foundedLesson) return setSelectedLessonId(null)
        
        selectLesson(foundedLesson)
    }, [
        teacherLessonsResponseIsLoading,
        teacherLessonsResponse,
        selectedLessonId,
        lessons,
    ])
    // endregion
    
    // region Get attendance statuses and lesson types
    const [attendanceStatuses, setAttendanceStatuses] = useState<AttendanceStatus[]>()
    const [lessonTypes, setLessonTypes] = useState<LessonType[]>()
    
    const {
        data: attendanceMarkingData,
        isLoading: attendanceMarkingDataIsLoading,
    } = useGetDataForAttendanceMarkingQuery()
    
    useEffect((): void => {
        if (attendanceMarkingDataIsLoading || !attendanceMarkingData) return
        
        setLessonTypes(attendanceMarkingData.types)
        setAttendanceStatuses(attendanceMarkingData.statuses)
    }, [
        attendanceMarkingDataIsLoading,
        attendanceMarkingData,
    ])
    // endregion
    
    const [uploadLessonMaterial] = useUploadLessonMaterialMutation()
    
    // region LessonMaterial attach information
    const [lessonHasMaterial, setLessonHasMaterial] = useState<boolean>(false)
    const [lessonMaterial, setLessonMaterial] = useState<LessonMaterialInformation>({
        attachment: null,
        isAttached: false,
    })
    
    const {
        data: lessonAttachedMaterial,
        isLoading: isLessonAttachedMaterialIsLoading,
    } = useGetLessonMaterialQuery(selectedLesson ? {
        path: { lessonId: selectedLesson.id },
    } : skipToken)
    
    useEffect((): void => {
        if (isLessonAttachedMaterialIsLoading || !lessonAttachedMaterial) return
        
        setLessonMaterial(lessonAttachedMaterial)
        setLessonHasMaterial(lessonAttachedMaterial.isAttached)
    }, [isLessonAttachedMaterialIsLoading, lessonAttachedMaterial])
    // endregion
    
    const [studentsAttendance, setStudentsAttendance] = useState<StudentAttendance[]>([])
    
    const {
        data: studentsAttendanceResponse,
        isLoading: studentsAttendanceResponseIsLoading,
    } = useGetStudentsAttendanceByLessonQuery(
        selectedLesson ? { path: { lessonId: selectedLesson.id } } : skipToken,
    )
    
    const [updateStudentAttendance] = useUpdateStudentAttendanceMutation()
    
    useEffect((): void => {
        if (!studentsAttendanceResponse || studentsAttendanceResponseIsLoading) return
        
        setStudentsAttendance(studentsAttendanceResponse)
    }, [studentsAttendanceResponse, studentsAttendanceResponseIsLoading])
    
    const transformStudentAttendance = (
        attendance: StudentAttendance,
        lessonType: LessonType,
    ): UpdatingStudentAttendance => {
        const partialAttendance: UpdatingStudentAttendanceBase = {
            lessonType: lessonType.id,
            status: attendance.status.id,
        }
        
        if (attendance.status.id === StudentAttendanceStatus.Absent) {
            return {
                ...partialAttendance,
                mark: 0,
                reward: 0,
                comment: null,
            }
        }
        
        return {
            ...partialAttendance,
            mark: attendance.mark,
            reward: attendance.reward,
            comment: attendance.comment,
        }
    }
    
    const onStudentAttendanceChanged: SingleParamFunction<StudentAttendance> = (
        attendance: StudentAttendance,
    ): void => {
        if (!selectedLesson) return
        
        const selectedLessonType: Nullable<LessonType> = selectedLessonsTypes.find(
            (selectedLessonType: SelectedLessonType): boolean =>
                selectedLessonType.lessonId === selectedLesson.id)?.type
        
        if (!selectedLessonType) return
        
        updateStudentAttendance({
            path: {
                lessonId: selectedLesson.id,
                attendanceId: attendance.id,
            },
            body: transformStudentAttendance(attendance, selectedLessonType),
        })
            .unwrap()
            .then((updatedStudentAttendance: StudentAttendance): void => {
                setStudentsAttendance((previous: StudentAttendance[]): StudentAttendance[] =>
                    previous.map((attendance: StudentAttendance): StudentAttendance => {
                        if (attendance.id === updatedStudentAttendance.id) {
                            return updatedStudentAttendance
                        }
                        return attendance
                    }))
            })
            .catch((reason: any): void => {
                notifyAboutException({
                    toast: toast,
                    message: reason.data,
                })
            })
    }
    
    const updateSelectedLessonTypes = (
        previousValues: SelectedLessonType[],
        lessonType: LessonType,
    ): SelectedLessonType[] => {
        if (!selectedLesson) return previousValues
        
        return previousValues
            .filter(
                (lessonSelectedType: SelectedLessonType): boolean =>
                    lessonSelectedType.lessonId !== selectedLesson.id,
            )
            .concat({
                type: lessonType,
                lessonId: selectedLesson.id,
            })
    }
    
    const onSuccessLessonMaterialUploading: VoidFunction = (): void => {
        notifyAboutSuccess({
            toast: toast,
            message: 'Материал урока успешно обновлен',
        })
        
        setLessonMaterialModalVisible(false)
    }
    
    const onErrorWhenLessonMaterialUploading: ReasonFunction = (reason: any): void => {
        notifyAboutException({
            toast: toast,
            message: reason.data,
        })
    }
    
    const onLessonMaterialFormSubmit: FormSubmitter<LessonMaterial<Date>> =
        (form: LessonMaterial<Date>): void => {
            if (!selectedLesson) return
            
            uploadLessonMaterial({
                path: {
                    lessonId: selectedLesson.id,
                },
                body: {
                    materialId: form.material.id,
                    expiresAt: form.expiresAt?.getTime() || null,
                },
            })
                .unwrap()
                .then(onSuccessLessonMaterialUploading)
                .catch(onErrorWhenLessonMaterialUploading)
        }
    
    const onLessonCardClick: SingleParamFunction<Lesson> = (lesson: Lesson): void => {
        selectLesson(lesson)
        setStudentsAttendance([])
    }
    
    const onLessonTypeSelected: SingleParamFunction<LessonType> = (selectedType: LessonType): void => {
        setSelectedLessonsTypes(
            (previousValue: SelectedLessonType[]): SelectedLessonType[] =>
                updateSelectedLessonTypes(
                    previousValue,
                    selectedType,
                ),
        )
    }
    
    const getAttendanceStatusOptions: NoParamFunction<SelectItem<AttendanceStatus>[]> = (): SelectItem<AttendanceStatus>[] => {
        return map(
            attendanceStatuses,
            (status: AttendanceStatus): SelectItem => ({
                label: status.name,
                value: status,
            }),
        )
    }
    
    const onLessonMarkingSuccessfullyFinished: VoidFunction = (): void => {
        setLessons((previous: Lesson[]): Lesson[] => filter(previous, (lesson: Lesson): boolean => {
            return lesson.id === selectedLesson?.id
        }))
        
        selectLesson(null)
        
        notifyAboutSuccess({
            toast: toast,
            message: 'Отметка урока успешно закончена!',
        })
    }
    
    const onErrorWhenLessonMarkingFinishing: ReasonFunction = (reason: any): void => {
        notifyAboutException({
            toast: toast,
            message: reason.data,
        })
    }
    
    const onLessonMarkingFinish: VoidFunction = (): void => {
        if (!selectedLesson) return
        
        finishMarking({
            path: { lessonId: selectedLesson.id },
        })
            .unwrap()
            .then(onLessonMarkingSuccessfullyFinished)
            .catch(onErrorWhenLessonMarkingFinishing)
    }
    
    return (
        <StudentLayout>
            <Toast ref={toast} />
            <Helmet title={'Активные занятия'} />
            <Card
                header={'Активные занятия'}
                className={'mt-24'}
                headerClassName={styles.header}
                contentClassName={'p-24'}
            >
                {selectedLesson && lessonMaterial && (
                    <Modal
                        header={'Методический материал'}
                        visible={isLessonMaterialModalVisible}
                        onHide={(): void => setLessonMaterialModalVisible(false)}
                    >
                        <LessonMaterialAttachingForm
                            lessonId={selectedLesson.id}
                            initialValues={lessonMaterial}
                            onSubmit={onLessonMaterialFormSubmit}
                        />
                    </Modal>
                )}
                
                {size(lessons) ? (
                    teacherLessonsResponseIsLoading
                    ? (<Loader />)
                    : (
                        <>
                            <div className={styles.nearestLessonsSlider}>
                                {map(lessons, (lesson: Lesson): ReactElement => {
                                    return (
                                        <TeacherLessonCard
                                            key={lesson.id}
                                            className={classNames(
                                                styles.nearestLesson,
                                                {
                                                    [styles.many]: size(lessons) > 2,
                                                },
                                            )}
                                            lesson={lesson}
                                            isSelected={lesson.id === selectedLesson?.id}
                                            onClick={onLessonCardClick}
                                        />
                                    )
                                })}
                            </div>
                            
                            {selectedLesson ? (
                                isLessonAttachedMaterialIsLoading ? (
                                    <Loader />
                                ) : (
                                    <>
                                        <FormItem label={'Методический пакет'}>
                                            <Button
                                                onClick={(): void => setLessonMaterialModalVisible(true)}
                                            >
                                                {lessonHasMaterial
                                                 ? 'Обновить'
                                                 : 'Загрузить'}
                                            </Button>
                                        </FormItem>
                                        
                                        {lessonHasMaterial && (
                                            <>
                                                {size(lessonTypes) && size(attendanceStatuses) ? (
                                                    <FormItem label={'Тип урока'}>
                                                        <div className={styles.classWrapper}>
                                                            {map(lessonTypes,
                                                                (lessonType: LessonType): ReactElement => (
                                                                    <ClassType
                                                                        key={lessonType.id}
                                                                        active={
                                                                            lessonType.id ===
                                                                            selectedLessonsTypes.find(
                                                                                (lessonType: SelectedLessonType): boolean =>
                                                                                    lessonType.lessonId ===
                                                                                    selectedLesson.id,
                                                                            )?.type.id
                                                                        }
                                                                        onClick={(): void =>
                                                                            onLessonTypeSelected(lessonType)
                                                                        }
                                                                    >
                                                                        {lessonType.name}
                                                                    </ClassType>
                                                                ),
                                                            )}
                                                        </div>
                                                    </FormItem>
                                                ) : null}
                                                
                                                {studentsAttendanceResponseIsLoading ? (
                                                    <Loader />
                                                ) : (
                                                     <>
                                                         {size(studentsAttendance) ? (
                                                             <>
                                                                 <AttendanceTable
                                                                     statusLoading={studentsAttendanceResponseIsLoading}
                                                                     statusOptions={getAttendanceStatusOptions()}
                                                                     attendance={studentsAttendance}
                                                                     lessonType={
                                                                         selectedLessonsTypes.find(
                                                                             (lessonType: SelectedLessonType): boolean => {
                                                                                 return lessonType.lessonId ===
                                                                                     selectedLesson.id
                                                                             },
                                                                         )?.type
                                                                     }
                                                                     onAttendanceChange={onStudentAttendanceChanged}
                                                                 />
                                                             </>
                                                         ) : null}
                                                         <ConfirmButton
                                                             loading={isFinishing}
                                                             onConfirm={onLessonMarkingFinish}
                                                         />
                                                     </>
                                                 )}
                                            </>
                                        )}
                                    </>
                                )
                            ) : null}
                            {/*{(selectedLesson?.academicDataLessonTeacher*/}
                            {/*        ?.methodPackageModel ||*/}
                            {/*    presentsData?.methodPackageModel) &&*/}
                            {/* (selectedLesson || selectedLesson) ? (*/}
                            {/*     <FormItem label={'Тип урока'}>*/}
                            {/*         <div className={styles.classWrapper}>*/}
                            {/*             {map(*/}
                            {/*                 allowEdit*/}
                            {/*                 ? lessonTypes*/}
                            {/*                 : filters?.marks,*/}
                            {/*                 (lessonType: LessonType) => (*/}
                            {/*                     <ClassType*/}
                            {/*                         disabled={isFinished}*/}
                            {/*                         key={lessonType.id}*/}
                            {/*                         active={*/}
                            {/*                             lessonType.id ===*/}
                            {/*                             lessonsTypes.find(*/}
                            {/*                                 (lessonType: LessonSelectedType): boolean =>*/}
                            {/*                                     lessonType.lessonId ===*/}
                            {/*                                     currentLessonId.current,*/}
                            {/*                             )?.type.id*/}
                            {/*                         }*/}
                            {/*                         onClick={() =>*/}
                            {/*                             setLessonsTypes(prev =>*/}
                            {/*                                 updateClassTypes(*/}
                            {/*                                     prev,*/}
                            {/*                                     lessonType,*/}
                            {/*                                 ),*/}
                            {/*                             )*/}
                            {/*                         }*/}
                            {/*                     >*/}
                            {/*                         {lessonType.name}*/}
                            {/*                     </ClassType>*/}
                            {/*                 ),*/}
                            {/*             )}*/}
                            {/*         </div>*/}
                            {/*     </FormItem>*/}
                            {/* ) : null}*/}
                            {/*{data.length ? (*/}
                            {/*    <>*/}
                            {/*        <AttendantTable*/}
                            {/*            statusLoading={false}*/}
                            {/*            statusOptions={map(*/}
                            {/*                attendanceStatuses,*/}
                            {/*                (status: AttendanceStatus) => ({*/}
                            {/*                    value: status.id,*/}
                            {/*                    label: status.name,*/}
                            {/*                }),*/}
                            {/*            )}*/}
                            {/*            data={data}*/}
                            {/*            lessonType={*/}
                            {/*                lessonsTypes.find(*/}
                            {/*                    (lessonType: LessonSelectedType): boolean => {*/}
                            {/*                        return lessonType.lessonId ===*/}
                            {/*                            currentLessonId.current*/}
                            {/*                    },*/}
                            {/*                )?.type*/}
                            {/*            }*/}
                            {/*            onStatusChange={(event, rowData) =>*/}
                            {/*                onSingleUpdate(*/}
                            {/*                    rowData.presentStudent.id,*/}
                            {/*                    {*/}
                            {/*                        status: event.value,*/}
                            {/*                    },*/}
                            {/*                )*/}
                            {/*            }*/}
                            {/*            onCommentChange={(value, rowData) =>*/}
                            {/*                onSingleUpdate(*/}
                            {/*                    rowData.presentStudent.id,*/}
                            {/*                    {*/}
                            {/*                        comment: value,*/}
                            {/*                    },*/}
                            {/*                )*/}
                            {/*            }*/}
                            {/*            onRewardChange={(reward, rowData) =>*/}
                            {/*                onSingleUpdate(*/}
                            {/*                    rowData.presentStudent.id,*/}
                            {/*                    {*/}
                            {/*                        reward,*/}
                            {/*                    },*/}
                            {/*                )*/}
                            {/*            }*/}
                            {/*            onMarkChange={(event, rowData) =>*/}
                            {/*                onSingleUpdate(*/}
                            {/*                    rowData.presentStudent.id,*/}
                            {/*                    {*/}
                            {/*                        mark: event.value,*/}
                            {/*                    },*/}
                            {/*                )*/}
                            {/*            }*/}
                            {/*        />*/}
                            {/*        <ConfirmButton*/}
                            {/*            loading={*/}
                            {/*                isFinishedLoading ||*/}
                            {/*                isFinishLoading*/}
                            {/*            }*/}
                            {/*            disabled={isFinished === true}*/}
                            {/*            onConfirm={onFinish}*/}
                            {/*        />*/}
                            {/*    </>*/}
                            {/*) : null}*/}
                        </>
                    )
                ) : 'Нет ближайших уроков'}
            </Card>
        </StudentLayout>
    )
}